import React, { createContext, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import axios from 'axios';
import { getAuth, signInWithEmailAndPassword, UserCredential } from 'firebase/auth';

import { useGetCurrentUserQuery } from '@/services';
import { User } from '@/types';

const initialContext: {
  currentUser: User;
  login: (email: string, pass: string) => Promise<UserCredential> | undefined;
  logout: () => void;
  refetch: () => void;
  permissions: number[];
} = {
  currentUser: null,
  login: () => undefined,
  logout: () => void 0,
  refetch: () => void 0,
  permissions: []
};

export const UserContext = createContext(initialContext);

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const auth = getAuth();
  const [ user, loading ] = useAuthState(auth);

  useEffect(() => {
    axios.interceptors.request.use(
      async (config) => {
        if (config && config.headers) {
          const token = await getAuth().currentUser?.getIdToken();
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (err) => Promise.reject(err)
    );
  }, []);

  const { data: currentUserData, refetch } = useGetCurrentUserQuery(!!user);

  const login = (email: string, pass: string) => {
    return signInWithEmailAndPassword(auth, email, pass);
  };

  const logout = () => auth.signOut();

  return (
    <UserContext.Provider value={{ currentUser: currentUserData?.data, login, logout, refetch, permissions: currentUserData?.data.role.permissions.map((permission: { value: number; }) => permission.value) }}>
      {((!loading && !user) || currentUserData?.data) && children}
    </UserContext.Provider>
  );
};
