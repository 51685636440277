import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';

import { CustomSelect } from '@/components';
import { SelectOption } from '@/types';

type PublicHolidayFilterType = {
  yearValue: SingleValue<SelectOption>;
  handleChangeYearValue: (newFilterType: SingleValue<SelectOption>) => void;
};

export const PublicHolidaysFilter = ({
  yearValue,
  handleChangeYearValue
}: PublicHolidayFilterType) => {
  const handleFilterTypeChange = (newValue: SingleValue<SelectOption>) => {
    handleChangeYearValue(newValue);
  };
  const [t] = useTranslation('common');
  const currentYear = new Date().getFullYear();
  const years = [];
  years.push({ value: -1, label: 'All' });
  for (let i = currentYear - 5; i <= currentYear + 5; i++) {
    years.push({ value: String(i), label: String(i) });
  }

  return (
    <CustomSelect
      value={yearValue}
      id='filter'
      placeholder={t('filterByType')}
      handleChange={(newValue) => handleFilterTypeChange(newValue as SingleValue<SelectOption>)}
      options={years}
    />
  );
};
