export enum ABSENCE_TYPE {
    'ANNUALLEAVE' = 1,
    'SICKLEAVE',
    'UNPAIDLEAVE',
    'FAMILYSPECIALEVENTLEAVE',
    'BLOODDONATIONLEAVE',
    'OTHERLEAVE'
}

export enum ABSENCE_APPROVAL_STATUS {
    'PENDING' = 1,
    'APPROVED' = 2,
    'REJECTED' = 3,
    'CANCELLED' = 4
}
