import axios from 'axios';
import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';

import { apiUrl } from '@/helpers';
import { Options, PartialAbsenceUpdateProps, AbsenceProps } from '@/types';

const ABSENCE_API_URL = '/v1/Absences';
const ABSENCE_QUERY_KEY = 'absence';
const GET_ABSENCE_BY_ID_KEY = 'getAbsenceById';

export const absenceApi = {
  create: (payload: AbsenceProps) => axios.post(`${apiUrl()}${ABSENCE_API_URL}/`, payload),
  createByAdmin: (payload: AbsenceProps) => axios.post(`${apiUrl()}${ABSENCE_API_URL}/${payload.userId}`, payload),
  updateByUsers: ({ payload, absenceId }: { payload: AbsenceProps, absenceId: number | undefined }) => axios.put(`${apiUrl()}${ABSENCE_API_URL}/${absenceId}`, payload),
  updateByAdmin: ({ payload, absenceId }: { payload: AbsenceProps, absenceId: number | undefined }) => axios.put(`${apiUrl()}${ABSENCE_API_URL}/admin_put/${absenceId}`, payload),
  list: (pageSize: number, currentPage: number, excludeMyAbsences: boolean, orderBy?: string, orderByDesc?: boolean, userId?: string, search?: string, filtersType?: string, filtersApprovalStatus?: string, year?: string) =>
    axios.get(
      `${apiUrl()}${ABSENCE_API_URL}?pageSize=${pageSize}&currentPage=${currentPage}&excludeMyAbsences=${excludeMyAbsences}&orderBy=${orderBy}&orderByDesc=${orderByDesc}&userId=${userId}&search=${search}&type=${filtersType}&approvalStatus=${filtersApprovalStatus}&year=${year}`
    ),
  update: (payload: PartialAbsenceUpdateProps) => axios.patch(`${apiUrl()}${ABSENCE_API_URL}/${payload.id}`, payload.patchPayload),
  getAbsenceById: (id: number) => axios.get(`${apiUrl()}${ABSENCE_API_URL}/${id}`),
  deleteAbsence: (id: number) => axios.delete(`${apiUrl()}${ABSENCE_API_URL}/${id}`)
};

export const useUpdateAbsenceStatus = (queryClient: QueryClient, options: Options) => {
  return useMutation(absenceApi.update, {
    onSuccess: (response) => {
      options.onSuccess(response);
      queryClient.invalidateQueries([ABSENCE_QUERY_KEY]);
    },
    onError: (error) => {
      options.onError(error);
    }
  });
};
export const useDeleteAbsence = (queryClient: QueryClient, options: Options) => {
  return useMutation(absenceApi.deleteAbsence, {
    onSuccess: (response) => {
      options.onSuccess(response);
      queryClient.invalidateQueries([ABSENCE_QUERY_KEY]);
    },
    onError: (error) => {
      options.onError(error);
    }
  });
};

export const useCreateAbsenceMutation = (queryClient: QueryClient, options: Options) => {
  return useMutation(absenceApi.create, {
    onSuccess: (response) => {
      options.onSuccess(response);
      queryClient.invalidateQueries([ABSENCE_QUERY_KEY]);
    },
    onError: (error) => {
      options.onError(error);
    }
  });
};

export const useCreateAbsenceByAdminMutation = (queryClient: QueryClient, options: Options) => {
  return useMutation(absenceApi.createByAdmin, {
    onSuccess: (response) => {
      options.onSuccess(response);
      queryClient.invalidateQueries([ABSENCE_QUERY_KEY]);
    },
    onError: (error) => {
      options.onError(error);
    }
  });
};

export const useUpdateAbsenceByUsersMutation = (queryClient: QueryClient, options: Options) => {
  return useMutation(absenceApi.updateByUsers, {
    onSuccess: (response) => {
      options.onSuccess(response);
      queryClient.invalidateQueries([ABSENCE_QUERY_KEY]);
    },
    onError: (error) => {
      options.onError(error);
    }
  });
};

export const useUpdateAbsenceByAdminMutation = (queryClient: QueryClient, options: Options) => {
  return useMutation(absenceApi.updateByAdmin, {
    onSuccess: (response) => {
      options.onSuccess(response);
      queryClient.invalidateQueries([ABSENCE_QUERY_KEY]);
    },
    onError: (error) => {
      options.onError(error);
    }
  });
};

export const useGetAllAbsencesQuery = (
  pageSize: number,
  currentPage: number,
  excludeMyAbsences: boolean,
  orderBy?: string,
  orderByDesc?: boolean,
  options?: Options,
  userId?: string,
  search?: string,
  filtersType?: string,
  filtersApprovalStatus?: string,
  year?: string
) => {
  return useQuery(
    [ABSENCE_QUERY_KEY, { pageSize, currentPage, excludeMyAbsences, orderBy, orderByDesc, userId, search, filtersType, filtersApprovalStatus, year }],
    () => absenceApi.list(pageSize, currentPage, excludeMyAbsences, orderBy, orderByDesc, userId, search, filtersType, filtersApprovalStatus, year),
    {
      keepPreviousData: true,
      onSuccess: (response) => {
        options?.onSuccess(response);
      },
      onError: (error) => {
        options?.onError(error);
      }
    }
  );
};

export const useGetAbsenceByIdQuery = (id: number, options?: Options) => {
  return useQuery(
    [GET_ABSENCE_BY_ID_KEY, { id }],
    () => absenceApi.getAbsenceById(id),
    {
      enabled: !!id,
      onError: (error) => {
        options?.onError(error);
      }
    }
  );
};
