import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const CustomCalendarHeaderForYearView = ({ label }: { label: string }) => {
  const [t] = useTranslation('common');

  return (
    <Box
      fontSize='14px'
      padding='5px !important'
      display='flex'
      alignItems='center'
      justifyContent='center'
      backgroundColor={label === 'Sat' || label === 'Sun' ? (localStorage.getItem('chakra-ui-color-mode') === 'dark' ? 'gray.600' :'brand.150') : 'brand.600'}>
      {t(label).slice(0, 3)}
    </Box>
  );
};
