import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { MultiValue, SingleValue } from 'react-select';

import { Box, Stack, Switch, Text as ChakraText, VStack } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { PaginationState, SortingState } from '@tanstack/react-table';
import i18next from 'i18next';

import {
  AbsenceFilter,
  AllAbsencesTimeline,
  ApproveRejectAbsence,
  CalendarLegend,
  CreateEditAbsence,
  CustomConfirmationModal,
  GenericTable,
  PageLoadingState,
  Restricted
} from '@/components';
import { UserContext } from '@/contexts';
import { ABSENCE_APPROVAL_STATUS, ABSENCE_TYPE, PERMISSION_TYPE } from '@/enums';
import {
  formatDate,
  getAbsenceColumns,
  handleError,
  initialAxiosResponse,
  SadStates,
  useErrorToast,
  useSuccessToast
} from '@/helpers';
import { useDebounce } from '@/hooks';
import { useDeleteAbsence, useGetAllAbsencesQuery, useGetAllUsersAdminQuery } from '@/services';
import { AbsenceFilters, SelectOption, TableData, User } from '@/types';

export const Absences = () => {
  const { permissions } = useContext(UserContext);
  const [sorting, setSorting] = useState<SortingState>([{ id: 'startsAt', desc: false }]);
  const [t] = useTranslation('common');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isApproveAbsenceRequest, setIsApproveAbsenceRequest] = useState<boolean>(false);
  const [absenceId, setAbsenceId] = useState<number>();
  const [showCreateEditModal, setShowCreateEditModal] = useState(false);
  const [selectedAbsenceId, setSelectedAbsenceId] = useState(0);
  const [search, setSearch] = useState('');
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();
  const queryClient = useQueryClient();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [filtersType, setFiltersType] = useState('');
  const [filtersApprovalStatus, setFiltersApprovalStatus] = useState('');
  const [filterTypeValue, setFilterTypeValue] = useState<
    SingleValue<SelectOption> | MultiValue<SelectOption>
  >([]);
  const [filterApprovalStatusValue, setFilterApprovalStatusValue] = useState<
    SingleValue<SelectOption> | MultiValue<SelectOption>
  >([]);
  const [isOpenTableView, setIsOpenTableView] = useState(false);
  const permissionsAbsences = [
    PERMISSION_TYPE.CREATEABSENCEBYADMIN,
    PERMISSION_TYPE.VIEWABSENCEBYID,
    PERMISSION_TYPE.EDITABSENCEBYADMIN,
    PERMISSION_TYPE.DELETEABSENCE,
    PERMISSION_TYPE.PARTIALEDITABSENCE
  ];
  const years = [
    new Date().getFullYear() - 1,
    new Date().getFullYear(),
    new Date().getFullYear() + 1
  ].toString();
  const [year, setYear] = useState<string>(years);
  const orderByDesc = false;
  const orderByAbsence = 'startsAt';
  const orderByUsers = 'firstName';
  const [searchParams, setSearchParams] = useSearchParams();
  const [queryParams, setQueryParams] = useState(() => ({
    searchQuery: searchParams.get('searchQuery') ?? [],
    type: searchParams.get('type') ? searchParams.get('type')?.split(',') : [],
    status: searchParams.get('status') ? searchParams.get('status')?.split(',') : []
  }));
  const [isLoadingFilters, setIsLoadingFilters] = useState<boolean>(true);
  const [debounceDelay, setDebounceDelay] = useState<number>(0);

  const absenceTypeObjects = Object.keys(ABSENCE_TYPE)
    .filter((v) => isNaN(Number(v)))
    .map((label) => {
      return {
        value: ABSENCE_TYPE[label as keyof typeof ABSENCE_TYPE],
        label: t(`${label}`) || label
      };
    });

  const approvalStatusObjects = Object.keys(ABSENCE_APPROVAL_STATUS)
    .filter((v) => isNaN(Number(v)))
    .map((label) => {
      return {
        value: ABSENCE_APPROVAL_STATUS[label as keyof typeof ABSENCE_APPROVAL_STATUS],
        label: t(`${label}`) || label
      };
    });

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('absencesSearch') || 'null') === null) {
      localStorage.setItem('absencesSearch', JSON.stringify(''));
    }
    if (JSON.parse(localStorage.getItem('filterTypeValue') || 'null') === null) {
      localStorage.setItem('filterTypeValue', JSON.stringify([]));
    }
    if (JSON.parse(localStorage.getItem('filterApprovalStatusValue') || 'null') === null) {
      localStorage.setItem('filterApprovalStatusValue', JSON.stringify([]));
    }

    if (
      searchParams.get('searchQuery') === null &&
      searchParams.get('type') === null &&
      searchParams.get('status') === null
    ) {
      updateQueryParams(
        'searchQuery',
        JSON.parse(localStorage.getItem('absencesSearch') || 'null')
      );
      setSearch(JSON.parse(localStorage.getItem('absencesSearch') || 'null'));

      updateQueryParams('type', getItemValueFromLocalStorage('filterTypeValue'));
      setFilterTypeValue(JSON.parse(localStorage.getItem('filterTypeValue') || 'null'));

      updateQueryParams('status', getItemValueFromLocalStorage('filterApprovalStatusValue'));
      setFilterApprovalStatusValue(
        JSON.parse(localStorage.getItem('filterApprovalStatusValue') || 'null')
      );
    } else {
      setSearch(queryParams.searchQuery.toString());
      localStorage.setItem('absencesSearch', JSON.stringify(queryParams.searchQuery.toString()));

      searchParams.get('type') === null
        ? localStorage.setItem('filterTypeValue', JSON.stringify([]))
        : updateFiltersByUrl('type', 'filterTypeValue', absenceTypeObjects, setFilterTypeValue);

      searchParams.get('status') === null
        ? localStorage.setItem('filterApprovalStatusValue', JSON.stringify([]))
        : updateFiltersByUrl(
            'status',
            'filterApprovalStatusValue',
            approvalStatusObjects,
            setFilterApprovalStatusValue
          );
    }
  }, []);

  useEffect(() => {
    setSearchParams({
      searchQuery: queryParams.searchQuery === '' ? [] : queryParams.searchQuery,
      type:
        searchParams.get('type') === null
          ? (queryParams.type as Array<string>)
          : (queryParams.type as Array<string>)?.join(','),
      status:
        searchParams.get('status') === null
          ? (queryParams.status as Array<string>)
          : (queryParams.status as Array<string>)?.join(',')
    });

    deleteQueryParams('searchQuery');
    deleteQueryParams('type');
    deleteQueryParams('status');
  }, [queryParams, searchParams, setSearchParams]);

  const deleteQueryParams = (value: string) => {
    if (searchParams.has(value) && searchParams.get(value) === '') {
      searchParams.delete(value);
      updateQueryParams(value, []);
      setSearchParams(searchParams);
    }
  };

  const updateQueryParams = (key: string, value: string | string[]) => {
    setQueryParams((prevState) => ({ ...prevState, [key]: value }));
  };

  const getItemValueFromLocalStorage = (item: string) => {
    return (JSON.parse(localStorage.getItem(item) || 'null') as MultiValue<SelectOption>).map(
      ({ value }) => value.toString()
    );
  };

  const updateFiltersByUrl = (
    queryParam: string,
    item: string,
    objects: AbsenceFilters[],
    setValue: Dispatch<SetStateAction<SingleValue<SelectOption> | MultiValue<SelectOption>>>
  ) => {
    const result = objects.filter((object) => {
      const array = searchParams
        .get(queryParam)
        ?.split(',')
        .filter((x) => x === object.value.toString());
      return !(array?.length === 0);
    });
    setValue(result);
    localStorage.setItem(item, JSON.stringify(result));
  };

  useEffect(() => {
    handleTranslation('filterTypeValue', ABSENCE_TYPE, setFilterTypeValue);
    handleTranslation(
      'filterApprovalStatusValue',
      ABSENCE_APPROVAL_STATUS,
      setFilterApprovalStatusValue
    );
  }, [t]);

  const handleTranslation = (
    item: string,
    absenceEnum: typeof ABSENCE_TYPE | typeof ABSENCE_APPROVAL_STATUS,
    setValue: Dispatch<SetStateAction<SingleValue<SelectOption> | MultiValue<SelectOption>>>
  ) => {
    const result = (
      JSON.parse(localStorage.getItem(item) || 'null') as MultiValue<SelectOption>
    ).map(({ value }) => {
      return {
        value: value,
        label: t(`${absenceEnum[value as keyof typeof absenceEnum]}`)
      };
    });
    setValue(result);
    localStorage.setItem(item, JSON.stringify(result));
  };

  const getColumns = () => [
    {
      id: 'user',
      accessorKey: 'user',
      header: t('userId')
    },
    ...getAbsenceColumns()
  ];

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });

  const { data: allData = initialAxiosResponse, isLoading: isLoadingData } = useGetAllAbsencesQuery(
    pageSize,
    pageIndex + 1,
    true,
    sorting
      .map((s) => {
        if (s.id === 'type' && i18next.resolvedLanguage === 'rs') {
          return 'TypeSerbian';
        } else if (s.id === 'approvalStatus' && i18next.resolvedLanguage === 'rs') {
          return 'ApprovalStatusSerbian';
        } else {
          return s.id.charAt(0).toUpperCase() + s.id.slice(1);
        }
      })
      .toString(),
    sorting.some((s) => s.desc),
    {
      onSuccess: () => void 0,
      onError: (error) => {
        errorToast(handleError(error));
      }
    },
    '',
    useDebounce(search, debounceDelay),
    filtersType,
    filtersApprovalStatus,
    year
  );

  const {
    data: allTimelineAbsencesData = initialAxiosResponse,
    isLoading: isLoadingTimelineAbsences
  } = useGetAllAbsencesQuery(
    0,
    pageIndex + 1,
    false,
    orderByAbsence.charAt(0).toUpperCase() + orderByAbsence.slice(1),
    orderByDesc,
    {
      onSuccess: () => setIsLoadingFilters(false),
      onError: (error) => {
        errorToast(handleError(error));
      }
    },
    '',
    '',
    filtersType,
    filtersApprovalStatus,
    year
  );

  const { data: allUsers = initialAxiosResponse, isLoading: isLoadingUsers } =
    useGetAllUsersAdminQuery(
      0,
      pageIndex + 1,
      orderByUsers.charAt(0).toUpperCase() + orderByUsers.slice(1),
      orderByDesc,
      {
        enabled: permissions.includes(PERMISSION_TYPE.EDITABSENCEBYADMIN),
        onSuccess: () => void 0,
        onError: (error) => {
          errorToast(handleError(error));
        }
      },
      true,
      useDebounce(search, debounceDelay)
    );

  const { mutate: deleteAbsence, isLoading: isLoadingDeleteAbsence } = useDeleteAbsence(
    queryClient,
    {
      onSuccess: () => {
        successToast({ title: t('successfullyDeletedAbsence') });
        handleCloseDeleteModal();
      },
      onError: (error) => {
        errorToast(handleError(error));
      }
    }
  );

  const handleDelete = (row: TableData) => {
    setIsDeleteModalOpen(true);
    setAbsenceId(row.id);
  };

  const handleApprove = (row: TableData) => {
    setIsModalOpen(true);
    setAbsenceId(row?.id);
    setIsApproveAbsenceRequest(true);
  };

  const handleReject = (row: TableData) => {
    setIsModalOpen(true);
    setAbsenceId(row?.id);
  };

  const onClose = () => {
    setIsModalOpen(false);
    setIsApproveAbsenceRequest(false);
    setAbsenceId(undefined);
  };

  const handleEdit = (row: TableData) => {
    setSelectedAbsenceId(row?.id);
    setShowCreateEditModal(true);
  };

  const handleCloseCreateEdit = () => {
    setShowCreateEditModal(false);
    setSelectedAbsenceId(0);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
    setPagination({ pageIndex: 0, pageSize });
    localStorage.setItem('absencesSearch', JSON.stringify(value));
    updateQueryParams('searchQuery', value);
    setDebounceDelay(500);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setAbsenceId(undefined);
  };

  const handleData = () => {
    return allData?.data?.items?.map(
      (item: {
        user: User;
        approvalStatus: ABSENCE_APPROVAL_STATUS;
        type: ABSENCE_TYPE;
        createdAt: string | number | Date;
        updatedAt: string | number | Date;
        startsAt: string | number | Date;
        endsAt: string | number | Date;
      }) => ({
        ...item,
        user:
          (
            item.user?.firstName +
            ' ' +
            item.user?.lastName +
            ' ' +
            '(' +
            item.user?.referenceNumber +
            ')'
          ).length > 40
            ? (
                item.user?.firstName +
                ' ' +
                item.user?.lastName +
                ' ' +
                '(' +
                item.user?.referenceNumber +
                ')'
              ).substring(0, 37) + '...'
            : item.user?.firstName +
              ' ' +
              item.user?.lastName +
              ' ' +
              '(' +
              item.user?.referenceNumber +
              ')',
        approvalStatus: t(`${ABSENCE_APPROVAL_STATUS[item.approvalStatus]}`),
        type: t(`${ABSENCE_TYPE[item.type]}`),
        createdAt: formatDate(item.createdAt),
        updatedAt: formatDate(item.updatedAt),
        startsAt: formatDate(item.startsAt),
        endsAt: formatDate(item.endsAt)
      })
    );
  };

  const defaultHiddenColumns = {
    createdAt: false,
    id: false,
    requestMessage: false,
    responseMessage: false,
    reviewerId: false,
    updatedAt: false
  };

  const handleChangeFilterType = (
    newFilterType: SingleValue<SelectOption> | MultiValue<SelectOption>
  ) => {
    setFilterTypeValue(newFilterType);
    localStorage.setItem('filterTypeValue', JSON.stringify(newFilterType));
    updateQueryParams(
      'type',
      (newFilterType as MultiValue<SelectOption>).map(({ value }) => value.toString())
    );
  };

  const handleChangeFilterApprovalStatus = (
    newFilterApprovalStatus: SingleValue<SelectOption> | MultiValue<SelectOption>
  ) => {
    setFilterApprovalStatusValue(newFilterApprovalStatus);
    localStorage.setItem('filterApprovalStatusValue', JSON.stringify(newFilterApprovalStatus));
    updateQueryParams(
      'status',
      (newFilterApprovalStatus as MultiValue<SelectOption>).map(({ value }) => value.toString())
    );
  };

  useEffect(() => {
    if (filterTypeValue) {
      const filters = Array.isArray(filterTypeValue)
        ? filterTypeValue.map(({ value }) => value).join(',')
        : undefined;
      setFiltersType(String(filters));
    }
  }, [filterTypeValue]);

  useEffect(() => {
    if (filterApprovalStatusValue) {
      const filters = Array.isArray(filterApprovalStatusValue)
        ? filterApprovalStatusValue.map(({ value }) => value).join(',')
        : undefined;
      setFiltersApprovalStatus(String(filters));
    }
  }, [filterApprovalStatusValue]);

  return (
    <Box>
      <VStack align='stretch' gap='1' mx='3rem' my='1em'>
        <SadStates
          states={[
            {
              when:
                isLoadingData || isLoadingTimelineAbsences || isLoadingUsers || isLoadingFilters,
              render: () => <PageLoadingState />
            }
          ]}>
          <>
            <Stack
              align='center'
              height='28px'
              direction='row'
              display='flex'
              justifyContent='space-between'>
              <Box display='flex' alignItems='center'>
                <Switch
                  id='switchAbsences'
                  size='lg'
                  onChange={() => setIsOpenTableView(!isOpenTableView)}
                />
                <ChakraText marginLeft='10px'>{t('switchToTableView')}</ChakraText>
              </Box>
              {!isOpenTableView && <CalendarLegend />}
            </Stack>
            {isOpenTableView ? (
              allData?.data && (
                <GenericTable
                  data={handleData()}
                  columns={getColumns()}
                  pageIndex={pageIndex}
                  pageSize={pageSize}
                  pageCount={allData?.data ? allData.data.totalPages : 0}
                  setPagination={setPagination}
                  defaultHiddenColumns={defaultHiddenColumns}
                  handleApprove={(row) => handleApprove(row)}
                  handleReject={(row) => handleReject(row)}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  handleCreateData={() => setShowCreateEditModal(true)}
                  handleSearch={(value) => handleSearch(value)}
                  filter={
                    <AbsenceFilter
                      filterTypeValue={filterTypeValue}
                      handleChangeFilterType={handleChangeFilterType}
                      filterApprovalStatusValue={filterApprovalStatusValue}
                      handleChangeFilterApprovalStatus={handleChangeFilterApprovalStatus}
                    />
                  }
                  localStorageKey='allLeaves'
                  dragAndDropLocalStorageKey='dndAllLeaves'
                  sorting={sorting}
                  searchValue={search}
                  setSorting={setSorting}
                  permissions={permissionsAbsences}
                />
              )
            ) : (
              <AllAbsencesTimeline
                data={allTimelineAbsencesData?.data?.items}
                setYear={setYear}
                yearsForFiltering={year}
                filter={
                  <AbsenceFilter
                    filterTypeValue={filterTypeValue}
                    handleChangeFilterType={handleChangeFilterType}
                    filterApprovalStatusValue={filterApprovalStatusValue}
                    handleChangeFilterApprovalStatus={handleChangeFilterApprovalStatus}
                  />
                }
                handleCreateData={() => setShowCreateEditModal(true)}
                handleSearch={(value) => handleSearch(value)}
                searchValue={search}
                dataUsers={allUsers?.data?.items}
              />
            )}
          </>
        </SadStates>
      </VStack>
      <Restricted to={[PERMISSION_TYPE.CREATEABSENCEBYADMIN, PERMISSION_TYPE.EDITABSENCEBYADMIN]}>
        <CreateEditAbsence
          isMyAbsence={false}
          isOpen={showCreateEditModal}
          onClose={handleCloseCreateEdit}
          selectedAbsenceId={selectedAbsenceId}
        />
      </Restricted>
      <Restricted to={[PERMISSION_TYPE.PARTIALEDITABSENCE]}>
        <ApproveRejectAbsence
          isOpen={isModalOpen}
          onClose={onClose}
          absenceId={absenceId}
          isApprove={isApproveAbsenceRequest}
        />
      </Restricted>
      <Restricted to={[PERMISSION_TYPE.DELETEABSENCE]}>
        <CustomConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={handleCloseDeleteModal}
          confirmButtonLabel={t('yesDelete')}
          message={`${t('confirmAbsenceDeletion')}`}
          modalTitle={t('deleteAbsenceRequest')}
          isSubmitting={isLoadingDeleteAbsence}
          handleClick={() => deleteAbsence(Number(absenceId))}
        />
      </Restricted>
    </Box>
  );
};
