import { useTranslation } from 'react-i18next';
import { SingleValue, MultiValue, ActionMeta } from 'react-select';
import { Box, MenuList, MenuItem } from '@chakra-ui/react';

import { CustomSelect } from '@/components';
import { ABSENCE_APPROVAL_STATUS, ABSENCE_TYPE } from '@/enums';
import { SelectOption } from '@/types';

export type AbsenceFilterProps = {
  filterTypeValue: SingleValue<SelectOption> | MultiValue<SelectOption>;
  filterApprovalStatusValue: SingleValue<SelectOption> | MultiValue<SelectOption>;
  handleChangeFilterType: (
    newFilterType: SingleValue<SelectOption> | MultiValue<SelectOption>
  ) => void;
  handleChangeFilterApprovalStatus: (
    newFilterApprovalStatus: SingleValue<SelectOption> | MultiValue<SelectOption>
  ) => void;
};

export const AbsenceFilter = ({
  filterTypeValue,
  handleChangeFilterType,
  filterApprovalStatusValue,
  handleChangeFilterApprovalStatus
}: AbsenceFilterProps) => {
  const [t] = useTranslation('common');
  const absenceTypeObjects = Object.keys(ABSENCE_TYPE)
    .filter((v) => isNaN(Number(v)))
    .map((label) => {
      return {
        value: ABSENCE_TYPE[label as keyof typeof ABSENCE_TYPE],
        label: t(`${label}`) || label
      };
    });

  const approvalStatusObjects = Object.keys(ABSENCE_APPROVAL_STATUS)
    .filter((v) => isNaN(Number(v)))
    .map((label) => {
      return {
        value: ABSENCE_APPROVAL_STATUS[label as keyof typeof ABSENCE_APPROVAL_STATUS],
        label: t(`${label}`) || label
      };
    });

  const handleFilterTypeChange = (
    newValue: MultiValue<SelectOption> | SingleValue<SelectOption>,
    action: ActionMeta<SelectOption>
  ) => {
    const { action: actionType } = action;
    if (actionType === 'clear') {
      handleChangeFilterType([]);
      return;
    }
    handleChangeFilterType(newValue);
  };

  const handleFilterApprovalStatusChange = (
    newValue: MultiValue<SelectOption> | SingleValue<SelectOption>,
    action: ActionMeta<SelectOption>
  ) => {
    const { action: actionType } = action;
    if (actionType === 'clear') {
      handleChangeFilterApprovalStatus([]);
      return;
    }
    handleChangeFilterApprovalStatus(newValue);
  };

  return (
    <MenuList minWidth='300px'>
      <MenuItem closeOnSelect={false} h='70px'>
        <Box w='100%' onClick={(e) => e.stopPropagation()}>
          <CustomSelect
            id='filterAbsenceType'
            isMulti={true}
            value={filterTypeValue}
            placeholder={t('filterByType')}
            handleChange={(newValue, action) => handleFilterTypeChange(newValue, action)}
            options={absenceTypeObjects}
          />
        </Box>
      </MenuItem>
      <MenuItem>
        <Box w='100%' onClick={(e) => e.stopPropagation()}>
          <CustomSelect
            id='filterApprovalStatus'
            isMulti={true}
            value={filterApprovalStatusValue}
            placeholder={t('filterByApprovalStatus')}
            handleChange={(newValue, action) => handleFilterApprovalStatusChange(newValue, action)}
            options={approvalStatusObjects}
          />
        </Box>
      </MenuItem>
    </MenuList>
  );
};
