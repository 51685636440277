import { AxiosResponse } from 'axios';

export const downloadFile = (res?: AxiosResponse) => {
  const url = window.URL.createObjectURL(new Blob([res?.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'file.csv');
  document.body.appendChild(link);
  link.click();
  link.remove();
};
