import { useTranslation } from 'react-i18next';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Button, Menu, MenuButton } from '@chakra-ui/react';

export type CustomFilterProps = {
  children: React.ReactNode;
  isPublicHoliday?: boolean;
};

export const CustomFilter = ({ children, isPublicHoliday }: CustomFilterProps) => {
  const [t] = useTranslation('common');

  return (
    <Box h='100%' display='flex' alignItems='center'>
      {isPublicHoliday ? (
        <Box>{children}</Box>
      ) : (
        <Menu placement='bottom-end' autoSelect={false}>
          {({ isOpen }) => (
            <>
              <MenuButton
                isActive={isOpen}
                as={Button}
                rightIcon={<ChevronDownIcon boxSize={6} m='0' />}>
                {t('filter')}
              </MenuButton>
              {isOpen && <>{children}</>}
            </>
          )}
        </Menu>
      )}
    </Box>
  );
};
