import axios from 'axios';
import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';

import { apiUrl } from '@/helpers';
import { Options, HolidayProps } from '@/types';

const RELIGIOUS_HOLIDAY__API_URL = '/v1/ReligiousHolidays';
const RELIGIOUS_HOLIDAY_QUERY_KEY = 'religiousHoliday';
const GET_RELIGIOUS_HOLIDAY_BY_ID_QUERY_KEY = 'getReligiousHoliday';

export const religiousHolidayApi = {
  create: (payload: HolidayProps) => axios.post(`${apiUrl()}${RELIGIOUS_HOLIDAY__API_URL}`, payload),
  list: (pageSize: number, currentPage: number, orderBy?: string, orderByDesc?: boolean, search?: string) =>
    axios.get(
      `${apiUrl()}${RELIGIOUS_HOLIDAY__API_URL}?pageSize=${pageSize}&currentPage=${currentPage}&orderBy=${orderBy}&orderByDesc=${orderByDesc}&search=${search}`
    ),
  updateReligiousHoliday: (payload: HolidayProps) => axios.put(`${apiUrl()}${RELIGIOUS_HOLIDAY__API_URL}/${payload.id}`, payload),
  getReligiousHoliday: (id: number) => axios.get(`${apiUrl()}${RELIGIOUS_HOLIDAY__API_URL}/${id}`),
  deleteReligiousHoliday: (id: number) => axios.delete(`${apiUrl()}${RELIGIOUS_HOLIDAY__API_URL}/${id}`)
};

export const useGetReligiousHolidaysQuery = (
  pageSize: number,
  currentPage: number,
  orderBy?: string,
  orderByDesc?: boolean,
  options?: Options,
  search?: string
) => {
  return useQuery(
    [RELIGIOUS_HOLIDAY_QUERY_KEY, { pageSize, currentPage, orderBy, orderByDesc, search }],
    () => religiousHolidayApi.list(pageSize, currentPage, orderBy, orderByDesc, search),
    {
      enabled: options?.enabled,
      keepPreviousData: true,
      onSuccess: (response) => {
        options?.onSuccess(response);
      },
      onError: (error) => {
        options?.onError(error);
      }
    }
  );
};

export const useGetReligiousHoliday = (id: number) => {
  return useQuery([GET_RELIGIOUS_HOLIDAY_BY_ID_QUERY_KEY, { id }], () => religiousHolidayApi.getReligiousHoliday(id), { enabled: !!id });
};

export const useCreateReligiousHolidayMutation = (queryClient: QueryClient, options: Options) => {
  return useMutation(religiousHolidayApi.create, {
    onSuccess: (response) => {
      options.onSuccess(response);
      queryClient.invalidateQueries([RELIGIOUS_HOLIDAY_QUERY_KEY]);
    },
    onError: (error) => {
      options.onError(error);
    }
  });
};

export const useUpdateReligiousHolidayMutation = (queryClient: QueryClient, options: Options) => {
  return useMutation(religiousHolidayApi.updateReligiousHoliday, {
    onSuccess: (response) => {
      options.onSuccess(response);
      queryClient.invalidateQueries([RELIGIOUS_HOLIDAY_QUERY_KEY]);
    },
    onError: (error) => {
      options.onError(error);
    }
  });
};

export const useDeleteReligiousHolidayMutation = (queryClient: QueryClient, options: Options) => {
  return useMutation(religiousHolidayApi.deleteReligiousHoliday, {
    onSuccess: (response) => {
      options.onSuccess(response);
      queryClient.invalidateQueries([RELIGIOUS_HOLIDAY_QUERY_KEY]);
    },
    onError: (error) => {
      options.onError(error);
    }
  });
};
