import axios from 'axios';
import { useQuery, useMutation, QueryClient } from '@tanstack/react-query';

import { ADMIN_QUERY_KEY } from '@/services';
import { CreateUserForm, ForgotPasswordForm, Options, RegistrateForm } from '@/types';
import { apiUrl } from '@/helpers';

const USER_API_URL = '/v1/Users';
const CURRENT_USER_API_URL = USER_API_URL + '/current-user';

const USER_QUERY_KEY = 'user';
const USER_BY_ID_QUERY_KEY = 'userById';
const CURRENT_USER_QUERY_KEY = 'current-user';

export const usersApi = {
  list: () => axios.get(`${apiUrl()}${USER_API_URL}`),
  getUserById: (id: string) => axios.get(`${apiUrl()}${USER_API_URL}/${id}`),
  currentUser: () => axios.get(`${apiUrl()}${CURRENT_USER_API_URL}`),
  deleteUser: (id: string) => axios.delete(`${apiUrl()}${USER_API_URL}/${id}`),
  setPassword: ({ token, payload }: { token: string; payload: RegistrateForm }) =>
    axios.post(`${apiUrl()}${USER_API_URL}/set_password?token=${token}`, payload),
  create: (payload: CreateUserForm) => axios.post(`${apiUrl()}${USER_API_URL}`, payload),
  forgotPassword: (payload: ForgotPasswordForm) => axios.post(`${apiUrl()}${USER_API_URL}/reset_password/${payload.email}`),
  getAllAdmins: () => axios.get(`${apiUrl()}${USER_API_URL}/get_admins`)
};

export const useGetUsersQuery = () => {
  return useQuery([USER_QUERY_KEY], usersApi.list);
};

export const useGetUserByIdQuery = (id: string, options?: Options) => {
  return useQuery(
    [USER_BY_ID_QUERY_KEY, { id }],
    () => usersApi.getUserById(id),
    {
      enabled: !!id,
      onError: (error) => {
        options?.onError(error);
      }
    }
  );
};

export const useGetCurrentUserQuery = (loggedIn: boolean) => {
  return useQuery([CURRENT_USER_QUERY_KEY, loggedIn], usersApi.currentUser, { enabled: loggedIn, cacheTime: 1000 });
};

export const useSetPasswordMutation = (queryClient: QueryClient, options: Options) => {
  return useMutation(usersApi.setPassword, {
    onSuccess: (response) => {
      options.onSuccess(response);
      queryClient.invalidateQueries([USER_QUERY_KEY]);
    },
    onError: (error) => {
      options.onError(error);
    }
  });
};

export const useCreateUserMutation = (queryClient: QueryClient, options: Options) => {
  return useMutation(usersApi.create, {
    onSuccess: (response) => {
      options.onSuccess(response);
      queryClient.invalidateQueries([ADMIN_QUERY_KEY]);
    },
    onError: (error) => {
      options.onError(error);
    }
  });
};

export const useForgotPasswordMutation = (queryClient: QueryClient, options: Options) => {
  return useMutation(usersApi.forgotPassword, {
    onSuccess: () => {
      options.onSuccess();
      queryClient.invalidateQueries([USER_QUERY_KEY]);
    },
    onError: (error) => {
      options.onError(error);
    }
  });
};

export const useDeleteUserMutation = (queryClient: QueryClient, options: Options) => {
  return useMutation(usersApi.deleteUser, {
    onSuccess: () => {
      options.onSuccess();
      queryClient.invalidateQueries([ADMIN_QUERY_KEY]);
    },
    onError: (error) => {
      options.onError(error);
    }
  });
};

export const useGetAllAdminsQuery = (options?: Options) => {
  return useQuery([USER_QUERY_KEY], usersApi.getAllAdmins, {
    onError: (error) => {
      options?.onError(error);
    }
  });
};
