import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { initializeApp } from 'firebase/app';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import './i18n';
import './index.css';
import { App } from '@/components';
import { UserProvider } from '@/contexts';
import { firebaseConfig } from '@/helpers';
import { themeConfiguration } from './resources';

initializeApp(firebaseConfig);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <QueryClientProvider client={queryClient}>
    <I18nextProvider i18n={i18next}>
      <ChakraProvider theme={themeConfiguration}>
        <UserProvider>
          <DndProvider backend={HTML5Backend}>
            <App />
          </DndProvider>
        </UserProvider>
      </ChakraProvider>
    </I18nextProvider>
  </QueryClientProvider>
);
