import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  VStack
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import i18next from 'i18next';
import * as Yup from 'yup';

import { CustomInput } from '@/components';
import { handleError, useErrorToast, useSuccessToast } from '@/helpers';
import { themeConfiguration } from '@/resources';
import { ReactComponent as FlagEN } from '@/resources/img/flagEN.svg';
import { ReactComponent as FlagRS } from '@/resources/img/flagRS.svg';
import { ReactComponent as Logo } from '@/resources/img/leawie.svg';
import { useSetPasswordMutation } from '@/services';
import { RegistrateForm } from '@/types';

export const ConfirmPassword = () => {
  const [t] = useTranslation('common');
  const navigate = useNavigate();
  const [serverError, setServerError] = useState('');
  const [token, setToken] = useState('');
  const routerlocation = useLocation();
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();
  const queryClient = useQueryClient();

  useEffect(() => {
    setToken(routerlocation.search.split('t=')[1]);
  }, []);

  const { mutate: setPassword } = useSetPasswordMutation(queryClient, {
    onSuccess: (response?: AxiosResponse) => {
      successToast({ title: t('successfulPasswordSet', { response }) });
      navigate('/login');
    },
    onError: (error) => {
      errorToast(handleError(error));
    }
  });

  const initialValues = { password: '', confirmPassword: '' };

  const loginSchema = Yup.object().shape({
    password: Yup.string().min(6, t('minPass')).required(t('passRequired')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('passMatch'))
      .required(t('passRequired'))
  });

  const handleSubmit = async (values: RegistrateForm) => {
    const payload = {
      password: values.password
    };
    setPassword({ token, payload });
  };

  return (
    <>
      <Flex minW='100px'>
        <Menu arrowPadding={8} autoSelect={false}>
          <MenuButton as={Button} position='absolute' top='1rem' right='1rem' w='6.5rem'>
            <Box display='flex' alignItems='center' justifyContent='center'>
              {i18next.resolvedLanguage === 'en' ? <FlagEN /> : <FlagRS />}
              {i18next.resolvedLanguage.toLocaleUpperCase()}
            </Box>
          </MenuButton>
          <MenuList bg='brand.500' minW='50px' p={0} w='6.5rem' borderColor='brand.500'>
            <MenuOptionGroup defaultValue={'en'} type='radio'>
              <MenuItemOption
                value={'en'}
                onClick={() => i18next.changeLanguage('en')}
                _hover={{ backgroundColor: 'brand.50', color: 'black' }}>
                <FlagEN /> {t('en')}
              </MenuItemOption>
              <MenuItemOption
                value={'rs'}
                onClick={() => i18next.changeLanguage('rs')}
                _hover={{ backgroundColor: 'brand.50', color: 'black' }}>
                <FlagRS /> {t('rs')}
              </MenuItemOption>
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </Flex>
      <VStack
        style={{
          display: 'flex',
          width: '100%',
          height: '90vh',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Box width='500px' textAlign='center' height='230px'>
          <Logo />
        </Box>
        <Formik
          validateOnMount
          validateOnChange
          initialValues={initialValues}
          validationSchema={loginSchema}
          onSubmit={handleSubmit}>
          {({ isSubmitting, isValid, errors, touched }) => (
            <Form onInput={() => setServerError('')}>
              <VStack
                p='10%'
                w='500px'
                bg={`${themeConfiguration.styles.popover}`}
                borderRadius='lg'
                boxShadow='lg'>
                <CustomInput
                  type='password'
                  maxLength={50}
                  isInvalid={!!errors.password && touched.password}
                  id='password'
                  label={t('newPassword')}
                  error={errors.password}
                />
                <CustomInput
                  type='password'
                  maxLength={50}
                  isInvalid={!!errors.confirmPassword && touched.confirmPassword}
                  id='confirmPassword'
                  label={t('confirmPassword')}
                  error={errors.confirmPassword}
                />
                <FormControl isInvalid={!!serverError}>
                  <FormErrorMessage>{serverError}</FormErrorMessage>
                </FormControl>
                <Button
                  type='submit'
                  minW='150px'
                  size='lg'
                  top='15px'
                  disabled={isSubmitting || !isValid || !!serverError}>
                  {t('setPassword')}
                </Button>
              </VStack>
            </Form>
          )}
        </Formik>
      </VStack>
    </>
  );
};
