import React, { FC } from 'react';
import { Center } from '@chakra-ui/react';

import { usePermission } from '@/hooks';

type Props = {
    to: number[];
    fallback?: JSX.Element | string;
    loadingComponent?: JSX.Element | string;
    children: React.ReactNode | (() => void);
};

export const Restricted: FC<Props> = ({to, fallback, loadingComponent, children}) => {

  const [loading, allowed] = usePermission(to);

  if (loading) {
    return <><Center>{loadingComponent}</Center></>;
  }

  if (allowed) {
    return <>{children}</>;
  }

  return <>{fallback}</>;
};
