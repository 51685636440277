import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import common_en from './translations/en/common.json';
import common_rs from './translations/rs/common.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      common: common_en
    },
    rs: {
      common: common_rs
    }
  },
  keySeparator: false,
  interpolation: { escapeValue: false },
  lng: JSON.parse(localStorage.getItem('language') || JSON.stringify('en'))
});

export default i18n;
