import { PERMISSION_TYPE } from '@/enums';

export const allowedPathsByPermission = (permissions: number[]) => {
  if (permissions.includes(PERMISSION_TYPE.VIEWRELIGIOUSHOLIDAYS)) {
    return [
      {
        label: 'absences',
        path: '/leave'
      },
      {
        label: 'myAbsences',
        path: '/myLeave'
      },
      {
        label: 'religiousHolidays',
        path: '/religiousHoliday'
      },
      {
        label: 'publicHolidays',
        path: '/publicHolidays'
      },
      {
        label: 'employees',
        path: '/usersListAdmin'
      },
      {
        label: 'worklog',
        path: '/worklog'
      }
    ];
  } else {
    return [
      {
        label: 'myAbsences',
        path: '/myLeave'
      },
      {
        label: 'publicHolidays',
        path: '/publicHolidays'
      }
    ];
  }
};
