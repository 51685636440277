export enum PERMISSION_TYPE {
    'CREATEUSER' = 11,
    'VIEWUSERS' = 12,
    'VIEWUSERBYID' = 13,
    'EDITUSER' = 14,
    'EDITUSERBYID' = 15,
    'DELETEUSER' = 16,
    'GETCURRENTUSER' = 17,
    'GETADMINS' = 18,
    'CREATEABSENCE' = 21,
    'CREATEABSENCEBYADMIN' = 22,
    'VIEWABSENCES' = 23,
    'VIEWABSENCEBYID' = 24,
    'EDITABSENCEBYUSER' = 25,
    'EDITABSENCEBYADMIN' = 26,
    'DELETEABSENCE' = 27,
    'PARTIALEDITABSENCE' = 28,
    'CREATEPUBLICHOLIDAY' = 31,
    'VIEWPUBLICHOLIDAYS' = 32,
    'VIEWPUBLICHOLIDAYBYID' = 33,
    'EDITPUBLICHOLIDAY' = 34,
    'DELETEPUBLICHOLIDAY' = 35,
    'PARTIALEDITPUBLICHOLIDAY' = 36,
    'CREATERELIGIOUSHOLIDAY' = 41,
    'VIEWRELIGIOUSHOLIDAYS' = 42,
    'VIEWRELIGIOUSHOLIDAYBYID' = 43,
    'EDITRELIGIOUSHOLIDAY' = 44,
    'DELETERELIGIOUSHOLIDAY' = 45,
    'PARTIALEDITRELIGIOUSHOLIDAY' = 46,
    'VIEWALLROLES' = 51,
    'VIEWROLEBYID' = 52,
    'VIEWROLEBYNAME' = 53,
    'CREATETEAM' = 61,
    'VIEWTEAMS' = 62,
    'VIEWTEAMBYID' = 63,
    'EDITTEAM' = 64,
    'DELETEETEAM' = 65,
    'VIEWWORKLOG' = 71
}
