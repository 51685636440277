import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Center, Heading, Text as Info } from '@chakra-ui/react';

export const Unauthorized = () => {
  const navigate = useNavigate();
  const [t] = useTranslation('common');
  return (
    <Center>
      <Box
        position='relative'
        bg='gray.50'
        p={5}
        borderRadius='lg'
        boxShadow='lg'
        h='200px'
        w='800px'
        mt='10%'>
        <Heading mb={4}>{t('unauthorized')}</Heading>
        <Info fontSize='xl'>{t('unauthMessage')}</Info>
        <Button mb={5} mr={5} position='absolute' right='0' bottom='0' onClick={() => navigate(-1)}>
          {t('goBack')}
        </Button>
      </Box>
    </Center>
  );
};
