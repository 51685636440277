import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button
} from '@chakra-ui/react';

import { themeConfiguration } from '@/resources';

export type CustomConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  handleClick: () => void;
  confirmButtonLabel: string;
  message: string;
  modalTitle: string;
  isSubmitting?: boolean;
};

export const CustomConfirmationModal = ({
  isOpen,
  onClose,
  handleClick,
  confirmButtonLabel,
  message,
  modalTitle,
  isSubmitting = false
}: CustomConfirmationModalProps) => {
  const cancelRef = useRef(null);
  const [t] = useTranslation('common');

  return (
    <AlertDialog
      motionPreset='slideInBottom'
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      size='lg'>
      <AlertDialogOverlay />
      <AlertDialogContent backgroundColor={`${themeConfiguration.styles.popover}`}>
        <AlertDialogHeader>{modalTitle}</AlertDialogHeader>
        <AlertDialogBody>{message}</AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            {t('noCancel')}
          </Button>
          <Button ml={3} disabled={isSubmitting} onClick={handleClick}>
            {confirmButtonLabel}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
