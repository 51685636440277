import { useTranslation } from 'react-i18next';
import { Center, Spinner, Text as Info, VStack } from '@chakra-ui/react';

import { themeConfiguration } from '@/resources';

export const ModalLoadingState = () => {
  const [t] = useTranslation('common');
  return (
    <Center h='64vh' color={`${themeConfiguration.styles.spinner}`}>
      <VStack>
        <Spinner size='xl' />
        <Info>{t('loading')}</Info>
      </VStack>
    </Center>
  );
};

export const ModalErrorState = () => {
  const [t] = useTranslation('common');
  return (
    <Center h='64vh' color={`${themeConfiguration.styles.spinner}`}>
      <VStack>
        <Info fontSize='20px'>{t('errorMessage')}</Info>
      </VStack>
    </Center>
  );
};
