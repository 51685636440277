import { ChangeEvent } from 'react';
import { Checkbox, FormControl, FormErrorMessage, FormLabel, InputProps } from '@chakra-ui/react';

export interface CustomInputCheckboxProps extends InputProps {
  id: string;
  label?: string;
  error?: string;
  isChecked?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const CustomInputCheckbox = (props: CustomInputCheckboxProps) => {
  return (
    <FormControl isInvalid={props.isInvalid}>
      <Checkbox mt='2' name={props.id} id={props.id} onChange={props.onChange} isChecked={props.isChecked}>
        <FormLabel m={0} htmlFor={props.id}>{props.label}</FormLabel>
      </Checkbox>
      <FormErrorMessage mt='-1' mb='1'>
        {props.error}
      </FormErrorMessage>
    </FormControl>
  );
};
