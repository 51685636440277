import { useTranslation } from 'react-i18next';
import { CheckCircleIcon, CloseIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuOptionGroup
} from '@chakra-ui/react';

import { Restricted } from '@/components';
import { PERMISSION_TYPE } from '@/enums';
import { ReactComponent as KebabIcon } from '@/resources/img/kebab_icon.svg';

export const TableContextMenu = ({
  handleEdit,
  handleDelete,
  handleView,
  handleApprove,
  handleReject,
  handleDeleteAbsence,
  handleEditByUser
}: {
  handleEdit?: () => void;
  handleDelete?: () => void;
  handleView?: () => void;
  handleApprove?: () => void;
  handleReject?: () => void;
  handleDeleteAbsence?: () => void;
  handleEditByUser?: () => void;
}) => {
  const [t] = useTranslation('common');

  return (
    <Menu arrowPadding={8} autoSelect={false}>
      <MenuButton
        as={IconButton}
        mr='10px'
        w='16px'
        minW='16px'
        colorScheme='white'
        icon={<KebabIcon color={localStorage.getItem('chakra-ui-color-mode') === 'dark' ? 'white' : 'black'} />}
      />
      <MenuList bg='brand.500' minW='50px' borderColor='brand.500' color='black'>
        <MenuOptionGroup defaultValue={t('en')}>
          <Restricted to={[PERMISSION_TYPE.EDITUSERBYID, PERMISSION_TYPE.EDITABSENCEBYADMIN, PERMISSION_TYPE.EDITPUBLICHOLIDAY, PERMISSION_TYPE.EDITRELIGIOUSHOLIDAY]}>
            {handleEdit && (
              <MenuItem
                value={'edit'}
                onClick={handleEdit}
                _hover={{ background: 'brand.50', color: 'black' }}>
                <EditIcon /> {t('edit')}
              </MenuItem>
            )}
          </Restricted>
          <Restricted to={[PERMISSION_TYPE.EDITABSENCEBYUSER]}>
            {handleEditByUser && (
              <MenuItem
                value={'edit'}
                onClick={handleEditByUser}
                _hover={{ background: 'brand.50', color: 'black' }}>
                <EditIcon /> {t('edit')}
              </MenuItem>
            )}
          </Restricted>
          <Restricted to={[PERMISSION_TYPE.VIEWUSERS]}>
            {handleView && (
              <MenuItem
                value={'viewUsersWhoCelebrate'}
                onClick={handleView}
                _hover={{ background: 'brand.50', color: 'black' }}>
                <EditIcon fontSize='18px' />
                {t('viewUsers')}
              </MenuItem>
            )}
          </Restricted>
          <Restricted to={[PERMISSION_TYPE.PARTIALEDITABSENCE]}>
            {handleApprove && (
              <MenuItem
                value={'approve'}
                onClick={handleApprove}
                _hover={{ background: 'brand.50', color: 'black' }}>
                <CheckCircleIcon /> {t('approve')}
              </MenuItem>
            )}
            {handleReject && (
              <MenuItem
                value={'reject'}
                onClick={handleReject}
                _hover={{ background: 'brand.50', color: 'black' }}>
                <CloseIcon /> {t('reject')}
              </MenuItem>
            )}
          </Restricted>
          <Restricted to={[PERMISSION_TYPE.DELETEUSER, PERMISSION_TYPE.DELETEABSENCE, PERMISSION_TYPE.DELETEPUBLICHOLIDAY, PERMISSION_TYPE.DELETERELIGIOUSHOLIDAY]}>
            {handleDelete && (
              <MenuItem
                value={'delete'}
                onClick={handleDelete}
                _hover={{ background: 'brand.50', color: 'black' }}>
                <DeleteIcon /> {t('delete')}
              </MenuItem>
            )}
          </Restricted>
          <Restricted to={[PERMISSION_TYPE.DELETEABSENCE]}>
            {handleDeleteAbsence && (
              <MenuItem
                value={'delete'}
                onClick={handleDeleteAbsence}
                _hover={{ background: 'brand.50', color: 'black' }}>
                <DeleteIcon /> {t('delete')}
              </MenuItem>
            )}
          </Restricted>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
