export const getPageTitleHelper = (pageTitle: string) => {
  switch (pageTitle) {
    case 'religiousHoliday':
      return 'religiousHolidays';
    case 'publicHolidays':
      return 'publicHolidays';
    case 'leave':
      return 'absences';
    case 'myLeave':
      return 'myAbsences';
    case 'myProfile':
      return 'myProfile';
    case 'usersListAdmin':
      return 'employees';
    case 'login':
      return 'absences';
    case 'worklog':
      return 'worklog';
    default:
      return pageTitle;
  }
};
