import { useTranslation } from 'react-i18next';

import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text as ChakraText
} from '@chakra-ui/react';

import { ReactComponent as ViewUsers } from '@/resources/img/users.svg';
import { HolidayProps } from '@/types';

type CalendarEventTitleProps = {
  holiday: HolidayProps;
  handleEdit: (e: HolidayProps) => void;
  handleDelete: (e: HolidayProps) => void;
  isReligiousHolidays?: boolean;
  handleViewUsersWhoCelebrate?: (id: number) => void;
};

export const CalendarEventTitle = ({
  holiday,
  handleEdit,
  handleDelete,
  isReligiousHolidays,
  handleViewUsersWhoCelebrate
}: CalendarEventTitleProps) => {
  const [t] = useTranslation('common');

  return (
    <Popover trigger='hover'>
      <PopoverTrigger>
        <Box w='100%' h='100%' display='flex' alignItems='center' justifyContent='center'>
          {holiday.name}
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          w='150px'
          display='flex'
          backgroundColor='#27a7a7'
          color='white'
          cursor='pointer'>
          <PopoverArrow />
          <PopoverBody p='0'>
            <Box
              w='100%'
              display='flex'
              alignItems='center'
              padding='5px'
              _hover={{ bg: '#fff', color: '#27a7a7' }}
              onClick={() => handleEdit(holiday)}>
              <EditIcon cursor='pointer' />
              <ChakraText cursor='pointer'>{t('edit')}</ChakraText>
            </Box>
            {isReligiousHolidays && handleViewUsersWhoCelebrate && (
              <Box
                w='100%'
                className='users'
                display='flex'
                alignItems='center'
                padding='5px'
                _hover={{ bg: '#fff', color: '#27a7a7' }}
                onClick={() => handleViewUsersWhoCelebrate(Number(holiday.id))}>
                <ViewUsers className='users-svg' style={{ width: '16px', height: '16px' }} />
                <ChakraText cursor='pointer'>{t('employees')}</ChakraText>
              </Box>
            )}
            <Box
              display='flex'
              alignItems='center'
              padding='5px'
              _hover={{ bg: '#fff', color: '#27a7a7' }}
              onClick={() => handleDelete(holiday)}>
              <DeleteIcon cursor='pointer' />
              <ChakraText cursor='pointer'>{t('delete')}</ChakraText>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
