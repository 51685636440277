import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

import { apiUrl } from '@/helpers';

const ROLE_API_URL = '/v1/Roles';
const ROLE_QUERY_KEY = 'roles';

export const roleApi = {
  getAll: () => axios.get(`${apiUrl()}${ROLE_API_URL}`)
};

export const useGetAllRolesQuery = () => {
  return useQuery([ROLE_QUERY_KEY], () => roleApi.getAll());
};
