import { format } from 'date-fns';

import { dateFormat } from './config';

export const toCamel = (s: string) => {
  return s.replace(/_([a-z])/g, (_, p1) => p1.toUpperCase());
};

export const formatDate = (dateString: string | number | Date) => {
  return format(new Date(dateString), dateFormat);
};
