import { useTranslation } from 'react-i18next';
import { AddIcon } from '@chakra-ui/icons';
import { Box, Button, Input, Spacer } from '@chakra-ui/react';

import { themeConfiguration } from '@/resources';
import { CustomFilter } from './CustomFilter';

type HeaderOptionsProps = {
  handleSearch?: (value: string) => void;
  searchValue?: string;
  filter?: React.ReactNode;
  handleCreateData?: () => void;
  isPublicHoliday?: boolean;
};

export const HeaderOptions = ({
  handleSearch,
  searchValue,
  filter,
  handleCreateData,
  isPublicHoliday
}: HeaderOptionsProps) => {
  const [t] = useTranslation('common');

  return (
    <>
      {handleSearch && (
        <Input
          id='search'
          autoComplete='off'
          backgroundColor={`${themeConfiguration.styles.search}`}
          placeholder={t('search')}
          w='15%'
          type='search'
          borderColor='gray.400 !important'
          _hover={{ borderColor: 'gray.400 !important' }}
          onChange={(e) => handleSearch(e.target.value)}
          value={searchValue}
        />
      )}
      <Spacer />
      <Box zIndex='5'>
        {filter && <CustomFilter isPublicHoliday={isPublicHoliday}>{filter}</CustomFilter>}
      </Box>
      {handleCreateData && (
        <Button onClick={handleCreateData} w='61px'>
          <AddIcon viewBox='0 0 20 24' />
        </Button>
      )}
    </>
  );
};
