import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  VStack,
  Center,
  Spinner,
  Box,
  TableContainer,
  Table,
  Td,
  Thead,
  Th,
  Tbody,
  Tr,
  Button
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { initialAxiosResponse } from '@/helpers';
import { themeConfiguration } from '@/resources';
import { useGetReligiousHoliday } from '@/services';
import { User } from '@/types';

export type ViewHolidayUserProps = {
  isOpen: boolean;
  onClose: () => void;
  holidayId?: number;
};

export const ViewHolidayUsers = ({ isOpen, onClose, holidayId }: ViewHolidayUserProps) => {
  const { data: holidayData = initialAxiosResponse, isLoading } = useGetReligiousHoliday(
    Number(holidayId)
  );
  const [t] = useTranslation('common');

  return (
    <Modal isCentered={true} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW='650px' backgroundColor={`${themeConfiguration.styles.avatar}`}>
        <VStack width='100%'>
          {isLoading && (
            <Center sx={{ position: 'absolute', top: '45%', left: '45%' }} color='black'>
              <Spinner size='xl' mr='4' color='white' />
            </Center>
          )}
          {!isLoading && holidayData?.data && (
            <>
              <TableContainer h='400px !important' w='100%' overflowY='auto' borderTopRadius='lg'>
                <Table variant='striped' colorScheme='gray'>
                  <Thead>
                    <Tr>
                      <Th bg='brand.600' color='white' fontSize='18px' fontWeight='500' textTransform='none'>
                        {t('firstName')}
                      </Th>
                      <Th bg='brand.600' color='white' fontSize='18px' fontWeight='500' textTransform='none'>
                        {t('lastName')}
                      </Th>
                      <Th bg='brand.600' color='white' fontSize='18px' fontWeight='500' textTransform='none' width='3%'>
                        {t('referenceNumber')}
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {holidayData?.data?.users.map((user: User) => (
                      <Tr key={user?.id}>
                        <Td bg={`${themeConfiguration.styles.td}`}>{user?.firstName}</Td>
                        <Td bg={`${themeConfiguration.styles.td}`}>{user?.lastName}</Td>
                        <Td bg={`${themeConfiguration.styles.td}`}>{user?.referenceNumber}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
              <Box display='flex' w='100%' justifyContent='right' padding='20px !important'>
                <Button w='100px' onClick={onClose}>
                  {t('close')}
                </Button>
              </Box>
            </>
          )}
        </VStack>
      </ModalContent>
    </Modal>
  );
};
