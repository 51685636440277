import { useEffect, useRef } from 'react';
import Select, { ActionMeta, MultiValue, Options, PropsValue, SingleValue } from 'react-select';
import { Box, FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';

import { themeConfiguration } from '@/resources';
import { SelectOption } from '@/types';

export interface SelectProps {
  id: string;
  label?: string;
  isMulti?: boolean;
  isDisabled?: boolean;
  handleChange: (
    newValue: MultiValue<SelectOption> | SingleValue<SelectOption>,
    action: ActionMeta<SelectOption>
  ) => void;
  options: Options<SelectOption> | undefined;
  defaultValue?: PropsValue<SelectOption>;
  value?: PropsValue<SelectOption>;
  placeholder?: string;
  isInvalid?: boolean;
  error?: string;
  onBlur?: () => void;
  handleMenuOpen?: () => void;
  handleMenuClose?: () => void;
  isSelectOpenScroll?: boolean;
}

export const CustomSelect = ({
  id,
  label,
  isMulti,
  isDisabled,
  handleChange,
  options,
  defaultValue,
  value,
  placeholder,
  isInvalid,
  error,
  onBlur,
  handleMenuOpen,
  handleMenuClose,
  isSelectOpenScroll
}: SelectProps) => {
  const errorRef = useRef<HTMLDivElement>(null);
  const selectBottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isInvalid) {
      errorRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    if (isSelectOpenScroll) {
      selectBottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isInvalid, isSelectOpenScroll]);

  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel mr='0' mb='0' htmlFor={id}>{label}</FormLabel>
      <Box position='relative' borderRadius='md' marginBottom='0.25rem' border={isInvalid ? '1px solid red' : `0.1px solid ${themeConfiguration.colors.brand[300]}`}>
        <Select
          inputId={id}
          name={id}
          isMulti={isMulti}
          isDisabled={isDisabled}
          onChange={handleChange}
          options={options}
          defaultValue={defaultValue}
          value={value}
          menuPlacement='auto'
          placeholder={placeholder}
          onMenuOpen={handleMenuOpen}
          onMenuClose={handleMenuClose}
          menuShouldScrollIntoView={false}
          styles={
            isMulti ? {
              control: (base) => ({
                ...base,
                height: '40px'
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? `${themeConfiguration.colors.brand[500]}` : (localStorage.getItem('chakra-ui-color-mode') === 'dark' ? 'gray.700' : '#FFFFFF'),
                color: localStorage.getItem('chakra-ui-color-mode') === 'dark' ? '#FFFFFF' : '#000000',
                ':hover': {
                  backgroundColor: state.isSelected ? `${themeConfiguration.colors.brand[500]}` : `${themeConfiguration.colors.brand[200]}`,
                  color: state.isSelected ? '#FFFFFF' : '#000000'
                },
              }),
            } : {
              control: (base) => ({
                ...base,
                height: '40px'
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? `${themeConfiguration.colors.brand[500]}` : (localStorage.getItem('chakra-ui-color-mode') === 'dark' ? 'gray.700' : '#FFFFFF'),
                color: localStorage.getItem('chakra-ui-color-mode') === 'dark' ? '#FFFFFF' : (state.isSelected ? '#FFFFFF' : '#000000'),
                ':hover': {
                  backgroundColor: state.isSelected ? `${themeConfiguration.colors.brand[500]}` : `${themeConfiguration.colors.brand[200]}`,
                  color: state.isSelected ? '#FFFFFF' : '#000000'
                },
              }),
            }
          }
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: `${themeConfiguration.colors.brand[500]}`,
              primary: `${themeConfiguration.colors.brand[500]}`,
              neutral0: localStorage.getItem('chakra-ui-color-mode') === 'dark' ? '#2D3748' : '#FFFFFF',
              neutral80: localStorage.getItem('chakra-ui-color-mode') === 'dark' ? (isMulti ? '#000000' : '#FFFFFF') : '#2D3748',
            }
          })}
          onBlur={onBlur}
        />
      </Box>
      <FormErrorMessage mt='1' mb='1' ref={errorRef}>
        {error}
      </FormErrorMessage>
      <div ref={selectBottomRef} />
    </FormControl>
  );
};
