import moment from 'moment';

import { themeConfiguration } from '@/resources';

export const MonthHelper = (currentYear: number) => {

  const months = [];
  for (let i = 0; i < 12; i++) {
    months.push({
      id: i,
      min: moment(new Date(currentYear, i, 1)).toDate(),
      max: moment(new Date(currentYear, i, 1)).endOf('month').toDate(),
      events: []
    });
  }
  return months;
};

export const formatDaysStyles = (date: Date) => {
  const dayName = moment(date).format('dddd');
  return {
    style: {
      backgroundColor: dayName === 'Saturday' || dayName === 'Sunday' ? (localStorage.getItem('chakra-ui-color-mode') === 'dark' ? 'var(--chakra-colors-gray-600)' : `${themeConfiguration.colors.brand[150]}`) : `${themeConfiguration.colors.brand[10]}`,
    }
  };
};

