import i18n from 'i18next';

import { ABSENCE_TYPE } from '@/enums';

export const getAbsenceColumns = () => [
  {
    id: 'type',
    accessorKey: 'type',
    header: i18n.t('type', { ns: 'common' })
  },
  {
    id: 'startsAt',
    accessorKey: 'startsAt',
    header: i18n.t('startDate', { ns: 'common' })
  },
  {
    id: 'endsAt',
    accessorKey: 'endsAt',
    header: i18n.t('endsAt', { ns: 'common' })
  },
  {
    id: 'approvalStatus',
    accessorKey: 'approvalStatus',
    header: i18n.t('approvalStatus', { ns: 'common' })
  },
  {
    id: 'createdAt',
    accessorKey: 'createdAt',
    header: i18n.t('createdAt', { ns: 'common' })
  },
  {
    id: 'requestMessage',
    accessorKey: 'requestMessage',
    header: i18n.t('requestMessage', { ns: 'common' })
  },
  {
    id: 'responseMessage',
    accessorKey: 'responseMessage',
    header: i18n.t('responseMessage', { ns: 'common' })
  },
  {
    id: 'updatedAt',
    accessorKey: 'updatedAt',
    header: i18n.t('updatedAt', { ns: 'common' })
  }
];

export const changeAbsenceBackgroundColor = (type?: number | string) => {
  switch (type) {
    case ABSENCE_TYPE.ANNUALLEAVE: {
      return '#ADD8E6';
    }
    case ABSENCE_TYPE.SICKLEAVE: {
      return '#008B8B';
    }
    case ABSENCE_TYPE.UNPAIDLEAVE: {
      return '#EC87C0';
    }
    case ABSENCE_TYPE.FAMILYSPECIALEVENTLEAVE: {
      return '#ff9085';
    }
    case ABSENCE_TYPE.BLOODDONATIONLEAVE: {
      return '#DC143C';
    }
    case 'publicHolidays': {
      return '#4169E1';
    }
    case 'religiousHolidays': {
      return '#BA55D3';
    }
    default: {
      return '#f0c33c';
    }
  }
};

export const getStripePattern = (background: string) => {
  const stripePattern = `
  repeating-linear-gradient(45deg, rgba(${convertToRGB(background)}, 1), rgba(${convertToRGB(
    background
  )}, 1) 6px, transparent 2px, transparent 10px),
  repeating-linear-gradient(45deg, rgba(${convertToRGB(background)}, 1), rgba(${convertToRGB(
    background
  )}, 1) 6px, transparent 2px, transparent 10px),
  repeating-linear-gradient(45deg, rgba(${convertToRGB(background)}, 1), rgba(${convertToRGB(
    background
  )}, 1) 6px, transparent 2px, transparent 10px)
`;

  return stripePattern;
};

const convertToRGB = (color: string) => {
  const hexValue = color.replace('#', '');
  const r = parseInt(hexValue.substr(0, 2), 16);
  const g = parseInt(hexValue.substr(2, 2), 16);
  const b = parseInt(hexValue.substr(4, 2), 16);

  return `${r}, ${g}, ${b}`;
};
