import { useContext, useEffect, useState } from 'react';
import { SlotInfo } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { SingleValue } from 'react-select';

import { Box, Switch, Text as ChakraText, VStack } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { PaginationState, SortingState } from '@tanstack/react-table';
import moment from 'moment';

import {
  CalendarEventTitle,
  CreateEditPublicHoliday,
  CustomCalendar,
  CustomConfirmationModal,
  GenericTable,
  PageLoadingState,
  PublicHolidaysFilter,
  Restricted
} from '@/components';
import { UserContext } from '@/contexts';
import { PERMISSION_TYPE } from '@/enums';
import {
  formatDate,
  formatEventsStyles,
  getHolidayColumns,
  handleError,
  initialAxiosResponse,
  SadStates,
  useErrorToast,
  useSuccessToast
} from '@/helpers';
import { useDebounce } from '@/hooks';
import { useDeletePublicHolidayMutation, useGetAllPublicHolidaysQuery } from '@/services';
import { CustomView, HolidayProps, SelectOption, TableData } from '@/types';

export const PublicHolidays = () => {
  const { permissions } = useContext(UserContext);
  const [t] = useTranslation('common');
  const [sorting, setSorting] = useState<SortingState>([{ id: 'name', desc: false }]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCreateEditModal, setShowCreateEditModal] = useState(false);
  const [search, setSearch] = useState(
    JSON.parse(localStorage.getItem('publicHolidaysSearch') || 'null')
  );
  const [holidayId, setHolidayId] = useState<number>();
  const [holidayName, setHolidayName] = useState<string>();
  const [isOpenTableView, setIsOpenTableView] = useState<boolean>(false);
  const [view, setView] = useState<CustomView>('month');
  const [startDate, setStartDate] = useState(new Date());
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [filterYearValue, setFilterYearValue] = useState<SingleValue<SelectOption>>({
    value: new Date().getFullYear(),
    label: new Date().getFullYear().toString()
  });

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });
  const queryClient = useQueryClient();
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();
  const permissionsPublicHoliday = [
    PERMISSION_TYPE.CREATEPUBLICHOLIDAY,
    PERMISSION_TYPE.EDITPUBLICHOLIDAY,
    PERMISSION_TYPE.DELETEPUBLICHOLIDAY
  ];
  const [searchParams, setSearchParams] = useSearchParams();
  const [queryParams, setQueryParams] = useState(() => ({
    searchQuery: searchParams.get('searchQuery') ?? []
  }));
  const [debounceDelay, setDebounceDelay] = useState<number>(0);

  const handleChangeFilterYear = (newFilterType: SingleValue<SelectOption>) => {
    setFilterYearValue(newFilterType);
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('publicHolidaysSearch') || 'null') === null) {
      localStorage.setItem('publicHolidaysSearch', JSON.stringify(''));
    }

    if (searchParams.get('searchQuery') === null) {
      updateQueryParams(
        'searchQuery',
        JSON.parse(localStorage.getItem('publicHolidaysSearch') || 'null')
      );
      setSearch(JSON.parse(localStorage.getItem('publicHolidaysSearch') || 'null'));
    } else {
      setSearch(queryParams.searchQuery.toString());
      localStorage.setItem(
        'publicHolidaysSearch',
        JSON.stringify(queryParams.searchQuery.toString())
      );
    }
  }, []);

  useEffect(() => {
    setSearchParams({
      searchQuery: queryParams.searchQuery === '' ? [] : queryParams.searchQuery
    });

    deleteQueryParams('searchQuery');
  }, [queryParams, searchParams, setSearchParams]);

  const deleteQueryParams = (value: string) => {
    if (searchParams.has(value) && searchParams.get(value) === '') {
      searchParams.delete(value);
      updateQueryParams(value, []);
      setSearchParams(searchParams);
    }
  };

  const updateQueryParams = (key: string, value: string | string[]) => {
    setQueryParams((prevState) => ({ ...prevState, [key]: value }));
  };

  const { isLoading, data = initialAxiosResponse } = useGetAllPublicHolidaysQuery(
    pageSize,
    pageIndex + 1,
    sorting.map((s) => s.id.charAt(0).toUpperCase() + s.id.slice(1)).toString(),
    sorting.some((s) => s.desc),
    {
      onSuccess: () => void 0,
      onError: (error) => {
        errorToast(handleError(error));
      }
    },
    useDebounce(search, debounceDelay),
    String(filterYearValue?.value)
  );

  const { mutate: deleteHoliday, isLoading: isLoadingDeleteHoliday } =
    useDeletePublicHolidayMutation(queryClient, {
      onSuccess: () => {
        successToast({ title: t('successfulHolidayDelete') });
        handleCloseDelete();
      },
      onError: (error) => {
        errorToast(handleError(error));
      }
    });

  const handleEdit = (row: TableData | HolidayProps) => {
    setShowCreateEditModal(true);
    setHolidayId(row.id);
  };

  const handleDelete = (row: TableData | HolidayProps) => {
    setShowDeleteModal(true);
    setHolidayId(row.id);
    setHolidayName(row.name);
  };

  const handleCloseCreateEdit = () => {
    setShowCreateEditModal(false);
    setStartDate(new Date());
    setHolidayId(undefined);
  };

  const handleCloseDelete = () => {
    setShowDeleteModal(false);
    setHolidayId(undefined);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
    setPagination({ pageIndex: 0, pageSize });
    localStorage.setItem('publicHolidaysSearch', JSON.stringify(value));
    updateQueryParams('searchQuery', value);
    setDebounceDelay(500);
  };

  const handleViewChange = (newView: CustomView) => {
    setView(newView);
  };

  const handleCreatePublicHoliday = (slot: SlotInfo) => {
    setShowCreateEditModal(true);
    setStartDate(slot.start);
  };

  const formatEvents = (holiday: HolidayProps) => {
    return {
      id: holiday?.id,
      start: moment(holiday?.startsAt).startOf('day').toDate(),
      allDay: true,
      title:
        view === 'year' ? (
          holiday.name
        ) : permissions.includes(PERMISSION_TYPE.EDITPUBLICHOLIDAY) &&
          permissions.includes(PERMISSION_TYPE.DELETEPUBLICHOLIDAY) ? (
          <CalendarEventTitle
            holiday={holiday}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        ) : (
          holiday.name
        ),
      type: 'publicHolidays',
      end: moment(holiday.startsAt)
        .add(holiday.totalDaysCelebrated === 1 ? 0 : holiday.totalDaysCelebrated - 1, 'day')
        .endOf('day')
        .toDate()
    };
  };
  const publicHolidays = data?.data?.items.map((holiday: HolidayProps) => formatEvents(holiday));

  return (
    <>
      <VStack mx='3rem' my='1em' gap={1} align='stretch'>
        <SadStates
          states={[
            {
              when: isLoading,
              render: () => <PageLoadingState />
            }
          ]}>
          <>
            <Box>
              <Switch
                id='switchPublicHolidays'
                size='lg'
                onChange={() => setIsOpenTableView(!isOpenTableView)}
              />
              <ChakraText as='span' marginLeft='10px'>
                {t('switchToTableView')}
              </ChakraText>
            </Box>
            {data?.data && (
              <>
                {isOpenTableView ? (
                  <GenericTable
                    data={data?.data?.items?.map(
                      (item: {
                        startsAt: string | number | Date;
                        createdAt: string | number | Date;
                        updatedAt: string | number | Date;
                        overflowsToWorkdaysIfWeekend: boolean;
                        recurring: boolean;
                      }) => ({
                        ...item,
                        startsAt: formatDate(item.startsAt),
                        createdAt: formatDate(item.createdAt),
                        updatedAt: formatDate(item.updatedAt),
                        overflowsToWorkdaysIfWeekend: item.overflowsToWorkdaysIfWeekend
                          ? t('yes')
                          : t('no'),
                        recurring: item.recurring ? t('yes') : t('no')
                      })
                    )}
                    columns={getHolidayColumns()}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    pageCount={data?.data ? data.data.totalPages : 0}
                    setPagination={setPagination}
                    handleEdit={(row) => handleEdit(row)}
                    handleDelete={(row) => handleDelete(row)}
                    defaultHiddenColumns={{
                      recurring: false
                    }}
                    handleSearch={(value) => handleSearch(value)}
                    searchValue={search}
                    handleCreateData={
                      permissions.includes(PERMISSION_TYPE.CREATEPUBLICHOLIDAY) &&
                      permissions.includes(PERMISSION_TYPE.EDITPUBLICHOLIDAY)
                        ? () => setShowCreateEditModal(true)
                        : undefined
                    }
                    localStorageKey='publicHolidays'
                    dragAndDropLocalStorageKey='dndPublicHolidays'
                    sorting={sorting}
                    setSorting={setSorting}
                    permissions={permissionsPublicHoliday}
                    isPublicHoliday={true}
                    filter={
                      <PublicHolidaysFilter
                        yearValue={filterYearValue}
                        handleChangeYearValue={handleChangeFilterYear}
                      />
                    }
                  />
                ) : (
                  <CustomCalendar
                    view={view}
                    handleView={handleViewChange}
                    handleCreate={handleCreatePublicHoliday}
                    events={publicHolidays}
                    eventsStyles={() => formatEventsStyles(view)}
                    year={year}
                    publicHolidaysYearValue={String(filterYearValue?.value)}
                    setYear={setYear}
                    handleSearch={(value) => handleSearch(value)}
                    searchValue={search}
                    filter={
                      <PublicHolidaysFilter
                        yearValue={filterYearValue}
                        handleChangeYearValue={handleChangeFilterYear}
                      />
                    }
                    handleCreateData={
                      permissions.includes(PERMISSION_TYPE.CREATEPUBLICHOLIDAY) &&
                      permissions.includes(PERMISSION_TYPE.EDITPUBLICHOLIDAY)
                        ? () => setShowCreateEditModal(true)
                        : undefined
                    }
                  />
                )}
              </>
            )}
          </>
        </SadStates>
      </VStack>
      <Restricted to={[PERMISSION_TYPE.CREATEPUBLICHOLIDAY, PERMISSION_TYPE.EDITPUBLICHOLIDAY]}>
        <CreateEditPublicHoliday
          isOpen={showCreateEditModal}
          onClose={handleCloseCreateEdit}
          holidayId={holidayId}
          defaultStartDate={startDate}
        />
      </Restricted>
      <Restricted to={[PERMISSION_TYPE.DELETEPUBLICHOLIDAY]}>
        <CustomConfirmationModal
          isOpen={showDeleteModal}
          onClose={handleCloseDelete}
          handleClick={() => deleteHoliday(Number(holidayId))}
          confirmButtonLabel={t('yesDelete')}
          message={`${t('confirmPublicHolidayDeletion')} ${holidayName}?`}
          modalTitle={t('deletePublicHoliday')}
          isSubmitting={isLoadingDeleteHoliday}
        />
      </Restricted>
    </>
  );
};
