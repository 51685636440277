import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiValue, SingleValue } from 'react-select';

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  HamburgerIcon
} from '@chakra-ui/icons';
import {
  Box,
  Button,
  CheckboxGroup,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text as ChakraText,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react';
import {
  ColumnDef,
  ColumnOrderState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  OnChangeFn,
  PaginationState,
  SortingState,
  useReactTable,
  VisibilityState
} from '@tanstack/react-table';

import {
  CustomInputCheckbox,
  CustomSelect,
  HeaderOptions,
  Restricted,
  TableContextMenu
} from '@/components';
import { themeConfiguration } from '@/resources';
import { SelectOption, TableData } from '@/types';

import { ColumnHeader } from './ColumnHeader';

type GenericTableProps = {
  data: TableData[];
  columns: ColumnDef<TableData>[];
  pageIndex: number;
  pageSize: number;
  pageCount: number;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  defaultHiddenColumns: VisibilityState;
  handleEdit?: (row: TableData) => void;
  handleDelete?: (row: TableData) => void;
  handleView?: (id: number) => void;
  handleApprove?: (row: TableData) => void;
  handleReject?: (row: TableData) => void;
  handleSearch?: (value: string) => void;
  handleCreateData?: () => void;
  handleDeleteAbsence?: (row: TableData) => void;
  handleEditByUser?: (row: TableData) => void;
  filter?: React.ReactNode;
  localStorageKey: string;
  dragAndDropLocalStorageKey: string;
  sorting?: SortingState;
  searchValue?: string;
  setSorting?: OnChangeFn<SortingState>;
  permissions: number[];
  isPublicHoliday?: boolean;
};

export const GenericTable = ({
  data,
  columns,
  pageIndex,
  pageSize,
  pageCount,
  setPagination,
  defaultHiddenColumns,
  handleEdit,
  handleDelete,
  handleView,
  handleApprove,
  handleReject,
  handleSearch,
  handleCreateData,
  handleDeleteAbsence,
  handleEditByUser,
  filter,
  localStorageKey,
  dragAndDropLocalStorageKey,
  sorting,
  searchValue,
  setSorting,
  permissions,
  isPublicHoliday
}: GenericTableProps) => {
  const [t] = useTranslation('common');

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    JSON.parse(localStorage.getItem(`${dragAndDropLocalStorageKey}`) as string) ||
      columns.map((column) => column.id as string)
  );

  const defaultColumnVisibility = JSON.parse(
    localStorage.getItem(String(localStorageKey)) as string
  );

  const [columnVisibility, setColumnVisibility] = useState(
    defaultColumnVisibility || defaultHiddenColumns
  );

  const [initialColumnVisibility] = useState(defaultColumnVisibility);

  const selectOptionTable: SelectOption[] = [
    {
      label: t('show 10'),
      value: 10
    },
    {
      label: t('show 15'),
      value: 15
    },
    {
      label: t('show 20'),
      value: 20
    }
  ];

  const [selectOptionTables, setSelectOptionTables] = useState<SingleValue<SelectOption>>(
    selectOptionTable[0]
  );

  useEffect(() => {
    switch (selectOptionTables?.value) {
      case 20:
        return setSelectOptionTables(selectOptionTable[2]);
      case 15:
        return setSelectOptionTables(selectOptionTable[1]);
      default:
        return setSelectOptionTables(selectOptionTable[0]);
    }
  }, [t]);

  const defaultData = React.useMemo(() => [], []);
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: data ?? defaultData,
    columns,
    pageCount: pageCount,
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      columnVisibility,
      sorting,
      pagination,
      columnOrder
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    onColumnOrderChange: setColumnOrder,
    onColumnVisibilityChange: setColumnVisibility,
    manualPagination: true,
    manualSorting: true,
    enableSortingRemoval: false,
    debugTable: true
  });

  const [numberInputValue, setNumberInputValue] = useState(
    table.getState().pagination.pageIndex + 1
  );

  const handleChange = (value: string) => {
    setNumberInputValue(Number(value));
    if (Number(value) > table.getPageCount()) {
      setNumberInputValue(Number(table.getPageCount()));
    } else if (isNaN(+value) || Number(value) === 0) {
      setNumberInputValue(1);
    }

    const page = Number(value) ? Number(value) - 1 : 0;
    table.setPageIndex(page);
  };

  const handleChangeButton = (value: number) => {
    setNumberInputValue(value);
  };

  const handleColumnVisibility = (value: boolean) => {
    if (value) {
      table.toggleAllColumnsVisible();
    } else {
      setColumnVisibility(defaultColumnVisibility);
    }
  };

  useEffect(() => {
    localStorage.setItem(String(localStorageKey), JSON.stringify(columnVisibility));
    if (table.getAllLeafColumns().every((column) => column.getIsVisible() === false)) {
      setColumnVisibility(initialColumnVisibility);
    }
  }, [columnVisibility]);

  const handleClickFirstPage = () => {
    table.setPageIndex(0);
    handleChangeButton(1);
  };

  const handleClickPreviousPage = () => {
    table.previousPage();
    handleChangeButton(numberInputValue - 1);
  };

  const handleClickNextPage = () => {
    table.nextPage();
    handleChangeButton(numberInputValue + 1);
  };

  const handleClickLastPage = () => {
    table.setPageIndex(table.getPageCount() - 1);
    handleChangeButton(table.getPageCount());
  };

  const handleChangeSelect = (value: SingleValue<SelectOption> | MultiValue<SelectOption>) => {
    setSelectOptionTables(value as SingleValue<SelectOption>);
    table.setPageSize(Number((value as SingleValue<SelectOption>)?.value));
  };

  return (
    <Box>
      <Flex py='1%' w='full' gap='5'>
        <HeaderOptions
          handleSearch={handleSearch}
          searchValue={searchValue}
          filter={filter}
          handleCreateData={handleCreateData}
          isPublicHoliday={isPublicHoliday}
        />
        <Popover placement='bottom-end'>
          <PopoverTrigger>
            <Button title={t('columnVisibility')} aria-label={t('columnVisibility')}>
              <HamburgerIcon viewBox='0 0 20 24' boxSize={6} />
            </Button>
          </PopoverTrigger>
          <PopoverContent
            backgroundColor={`${themeConfiguration.styles.popover}`}
            overflowY='auto'
            maxHeight='55vh'>
            <PopoverHeader>{t('columnVisibility')}</PopoverHeader>
            <PopoverBody>
              <CheckboxGroup>
                <Stack spacing={4} justify='start'>
                  <CustomInputCheckbox
                    id='columnVisibility'
                    isChecked={table.getIsAllColumnsVisible()}
                    onChange={(e) => handleColumnVisibility(e.target.checked)}
                    label={t('showAll')}
                  />
                  {table.getAllLeafColumns().map((column, index) => {
                    return (
                      <CustomInputCheckbox
                        id={index.toString()}
                        key={column.id}
                        isChecked={column.getIsVisible()}
                        onChange={column.getToggleVisibilityHandler()}
                        label={column.columnDef.header?.toString()}
                      />
                    );
                  })}
                </Stack>
              </CheckboxGroup>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
      <Box boxShadow='lg'>
        <TableContainer
          overflowY='auto'
          h='100%'
          borderTopRadius='lg'
          border='1px solid var(--chakra-colors-gray-400)'
          borderBottomWidth={0}>
          <Table colorScheme='gray' variant='striped' h='100%'>
            <Thead position='sticky' top={0}>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <ColumnHeader
                      key={header.id}
                      table={table}
                      header={header}
                      dragAndDropLocalStorageKey={dragAndDropLocalStorageKey}
                    />
                  ))}
                  <Th bg='brand.600' color='white' />
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td
                      key={cell.id}
                      bg={`${themeConfiguration.styles.td}`}
                      whiteSpace='break-spaces'
                      paddingInlineStart='3.25rem'>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  ))}
                  <Td p='0' key='actions' bg={`${themeConfiguration.styles.td}`}>
                    <Restricted to={permissions}>
                      {handleEdit ? (
                        row.original.approvalStatus === t('PENDING') ? (
                          <TableContextMenu
                            handleEdit={handleEdit && (() => handleEdit(row.original))}
                            handleDelete={handleDelete && (() => handleDelete(row.original))}
                            handleApprove={handleApprove && (() => handleApprove(row.original))}
                            handleReject={handleReject && (() => handleReject(row.original))}
                          />
                        ) : (
                          <TableContextMenu
                            handleEdit={handleEdit && (() => handleEdit(row.original))}
                            handleView={handleView && (() => handleView(row.original.id))}
                            handleDelete={handleDelete && (() => handleDelete(row.original))}
                          />
                        )
                      ) : (
                        row.original.approvalStatus === t('PENDING') && (
                          <TableContextMenu
                            handleEditByUser={
                              handleEditByUser && (() => handleEditByUser(row.original))
                            }
                            handleDeleteAbsence={
                              handleDeleteAbsence && (() => handleDeleteAbsence(row.original))
                            }
                          />
                        )
                      )}
                    </Restricted>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <Flex
        borderBottomRadius='lg'
        border='1px solid var(--chakra-colors-gray-400)'
        borderTopWidth={0}
        backgroundColor={
          localStorage.getItem('chakra-ui-color-mode') === 'dark' ? 'gray.700' : 'white'
        }
        px='5%'
        py='1%'
        display='flex'
        alignItems='center'
        justifyContent='center'
        w='full'
        gap='10'>
        <Flex>
          <Button onClick={handleClickFirstPage} disabled={!table.getCanPreviousPage()} mr={4}>
            <ArrowLeftIcon w={8} />
          </Button>
          <Button onClick={handleClickPreviousPage} disabled={!table.getCanPreviousPage()} mr={4}>
            <ChevronLeftIcon boxSize={8} />
          </Button>
          <Spacer />
          <Flex alignItems='center' flexShrink='0'>
            <ChakraText flexShrink='0'>
              {`${t('page')} `}
              <ChakraText fontWeight='bold' as='span'>
                {table.getState().pagination.pageIndex + 1} {t('of')}{' '}
                {table.getPageCount() === 0 ? 1 : table.getPageCount()}
              </ChakraText>
            </ChakraText>
          </Flex>
          <Spacer />
          <Button onClick={handleClickNextPage} disabled={!table.getCanNextPage()} mx={4}>
            <ChevronRightIcon boxSize={8} />
          </Button>
          <Button onClick={handleClickLastPage} disabled={!table.getCanNextPage()}>
            <ArrowRightIcon w={8} />
          </Button>
        </Flex>
        <Flex alignItems='center'>
          <ChakraText flexShrink='0' mr={4}>
            {t('goToPage')}
          </ChakraText>
          <NumberInput
            id='goToPage'
            border={`0.1px solid ${themeConfiguration.colors.brand[400]}`}
            borderRadius='md'
            minWidth={20}
            min={1}
            max={table.getPageCount() === 0 ? 1 : table.getPageCount()}
            value={numberInputValue}
            onChange={(value) => handleChange(value)}>
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Flex>
        <Flex minWidth={40} w={60}>
          <CustomSelect
            id='showData'
            value={selectOptionTables}
            options={selectOptionTable}
            handleChange={(value) => handleChangeSelect(value)}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
