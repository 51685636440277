import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';

import { Spinner, Textarea } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import {
  CustomDateInput,
  CustomInput,
  CustomModal,
  CustomSelect,
  ModalErrorState,
  ModalLoadingState
} from '@/components';
import { UserContext } from '@/contexts';
import { ABSENCE_APPROVAL_STATUS, ABSENCE_TYPE, PERMISSION_TYPE } from '@/enums';
import { SadStates, handleError, useErrorToast, useSuccessToast } from '@/helpers';
import {
  useCreateAbsenceByAdminMutation,
  useCreateAbsenceMutation,
  useGetAbsenceByIdQuery,
  useGetAllAdminsQuery,
  useGetAllUsersAdminSelectQuery,
  useUpdateAbsenceByAdminMutation,
  useUpdateAbsenceByUsersMutation
} from '@/services';
import { AbsenceProps, SelectOption, User } from '@/types';

type CreateEditAbsenceProps = {
  selectedAbsenceId: number;
  isOpen: boolean;
  onClose: () => void;
  isMyAbsence: boolean;
  defaultStartDate?: Date;
};

export const CreateEditAbsence = ({
  selectedAbsenceId,
  isOpen,
  onClose,
  isMyAbsence,
  defaultStartDate
}: CreateEditAbsenceProps) => {
  const [t] = useTranslation('common');
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();
  const queryClient = useQueryClient();
  const currentDate = format(new Date(), 'yyyy-MM-dd');
  const { permissions, currentUser } = useContext(UserContext);
  const isAbsenceEdit = !!selectedAbsenceId;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const maxDate = format(new Date().setFullYear(new Date().getFullYear() + 1), 'yyyy-MM-dd');
  const [isSelectOpenScroll, setIsSelectOpenScroll] = useState(false);

  const approvalStatusObjects = Object.keys(ABSENCE_APPROVAL_STATUS)
    .filter((v) => isNaN(Number(v)))
    .map((label) => {
      return {
        value: ABSENCE_APPROVAL_STATUS[label as keyof typeof ABSENCE_APPROVAL_STATUS],
        label: t(`${label}`) || label
      };
    });

  const absenceTypeObjects = Object.keys(ABSENCE_TYPE)
    .filter((v) => isNaN(Number(v)))
    .map((label) => {
      return {
        value: ABSENCE_TYPE[label as keyof typeof ABSENCE_TYPE],
        label: t(`${label}`) || label
      };
    });

  const {
    data: selectedAbsence,
    isLoading: isLoadingAbsence,
    isSuccess,
    isRefetching
  } = useGetAbsenceByIdQuery(selectedAbsenceId, {
    onSuccess: () => void 0,
    onError: (error) => {
      errorToast(handleError(error));
    }
  });

  const { data: admins, isLoading: isLoadingAdmins } = useGetAllAdminsQuery();

  const { data: allUsersData, isLoading: isLoadingUsers } = useGetAllUsersAdminSelectQuery(0, 1, {
    enabled: permissions.includes(PERMISSION_TYPE.VIEWUSERS),
    onSuccess: () => void 0,
    onError: (error) => {
      errorToast(handleError(error));
    }
  });

  const absenceRequestSchema = Yup.object().shape({
    startsAt: Yup.date()
      .required(t('startsAtRequired'))
      .nullable()
      .transform((v) => (v instanceof Date ? v : null)),
    endsAt: Yup.date()
      .required(t('endsAtRequired'))
      .nullable()
      .transform((v) => (v instanceof Date ? v : null))
      .min(Yup.ref('startsAt'), t('endsAtDateBeforeStartsAtError')),
    requestMessage: Yup.string(),
    responseMessage: Yup.string(),
    type: Yup.number().nullable().required(t('typeRequired')),
    ...(permissions.includes(PERMISSION_TYPE.EDITUSERBYID) &&
      isAbsenceEdit &&
      !isMyAbsence && {
        reviewerId: Yup.string().nullable().required(t('reviewerIdRequired'))
      }),
    ...(permissions.includes(PERMISSION_TYPE.CREATEUSER) &&
      !isAbsenceEdit &&
      !isMyAbsence && {
        userId: Yup.string().nullable().required(t('userIdRequired'))
      })
  });

  const { mutate: createAbsence } = useCreateAbsenceMutation(queryClient, {
    onSuccess: () => {
      onClose();
      successToast({ title: t('successfulAbsenceCreation') });
      setIsSubmitting(false);
    },
    onError: (error) => {
      errorToast(handleError(error));
      setIsSubmitting(false);
    }
  });

  const { mutate: createAbsenceByAdmin } = useCreateAbsenceByAdminMutation(queryClient, {
    onSuccess: () => {
      onClose();
      successToast({ title: t('successfulAbsenceCreation') });
      setIsSubmitting(false);
    },
    onError: (error) => {
      errorToast(handleError(error));
      setIsSubmitting(false);
    }
  });

  const { mutate: editAbsenceByUsers } = useUpdateAbsenceByUsersMutation(queryClient, {
    onSuccess: () => {
      onClose();
      successToast({ title: t('successfulAbsenceUpdate') });
      setIsSubmitting(false);
    },
    onError: (error) => {
      errorToast(handleError(error));
      setIsSubmitting(false);
    }
  });

  const { mutate: editAbsenceByAdmin } = useUpdateAbsenceByAdminMutation(queryClient, {
    onSuccess: () => {
      onClose();
      successToast({ title: t('successfulAbsenceUpdate') });
      setIsSubmitting(false);
    },
    onError: (error) => {
      errorToast(handleError(error));
      setIsSubmitting(false);
    }
  });

  const getInitialValues = (): AbsenceProps => {
    return {
      id: selectedAbsence?.data?.id || 0,
      type: selectedAbsence?.data?.type || null,
      startsAt: new Date(
        selectedAbsence?.data?.startsAt?.toString().split('/').reverse().join('-') ||
          moment(defaultStartDate).format('YYYY-MM-DDTHH:mm:ss[Z]')
      ),
      endsAt: new Date(
        selectedAbsence?.data?.endsAt?.toString().split('/').reverse().join('-') ||
          moment(defaultStartDate).add(1, 'seconds').format('YYYY-MM-DDTHH:mm:ss[Z]')
      ),
      requestMessage: selectedAbsence?.data?.requestMessage || '',
      responseMessage: selectedAbsence?.data?.responseMessage || '',
      approvalStatus: selectedAbsence?.data?.approvalStatus || ABSENCE_APPROVAL_STATUS.PENDING,
      reviewerId: selectedAbsence?.data?.reviewerId || null,
      userId: selectedAbsence?.data?.user?.id || ''
    };
  };

  const handleSubmit = (values: AbsenceProps) => {
    setIsSubmitting(true);
    const editByAdminPayload: AbsenceProps = {
      type: values.type,
      startsAt: values.startsAt,
      endsAt: values.endsAt,
      requestMessage: values.requestMessage,
      responseMessage: values.responseMessage,
      approvalStatus: values.approvalStatus,
      reviewerId: values.reviewerId
    };

    const createEditPayload: AbsenceProps = {
      type: values.type,
      startsAt: values.startsAt,
      endsAt: values.endsAt,
      requestMessage: values.requestMessage
    };

    const createByAdminPayload: AbsenceProps = {
      userId: values.userId,
      type: values.type,
      startsAt: values.startsAt,
      endsAt: values.endsAt,
      requestMessage: values.requestMessage
    };

    if (permissions.includes(PERMISSION_TYPE.EDITUSERBYID)) {
      if (isAbsenceEdit) {
        if (currentUser?.id === values.userId) {
          editAbsenceByUsers({ payload: createEditPayload, absenceId: selectedAbsenceId });
        } else {
          editAbsenceByAdmin({ payload: editByAdminPayload, absenceId: selectedAbsenceId });
        }
      } else {
        createAbsenceByAdmin(createByAdminPayload);
      }
    } else {
      if (isAbsenceEdit) {
        editAbsenceByUsers({ payload: createEditPayload, absenceId: selectedAbsenceId });
      } else {
        createAbsence(createEditPayload);
      }
    }
  };

  const handleMenuOpen = () => {
    setIsSelectOpenScroll(true);
  };

  const handleMenuClose = () => {
    setIsSelectOpenScroll(false);
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      title={isAbsenceEdit ? t('editAbsence') : t('createAbsence')}
      formId='createEditAbsence'
      isSubmitting={isSubmitting}
      submitButtonLabel={isAbsenceEdit ? t('update') : t('send')}>
      <SadStates
        states={[
          {
            when: (isAbsenceEdit && isLoadingAbsence) || isRefetching,
            render: () => <ModalLoadingState />
          },
          {
            when: isAbsenceEdit && !isSuccess,
            render: () => <ModalErrorState />
          }
        ]}>
        <Formik
          enableReinitialize={!!isAbsenceEdit}
          validateOnBlur={false}
          initialValues={getInitialValues()}
          validationSchema={absenceRequestSchema}
          onSubmit={handleSubmit}>
          {({ errors, setFieldValue, values, touched, setFieldTouched }) => (
            <Form
              id='createEditAbsence'
              style={{ width: '100%', height: '29rem', overflow: 'auto' }}>
              {!isAbsenceEdit && permissions.includes(PERMISSION_TYPE.CREATEUSER) && !isMyAbsence && (
                <CustomSelect
                  label={t('user')}
                  isDisabled={false}
                  id='userId'
                  handleChange={(value) =>
                    setFieldValue('userId', (value as SingleValue<SelectOption>)?.value)
                  }
                  options={
                    !isLoadingUsers && allUsersData?.data ? (
                      allUsersData?.data?.items.map((users: User) => ({
                        value: users?.id,
                        label: `${users?.firstName} ${users?.lastName}`
                      }))
                    ) : (
                      <Spinner />
                    )
                  }
                  isInvalid={!!errors.userId && touched.userId}
                  error={errors.userId}
                  onBlur={() => setFieldTouched('userId', true)}
                />
              )}
              <CustomSelect
                label={t('absenceType')}
                id='type'
                defaultValue={
                  absenceTypeObjects.filter((item) => item.value === selectedAbsence?.data?.type) ||
                  null
                }
                handleChange={(value) =>
                  setFieldValue('type', (value as SingleValue<SelectOption>)?.value)
                }
                options={absenceTypeObjects}
                isInvalid={!!errors.type && touched.type}
                error={errors.type}
                onBlur={() => setFieldTouched('type', true)}
              />
              <CustomDateInput
                isInvalid={!!errors.startsAt && touched.startsAt}
                id='startsAt'
                label={t('startDate')}
                error={errors.startsAt}
                min={
                  permissions.includes(PERMISSION_TYPE.EDITABSENCEBYADMIN) && !isMyAbsence
                    ? undefined
                    : new Date(currentDate)
                }
                filterDate={(date) => {
                  return date.getDay() !== 0 && date.getDay() !== 6;
                }}
                onChange={(date: Date | null) => setFieldValue('startsAt', date)}
              />
              <CustomDateInput
                isInvalid={!!errors.endsAt && touched.endsAt}
                id='endsAt'
                label={t('endsAt')}
                error={errors.endsAt}
                min={
                  (values.startsAt as Date) &&
                  permissions.includes(PERMISSION_TYPE.EDITABSENCEBYADMIN) &&
                  !isMyAbsence
                    ? undefined
                    : new Date(currentDate)
                }
                max={
                  values.type === ABSENCE_TYPE.BLOODDONATIONLEAVE
                    ? new Date(
                        new Date(values.startsAt).setDate(new Date(values.startsAt).getDate() + 1)
                      )
                    : new Date(maxDate)
                }
                filterDate={(date) => {
                  return date.getDay() !== 0 && date.getDay() !== 6;
                }}
                onChange={(date: Date | null) => setFieldValue('endsAt', date)}
              />
              <CustomInput
                type='text'
                as={Textarea}
                h={140}
                id='requestMessage'
                label={t('requestMessage')}
                placeholder={t('requestMessagePlaceholder')}
                focusBorderColor='brand.500'
              />
              {isAbsenceEdit &&
                permissions.includes(PERMISSION_TYPE.EDITUSERBYID) &&
                values.userId !== currentUser?.id && (
                  <>
                    <CustomInput
                      type='text'
                      as={Textarea}
                      h={140}
                      id='responseMessage'
                      label={t('responseMessage')}
                      placeholder={t('responseMessagePlaceholder')}
                      focusBorderColor='brand.500'
                    />
                    <CustomSelect
                      id='approvalStatus'
                      defaultValue={approvalStatusObjects.filter(
                        (item) => item.value === selectedAbsence?.data?.approvalStatus
                      )}
                      label={t('approvalStatus')}
                      handleChange={(value) =>
                        setFieldValue('approvalStatus', (value as SingleValue<SelectOption>)?.value)
                      }
                      options={approvalStatusObjects}
                      handleMenuOpen={handleMenuOpen}
                      handleMenuClose={handleMenuClose}
                      isSelectOpenScroll={isSelectOpenScroll}
                    />
                    <CustomSelect
                      id='reviewerId'
                      label={t('reviewer')}
                      defaultValue={
                        admins?.data
                          ?.map((admin: User) => ({
                            value: admin?.id,
                            label: `${admin?.firstName} ${admin?.lastName}`
                          }))
                          .filter(
                            (item: SelectOption) => item.value === selectedAbsence?.data?.reviewerId
                          ) || []
                      }
                      handleChange={(value) =>
                        setFieldValue('reviewerId', (value as SingleValue<SelectOption>)?.value)
                      }
                      options={
                        !isLoadingAdmins && admins?.data ? (
                          admins?.data?.map((admin: User) => ({
                            value: admin?.id,
                            label: `${admin?.firstName} ${admin?.lastName}`
                          }))
                        ) : (
                          <Spinner />
                        )
                      }
                      handleMenuOpen={handleMenuOpen}
                      handleMenuClose={handleMenuClose}
                      isInvalid={!!errors.reviewerId && touched.reviewerId}
                      error={errors.reviewerId}
                      onBlur={() => setFieldTouched('reviewerId', true)}
                      isSelectOpenScroll={isSelectOpenScroll}
                    />
                  </>
                )}
            </Form>
          )}
        </Formik>
      </SadStates>
    </CustomModal>
  );
};
