import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Drawer, DrawerContent, useDisclosure } from '@chakra-ui/react';

import { MobileNav, Sidebar } from '@/components';
import { getPageTitleHelper } from '@/helpers';

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const routerLocation = useLocation();
  const currentPath = routerLocation.pathname;
  const formatedPageTitle = currentPath.split('/').join('');
  const [title, setTitle] = useState<string>('absences');

  useEffect(() => {
    setTitle(getPageTitleHelper(formatedPageTitle));
  }, [routerLocation]);

  return (
    <Box minH='100vh'>
      <Sidebar
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
        setTitle={setTitle}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size='full'>
        <DrawerContent>
          <Sidebar onClose={onClose} setTitle={setTitle} />
        </DrawerContent>
      </Drawer>
      <MobileNav onOpen={onOpen} title={title} setTitle={setTitle} />
      <Box ml={{ base: 0, md: 60 }} style={{ paddingTop: '4rem' }}>
        {children}
      </Box>
    </Box>
  );
};
