import { errorHandle } from '@/helpers';

export const handleError = (error: any) => {

  if (error?.response?.data?.Errors) {
    for (const [key] of Object.entries(error.response.data.Errors)) {
      return { title: errorHandle(Number(key)) };
    }
  } else {
    return { title: error?.message };
  }
};
