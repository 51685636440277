import axios from 'axios';
import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';

import { apiUrl } from '@/helpers';
import { Options, HolidayProps } from '@/types';

const PUBLIC_HOLIDAY_API_URL = '/v1/PublicHoliday';
const PUBLIC_HOLIDAY_QUERY_KEY = 'publicHoliday';
const GET_PUBLIC_HOLIDAY_BY_ID_QUERY_KEY = 'getPublicHoliday';

export const publicHolidayApi = {
  list: (pageSize: number, currentPage: number, orderBy?: string, orderByDesc?: boolean, search?: string, year?: string) =>
    axios.get(
      `${apiUrl()}${PUBLIC_HOLIDAY_API_URL}?pageSize=${pageSize}&currentPage=${currentPage}&orderBy=${orderBy}&orderByDesc=${orderByDesc}&search=${search}&year=${year}`
    ),
  create: (payload: HolidayProps) => axios.post(`${apiUrl()}${PUBLIC_HOLIDAY_API_URL}`, payload),
  update: (payload: HolidayProps ) =>
    axios.put(`${apiUrl()}${PUBLIC_HOLIDAY_API_URL}/${payload.id}`, payload),
  delete: (id: number) => axios.delete(`${apiUrl()}${PUBLIC_HOLIDAY_API_URL}/${id}`),
  getPublicHoliday: (id: number) => axios.get(`${apiUrl()}${PUBLIC_HOLIDAY_API_URL}/${id}`)
};

export const useGetAllPublicHolidaysQuery = (
  pageSize: number,
  currentPage: number,
  orderBy?: string,
  orderByDesc?: boolean,
  options?: Options,
  search?: string,
  year?: string
) => {
  return useQuery(
    [PUBLIC_HOLIDAY_QUERY_KEY, { pageSize, currentPage, orderBy, orderByDesc, search, year }],
    () => publicHolidayApi.list(pageSize, currentPage, orderBy, orderByDesc, search, year),
    {
      keepPreviousData: true,
      onSuccess: (response) => {
        options?.onSuccess(response);
      },
      onError: (error) => {
        options?.onError(error);
      }
    }
  );
};

export const useGetPublicHoliday = (id: number) => {
  return useQuery([GET_PUBLIC_HOLIDAY_BY_ID_QUERY_KEY, { id }], () => publicHolidayApi.getPublicHoliday(id), { enabled: !!id });
};

export const useCreatePublicHolidayMutation = (queryClient: QueryClient, options: Options) => {
  return useMutation(publicHolidayApi.create, {
    onSuccess: () => {
      options.onSuccess();
      queryClient.invalidateQueries([PUBLIC_HOLIDAY_QUERY_KEY]);
    },
    onError: (error) => {
      options.onError(error);
    }
  });
};

export const useUpdatePublicHolidayMutation = (queryClient: QueryClient, options: Options) => {
  return useMutation(publicHolidayApi.update, {
    onSuccess: (response) => {
      options.onSuccess(response);
      queryClient.invalidateQueries([PUBLIC_HOLIDAY_QUERY_KEY]);
    },
    onError: (error) => {
      options.onError(error);
    }
  });
};

export const useDeletePublicHolidayMutation = (queryClient: QueryClient, options: Options) => {
  return useMutation(publicHolidayApi.delete, {
    onSuccess: (response) => {
      options.onSuccess(response);
      queryClient.invalidateQueries([PUBLIC_HOLIDAY_QUERY_KEY]);
    },
    onError: (error) => {
      options.onError(error);
    }
  });
};
