import { useContext, useState } from 'react';

import { PermissionContext } from '@/contexts';

export const usePermission = (permissions: number[]) => {
  const [loading, setLoading] = useState(true);
  const [allowed, setAllowed] = useState<boolean>();

  const { isAllowedTo } = useContext(PermissionContext);

  isAllowedTo(permissions).then((allowedPermissions) => {
    setLoading(false);
    setAllowed(allowedPermissions);
  });

  return [loading, allowed];
};
