import { Outlet } from 'react-router-dom';

import { Restricted } from '@/components';
import { Unauthorized } from './Unauthorized';

export const ProtectedRoute = ({ allowedPermissions }: { allowedPermissions: number[] }) => {

  return <Restricted to={allowedPermissions} fallback={<Unauthorized />} loadingComponent={undefined}><Outlet /></Restricted>;

};
