import i18n from 'i18next';

export const errorHandle = (code: number): string => {
  switch (code) {
    case 1:
      return i18n.t('internalServerError', { ns: 'common' });
    case 2:
      return i18n.t('entityNotFoundError', { ns: 'common' });
    case 3:
      return i18n.t('accessViolationError', { ns: 'common' });
    case 7:
      return i18n.t('claimNullException', { ns: 'common' });
    case 8:
      return i18n.t('invalidTokenException', { ns: 'common' });
    case 9:
      return i18n.t('claimUpdateError', { ns: 'common' });
    case 10:
      return i18n.t('firebasePasswordUpdateError', { ns: 'common' });
    case 11:
      return i18n.t('firebaseUserCreateError', { ns: 'common' });
    case 61:
      return i18n.t('firstNameInvalidError', { ns: 'common' });
    case 62:
      return i18n.t('lastNameInvalidError', { ns: 'common' });
    case 63:
      return i18n.t('emailAddressInvalidError', { ns: 'common' });
    case 64:
      return i18n.t('religiousHolidaysExceededError', { ns: 'common' });
    case 65:
      return i18n.t('currentUserNotFoundError', { ns: 'common' });
    case 66:
      return i18n.t('emailAddressExistsError', { ns: 'common' });
    case 67:
      return i18n.t('emailAddressNotFoundError', { ns: 'common' });
    case 68:
      return i18n.t('annualLeaveDaysError', { ns: 'common' });
    case 69:
      return i18n.t('usedAnnualLeaveDaysError', { ns: 'common' });
    case 70:
      return i18n.t('employmentStartedAtDateInvalidError', { ns: 'common' });
    case 71:
      return i18n.t('referenceNumberError', { ns: 'common' });
    case 80:
      return i18n.t('holidayNameInvalidError', { ns: 'common' });
    case 81:
      return i18n.t('startsAtDateInvalidError', { ns: 'common' });
    case 82:
      return i18n.t('totalDaysCelebratedValueInvalidError', { ns: 'common' });
    case 83:
      return i18n.t('endsAtDateInvalidError', { ns: 'common' });
    case 84:
      return i18n.t('holidayNameExists', { ns: 'common' });
    case 100:
      return i18n.t('absenceTypeInvalidError', { ns: 'common' });
    case 101:
      return i18n.t('requestMessageInvalidError', { ns: 'common' });
    case 102:
      return i18n.t('notEnoughAnnualLeaveDays', { ns: 'common' });
    case 103:
      return i18n.t('notAllowedApproveRejectYourRequest', { ns: 'common' });
    case 104:
      return i18n.t('alreadyApprovedOrRejected', { ns: 'common' });
    case 105:
      return i18n.t('absenceApprovalStatusInvalidError', { ns: 'common' });
    case 106:
      return i18n.t('absenceStartDateInvalidError', { ns: 'common' });
    case 107:
      return i18n.t('absenceEndDateInvalidError', { ns: 'common' });
    case 108:
      return i18n.t('absenceBloodDonationLeaveError', { ns: 'common' });
    case 109:
      return i18n.t('absencesOverlapError', { ns: 'common' });
    case 110:
      return i18n.t('notEnoughSickLeaveDays', { ns: 'common' });
    default:
      return i18n.t(`${code}`, 'unknownError', { ns: 'common' });
  }
};

export const handleFirebaseErrors = (message: string) => {
  switch (message) {
    case 'Firebase: Error (auth/wrong-password).':
      return { title: i18n.t('incorrectPassword', { ns: 'common' }) };
    case 'Firebase: Error (auth/user-not-found).':
      return { title: i18n.t('incorrectEmail', { ns: 'common' }) };
    case 'Firebase: Error (auth/user-disabled).':
      return { title: i18n.t('userDisabled', { ns: 'common' }) };
    case 'Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).':
      return { title: i18n.t('temporarilyDisabled', { ns: 'common' }) };
    default:
      return { title: i18n.t('internalServerError', { ns: 'common' }) };
  }
};
