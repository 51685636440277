import { Dispatch, SetStateAction, useContext, useRef, useState } from 'react';
import Timeline, {
  CustomMarker,
  DateHeader,
  IntervalRenderer,
  SidebarHeader,
  TimelineGroupBase,
  TimelineHeaders,
  Unit
} from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import { useTranslation } from 'react-i18next';

import { Box, Text as ChakraText, Flex } from '@chakra-ui/react';
import moment, { Moment } from 'moment';
import { RRule } from 'rrule';
import { v4 as uuidv4 } from 'uuid';

import { CreateEditAbsence, HeaderOptions, HolidaysTooltip } from '@/components';
import { UserContext } from '@/contexts';
import { ABSENCE_APPROVAL_STATUS } from '@/enums';
import {
  changeAbsenceBackgroundColor,
  getStripePattern,
  handleError,
  initialAxiosResponse
} from '@/helpers';
import { useGetAllPublicHolidaysQuery } from '@/services';
import { AbsenceProps, HolidayProps, User } from '@/types';

type TimelineProps = {
  data: AbsenceProps[];
  setYear: Dispatch<SetStateAction<string>>;
  filter?: React.ReactNode;
  handleCreateData?: () => void;
  handleSearch?: (value: string) => void;
  searchValue?: string;
  dataUsers: User[];
  yearsForFiltering: string;
};

export const AllAbsencesTimeline = ({
  data,
  setYear,
  filter,
  handleCreateData,
  handleSearch,
  searchValue,
  dataUsers,
  yearsForFiltering
}: TimelineProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAbsenceId, setSelectedAbsenceId] = useState(0);
  const currentDate = new Date();
  const [t] = useTranslation('common');
  const view = useRef<string>('');
  const { currentUser } = useContext(UserContext);
  const [isMyAbsence, setIsMyAbsence] = useState<boolean>(false);

  const { data: publicHolidays = initialAxiosResponse } = useGetAllPublicHolidaysQuery(
    0,
    1,
    '',
    false,
    {
      onSuccess: () => void 0,
      onError: (error) => {
        handleError(error);
      }
    },
    '',
    yearsForFiltering
  );

  const formatUsers = (users: User[]) => {
    return users?.map(
      (item) =>
        ({
          title: `${item?.firstName} ${item?.lastName}`,
          id: item?.id
        } as TimelineGroupBase)
    );
  };

  const allUsersList = dataUsers && formatUsers(dataUsers);

  const getEventsStyles = (background: string, pendingStatus?: ABSENCE_APPROVAL_STATUS) => {
    return {
      style: {
        color: 'black',
        background:
          pendingStatus === ABSENCE_APPROVAL_STATUS.PENDING
            ? getStripePattern(background)
            : background,
        height: '100%',
        borderRadius: '10px',
        fontSize: '12px',
        zIndex: '1'
      }
    };
  };

  const formatedPublicHolidays = dataUsers?.flatMap((user: User) => {
    return publicHolidays.data?.items
      .map((holiday: HolidayProps) => {
        return {
          id: uuidv4(),
          start_time: moment(holiday.startsAt).startOf('day').toDate(),
          itemProps: getEventsStyles(changeAbsenceBackgroundColor('publicHolidays')),
          group: user?.id,
          title: <HolidaysTooltip label={holiday.name} />,
          end_time: moment(holiday.startsAt)
            .add(holiday.totalDaysCelebrated === 1 ? 0 : holiday.totalDaysCelebrated - 1, 'days')
            .endOf('day')
            .toDate()
        };
      })
      .flat();
  });

  const usersWithReligiousHolidays = dataUsers?.filter(
    (user: User) => user && user.religiousHolidays?.length > 0
  );

  const formatedReligiousHolidays =
    usersWithReligiousHolidays &&
    usersWithReligiousHolidays.flatMap(
      (user: User) =>
        user &&
        user.religiousHolidays.flatMap((holiday: HolidayProps) => {
          if (holiday.recurring) {
            const rruleObj = new RRule({
              freq: RRule.YEARLY,
              dtstart: moment(holiday.startsAt).add(-10, 'years').startOf('day').toDate(),
              until: moment(new Date()).add(10, 'years').toDate()
            });
            const recurringEvents = rruleObj.all();
            return recurringEvents.map((date) => ({
              start_time: moment(date).startOf('day'),
              end_time: moment(date)
                .add(
                  holiday.totalDaysCelebrated === 1 ? 0 : holiday.totalDaysCelebrated - 1,
                  'days'
                )
                .endOf('day'),
              id: uuidv4(),
              group: user.id,
              title: <HolidaysTooltip label={holiday.name} />,
              itemProps: getEventsStyles(changeAbsenceBackgroundColor('religiousHolidays'))
            }));
          } else {
            return {
              start_time: moment(new Date(holiday.startsAt)).startOf('day'),
              end_time: moment(new Date(holiday.startsAt))
                .add(
                  holiday.totalDaysCelebrated === 1 ? 0 : holiday.totalDaysCelebrated - 1,
                  'days'
                )
                .endOf('day'),
              id: uuidv4(),
              group: user.id,
              title: <HolidaysTooltip label={holiday.name} />,
              itemProps: getEventsStyles(changeAbsenceBackgroundColor('religiousHolidays'))
            };
          }
        })
    );

  const formatAbsences = (absence: AbsenceProps[]) => {
    return absence?.map((item) => ({
      start_time: moment(new Date(item.startsAt)).startOf('day'),
      end_time: moment(new Date(item.endsAt)).endOf('day'),
      id: item.id,
      approvalStatus: item?.approvalStatus,
      group: item?.user?.id,
      itemProps: getEventsStyles(changeAbsenceBackgroundColor(item?.type), item?.approvalStatus),
      isAbsence: true
    }));
  };

  const absences = data && formatAbsences(data);
  const events =
    (absences &&
      publicHolidays.data &&
      dataUsers && [...absences, ...formatedPublicHolidays, ...formatedReligiousHolidays]) ||
    [];

  const handleClose = () => {
    setModalOpen(false);
    setSelectedAbsenceId(0);
  };

  const handleClick = (absenceId: number) => {
    const dataEvents = data?.filter((e) => e.id === absenceId);
    if (dataEvents[0]) {
      setSelectedAbsenceId(absenceId);
      const userAbsence = data.find((x) => x.id === absenceId && x.user?.id === currentUser?.id);
      userAbsence ? setIsMyAbsence(true) : setIsMyAbsence(false);
      setModalOpen(
        !(
          moment(userAbsence?.startsAt).isBefore(currentDate) ||
          userAbsence?.approvalStatus === ABSENCE_APPROVAL_STATUS.REJECTED ||
          userAbsence?.approvalStatus === ABSENCE_APPROVAL_STATUS.APPROVED
        )
      );
    }
  };

  const formatDateLabel = ([startTime]: [Moment, Moment], unit: Unit) => {
    const date = moment(startTime).format('DD');
    const dayName = moment(startTime).format('ddd');
    view.current = unit;
    if (unit === 'day') {
      return `${date}, ${t(dayName).slice(0, 3)}`;
    } else {
      return t(moment(startTime).format('MMMM'));
    }
  };

  const formatMonthLabel = ([startTime]: [Moment, Moment], unit: Unit) => {
    const month = moment(startTime).format('MMMM');
    const year = moment(startTime).format('YYYY');
    if (unit === 'month') {
      return `${t(month)}, ${year}`;
    } else {
      return moment(startTime).format('YYYY');
    }
  };

  const intervalRendererFunction = (interval: IntervalRenderer<Event> | undefined) => {
    if (!interval) {
      return null;
    }

    const { intervalContext, getIntervalProps } = interval;
    const dayName = moment(intervalContext.interval.startTime).format('dddd');

    const backgroundColor =
      view.current === 'day' && (dayName === 'Sunday' || dayName === 'Saturday')
        ? localStorage.getItem('chakra-ui-color-mode') === 'dark'
          ? 'gray.600'
          : 'brand.150'
        : 'brand.600';

    return (
      <Box
        backgroundColor={backgroundColor}
        {...getIntervalProps()}
        display='flex'
        alignItems='center'
        justifyContent='center'
        h='100%'
        cursor='pointer'>
        <ChakraText as='span' fontSize={intervalContext.interval.labelWidth < 65 ? '10px' : '14px'}>
          {intervalContext.intervalText}
        </ChakraText>
      </Box>
    );
  };

  const updateTimelineCanvas = (canvasTimeStart: number, canvasTimeEnd: number) => {
    const startYear = Number(moment(canvasTimeStart).format('YYYY'));
    const endYear = Number(moment(canvasTimeEnd).format('YYYY'));
    const years = [startYear - 1, startYear, startYear + 1].toString();
    const yearsForYearView = [];
    for (let i = startYear; i <= endYear; i++) {
      yearsForYearView.push(i);
    }
    view.current === 'month' ? setYear(yearsForYearView.toString()) : setYear(years);
  };

  return (
    <Box>
      {events.length !== 0 && data && dataUsers && (
        <Box>
          <Flex py='1%' w='full' gap='5'>
            <HeaderOptions
              handleSearch={handleSearch}
              searchValue={searchValue}
              filter={filter}
              handleCreateData={handleCreateData}
            />
          </Flex>
          <Timeline
            groups={allUsersList}
            items={events}
            defaultTimeStart={moment(new Date()).add(-6, 'days')}
            defaultTimeEnd={moment(new Date()).add(14, 'days')}
            lineHeight={40}
            onBoundsChange={(canvasTimeStart: number, canvasTimeEnd: number) =>
              updateTimelineCanvas(canvasTimeStart, canvasTimeEnd)
            }
            canMove={false}
            timeSteps={{
              second: 0,
              minute: 0,
              hour: 0,
              day: 1,
              month: 1,
              year: 1
            }}
            onItemSelect={(e: number) => handleClick(e)}
            onItemClick={(e: number) => handleClick(e)}>
            <TimelineHeaders>
              <SidebarHeader>
                {({ getRootProps }) => {
                  return (
                    <Box
                      {...getRootProps()}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                        width: '150px',
                        padding: '0 4px'
                      }}>
                      <p style={{ color: 'white' }}>{t('allAbsences')}</p>
                    </Box>
                  );
                }}
              </SidebarHeader>
              <DateHeader
                unit='primaryHeader'
                className='month-name'
                labelFormat={(startDate, unit) => formatMonthLabel(startDate, unit)}
              />
              <DateHeader
                intervalRenderer={intervalRendererFunction}
                labelFormat={(startDate, unit) => formatDateLabel(startDate, unit)}
              />
            </TimelineHeaders>
            <CustomMarker date={Number(currentDate)}>
              {({ styles }) => {
                const customStyles = {
                  ...styles,
                  backgroundColor: '#35bebf',
                  width: '3px',
                  zIndex: 2,
                  overflow: 'hidden'
                };
                return <div style={customStyles} className='marker' />;
              }}
            </CustomMarker>
          </Timeline>
          <CreateEditAbsence
            isOpen={modalOpen}
            onClose={handleClose}
            selectedAbsenceId={selectedAbsenceId}
            isMyAbsence={isMyAbsence}
          />
        </Box>
      )}
    </Box>
  );
};
