import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import { ABSENCE_APPROVAL_STATUS } from '@/enums/AbsenceTypeEnum';
import { useErrorToast, useSuccessToast, handleError } from '@/helpers';
import { useUpdateAbsenceStatus } from '@/services';
import { PartialAbsenceUpdateProps } from '@/types';
import { CustomConfirmationModal } from '@/components';

export type ApproveRejectAbsenceProps = {
  isOpen: boolean;
  onClose: () => void;
  absenceId?: number;
  isApprove: boolean;
};

export const ApproveRejectAbsence = ({
  onClose,
  isOpen,
  absenceId,
  isApprove
}: ApproveRejectAbsenceProps) => {
  const [t] = useTranslation('common');
  const queryClient = useQueryClient();
  const successMessage = useSuccessToast();
  const errorToast = useErrorToast();

  const { mutate: changeAbsenceStatus } = useUpdateAbsenceStatus(queryClient, {
    onSuccess: () => {
      successMessage({
        title: isApprove ? t('successfullyAprovedAbsence') : t('successfullyRejectedAbsence')
      });
      onClose();
    },
    onError: (error) => {
      errorToast(handleError(error));
      onClose();
    }
  });

  const handleSubmit = () => {
    const payload: PartialAbsenceUpdateProps = {
      patchPayload: [
        {
          op: 'replace',
          path: '/approvalStatus',
          value: isApprove ? ABSENCE_APPROVAL_STATUS.APPROVED : ABSENCE_APPROVAL_STATUS.REJECTED
        }
      ],
      id: absenceId
    };
    changeAbsenceStatus(payload);
  };

  return (
    <CustomConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle={isApprove ? t('approve') : t('reject')}
      confirmButtonLabel={isApprove ? t('approve') : t('reject')}
      message={isApprove ? t('confirmApprove') : t('confirmReject')}
      handleClick={handleSubmit}
    />
  );
};
