import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';
import brandColor from '@/resources/theme/config.json';

export const themeConfiguration = extendTheme(
  {
    colors: {
      brand: {
        50: brandColor[50],
        100: brandColor[100],
        150: brandColor[150],
        200: brandColor[200],
        300: brandColor[300],
        400: brandColor[400],
        500: brandColor[500],
        600: brandColor[600],
        700: brandColor[700],
        800: brandColor[800],
        900: brandColor[900]
      }
    },
    components: {
      Input: {
        solid: (props: any) => ({
          bg: props.colorMode === 'dark' ? 'gray.500' : 'white'
        }),
        defaultProps: {
          focusBorderColor: 'brand.500',
          borderColor: 'black'
        }
      },
      NumberInput: {
        solid: (props: any) => ({
          bg: props.colorMode === 'dark' ? 'gray.500' : 'white',
        }),
        defaultProps: {
          focusBorderColor: 'brand.500',
          borderColor: 'black'
        }
      },
      Select: {
        solid: (props: any) => ({
          bg: props.colorMode === 'dark' ? 'gray.500' : 'white',
        }),
        defaultProps: {
          focusBorderColor: 'brand.500',
          borderColor: 'brand.400'
        }
      },
      Checkbox: {
        solid: (props: any) => ({
          bg: props.colorMode === 'dark' ? 'gray.500' : 'white',
        }),
        baseStyle: {
          icon: {
            m: '0px'
          }
        }
      }
    },
    config: {
      initialColorMode: 'light'
    },
    fonts: {
      body: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
      code: 'source-code-pro, Menlo, Monaco, Consolas, \'Courier New\', monospace'
    },
    styles: {
      global: {
        html: {
          minHeight: '100%'
        },
        spinner: {
          solid: (props: any) => ({
            color: props.colorMode === 'dark' ? 'white' : 'black',
          }),
        },
        td: {
          bg: localStorage.getItem('chakra-ui-color-mode') === 'dark' ? 'gray.900' : 'white'
        },
        search: {
          solid: (props: any) => ({
            bg: props.colorMode === 'dark' ? 'black' : 'white',
          }),
        },
        popover: {
          solid: (props: any) => ({
            bg: props.colorMode === 'dark' ? 'gray.700' : 'white',
          }),
        },
        avatar: {
          solid: (props: any) => ({
            bg: props.colorMode === 'dark' ? 'gray.700' : 'gray.200',
            borderColor: props.colorMode === 'dark' ? 'gray.700' : 'gray.400'
          }),
        },
        profil: {
          solid: (props: any) => ({
            bg: props.colorMode === 'dark' ? 'gray.700' : 'gray.100',
          }),
        },
        body: {
          solid: (props: any) => ({
            color: props.colorMode === 'dark' ? 'white' : 'black',
          }),
          bgGradient: localStorage.getItem('chakra-ui-color-mode') === 'dark' ? 'linear(to-b, gray.900, gray.700)' : 'linear(to-b, gray.200, gray.50)'
        }
      }
    }
  },
  withDefaultColorScheme({
    colorScheme: 'brand'
  })
);
