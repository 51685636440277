import React, { createContext, FC } from 'react';

type Props = {
  fetchPermissions: (permissions: number[]) => Promise<boolean>
  children: React.ReactNode;
};

type PermissionCache = {
  [key:number]: boolean;
};

type PermissionContextType = {
  isAllowedTo: (permissions: number[]) => Promise<boolean>;
};

const defaultBehaviour: PermissionContextType = {
  isAllowedTo: () => Promise.resolve(false)
};

export const PermissionContext = createContext<PermissionContextType>(defaultBehaviour);

export const PermissionProvider: FC<Props> = ({fetchPermissions, children}) => {

  const cache: PermissionCache = {};

  const isAllowedTo = async (permission: number[]): Promise<boolean> => {
    permission.map(allowedPermission => {
      if (Object.keys(cache).includes(allowedPermission.toString())) {
        return cache[allowedPermission];
      }
    });

    const isAllowed = await fetchPermissions(permission);
    permission.map(allowedPermission => {
      cache[allowedPermission] = isAllowed;
    });

    return isAllowed;
  };

  return <PermissionContext.Provider value={{isAllowedTo}}>{children}</PermissionContext.Provider>;
};

