import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  Flex,
  FlexProps,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Spacer,
  Text as Info,
  VStack
} from '@chakra-ui/react';

import { UserContext } from '@/contexts';
import { themeConfiguration } from '@/resources';
import { ReactComponent as Logo } from '@/resources/img/company-logo.svg';
import { ReactComponent as Logout } from '@/resources/img/logout.svg';
import { ReactComponent as MyProfile } from '@/resources/img/myprofile.svg';

interface MobileProps extends FlexProps {
  onOpen: () => void;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
}

export const MobileNav = ({ onOpen, title, setTitle, ...rest }: MobileProps) => {
  const [t] = useTranslation('common');
  const { currentUser, logout } = useContext(UserContext);
  const navigate = useNavigate();
  const fullName = `${currentUser?.firstName} ${currentUser?.lastName}`;
  const email = currentUser?.email;

  return (
    <Flex
      position='fixed'
      zIndex='999'
      w='100%'
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height='16'
      alignItems='center'
      bg='brand.600'
      borderBottomWidth='1px'
      verticalAlign='center'
      borderStyle='none'
      justifyContent={{ base: 'space-between', md: 'flex-start' }}
      {...rest}>
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant='outline'
        aria-label='open menu'
        bg='white'
        icon={<HamburgerIcon viewBox='0 0 20 24' boxSize={6} />}
      />
      <Info display={{ base: 'flex', md: '2' }} fontSize='3xl' color='white' ml='2rem'>
        {t(title)}
      </Info>
      <Spacer />
      <HStack style={{ marginRight: '16.75rem' }}>
        <Button variant='unstyled' onClick={() => navigate('/')} w='160px'>
          <Logo />
        </Button>
        <Popover>
          <PopoverTrigger>
            <Button variant='unstyled'>
              <Avatar
                size='full'
                fontSize='2.3em'
                name={fullName}
                bg='brand.500'
                border='1px solid gray'
              />
            </Button>
          </PopoverTrigger>
          <PopoverContent
            borderColor='gray.400'
            w='auto'
            minW='215px'
            marginRight='30px'
            backgroundColor={themeConfiguration.styles.avatar}>
            <PopoverArrow backgroundColor={themeConfiguration.styles.avatar} marginLeft='10px' />
            <PopoverBody>
              <VStack display='flex' alignItems='center' w='100%' justifyContent='center'>
                <Info color='brand.600' fontWeight='600' fontSize='20px'>
                  {fullName}
                </Info>
                <Info color='brand.600' fontSize='15px'>
                  {email}
                </Info>
              </VStack>
            </PopoverBody>
            <PopoverFooter
              display='flex'
              justifyContent='left'
              flexDirection='column'
              height='100%'
              borderTop='2px solid brand.600'>
              <Button
                w='100%'
                backgroundColor={
                  localStorage.getItem('chakra-ui-color-mode') === 'dark' ? 'gray.400' : 'white'
                }
                display='flex'
                justifyContent='left'
                paddingLeft='0'
                _hover={{
                  bg:
                    localStorage.getItem('chakra-ui-color-mode') === 'dark'
                      ? 'gray.300'
                      : 'whitesmoke'
                }}
                onClick={() => {
                  navigate('/myProfile');
                  setTitle('myProfile');
                }}>
                <Box display='flex' alignItems='center' h='40px'>
                  <MyProfile />
                  <Info color='black' paddingLeft='5px'>
                    {t('myProfile')}
                  </Info>
                </Box>
              </Button>
              <Button
                backgroundColor={
                  localStorage.getItem('chakra-ui-color-mode') === 'dark' ? 'gray.400' : 'white'
                }
                paddingLeft='0'
                display='flex'
                justifyContent='left'
                _hover={{
                  bg:
                    localStorage.getItem('chakra-ui-color-mode') === 'dark'
                      ? 'gray.300'
                      : 'whitesmoke'
                }}
                onClick={logout}>
                <Box display='flex' alignItems='center' h='40px'>
                  <Logout />
                  <Info color='black' paddingLeft='5px'>
                    {t('logout')}
                  </Info>
                </Box>
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </Popover>
      </HStack>
    </Flex>
  );
};
