import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper
} from '@chakra-ui/react';
import { Field } from 'formik';

import { themeConfiguration } from '@/resources';

export interface CustomNumberInputProps extends NumberInputProps {
  id: string;
  label?: string;
  error?: string;
}

export const CustomNumberInput = (props: CustomNumberInputProps) => {
  return (
    <FormControl isInvalid={props.isInvalid}>
      <FormLabel mr='0' mb='1' htmlFor={props.id}>
        {props.label}
        <Field as={NumberInput} {...props} name={props.id} border={`1px solid ${themeConfiguration.colors.brand[300]}`} borderRadius='md' pattern='[0-9]+'>
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </Field>
      </FormLabel>
      <FormErrorMessage mt='-1' mb='1'>
        {props.error}
      </FormErrorMessage>
    </FormControl>
  );
};
