export const firebaseConfig = {
  apiKey: window.REACT_APP_FIREBASE_API_KEY,
  authDomain: window.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: window.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: window.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: window.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: window.REACT_APP_FIREBASE_APP_ID
};

export const dateFormat = window.REACT_APP_DATE_FORMAT;

export const apiUrl = () => {
  const r = new RegExp('^(?:[a-z]+:)?//', 'i');

  if (r.test(window.LEAWIE_APP_CORE_URL)) {
    return window.LEAWIE_APP_CORE_URL;
  } else {
    return window.location.origin + window.LEAWIE_APP_CORE_URL;
  }
};
