import { useEffect, useState } from 'react';
import { SlotInfo } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Box, Switch, Text as ChakraText, VStack } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { PaginationState, SortingState } from '@tanstack/react-table';
import moment from 'moment';
import { RRule } from 'rrule';

import {
  CalendarEventTitle,
  CreateEditHoliday,
  CustomCalendar,
  CustomConfirmationModal,
  GenericTable,
  PageLoadingState,
  Restricted,
  ViewHolidayUsers
} from '@/components';
import { PERMISSION_TYPE } from '@/enums';
import {
  formatDate,
  formatEventsStyles,
  getHolidayColumns,
  handleError,
  initialAxiosResponse,
  SadStates,
  useErrorToast,
  useSuccessToast
} from '@/helpers';
import { useDebounce } from '@/hooks';
import { useDeleteReligiousHolidayMutation, useGetReligiousHolidaysQuery } from '@/services';
import { CustomView, HolidayProps, TableData } from '@/types';

export const ReligiousHolidays = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [viewUserModalOpen, setViewUserModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [holidayId, setHolidayId] = useState<number>();
  const [holidayName, setHolidayName] = useState<string>();
  const [search, setSearch] = useState(
    JSON.parse(localStorage.getItem('religiousHolidaysSearch') || 'null')
  );
  const [sorting, setSorting] = useState<SortingState>([{ id: 'name', desc: false }]);
  const [isOpenTableView, setIsOpenTableView] = useState<boolean>(false);
  const [view, setView] = useState<CustomView>('month');
  const [startDate, setStartDate] = useState(new Date());
  const [t] = useTranslation('common');
  const errorToast = useErrorToast();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });
  const queryClient = useQueryClient();
  const successToast = useSuccessToast();
  const permissionsReligiousHoliday = [
    PERMISSION_TYPE.CREATERELIGIOUSHOLIDAY,
    PERMISSION_TYPE.VIEWRELIGIOUSHOLIDAYBYID,
    PERMISSION_TYPE.EDITRELIGIOUSHOLIDAY,
    PERMISSION_TYPE.DELETERELIGIOUSHOLIDAY
  ];
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [searchParams, setSearchParams] = useSearchParams();
  const [queryParams, setQueryParams] = useState(() => ({
    searchQuery: searchParams.get('searchQuery') ?? []
  }));
  const [debounceDelay, setDebounceDelay] = useState<number>(0);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('religiousHolidaysSearch') || 'null') === null) {
      localStorage.setItem('religiousHolidaysSearch', JSON.stringify(''));
    }

    if (searchParams.get('searchQuery') === null) {
      updateQueryParams(
        'searchQuery',
        JSON.parse(localStorage.getItem('religiousHolidaysSearch') || 'null')
      );
      setSearch(JSON.parse(localStorage.getItem('religiousHolidaysSearch') || 'null'));
    } else {
      setSearch(queryParams.searchQuery.toString());
      localStorage.setItem(
        'religiousHolidaysSearch',
        JSON.stringify(queryParams.searchQuery.toString())
      );
    }
  }, []);

  useEffect(() => {
    setSearchParams({
      searchQuery: queryParams.searchQuery === '' ? [] : queryParams.searchQuery
    });

    deleteQueryParams('searchQuery');
  }, [queryParams, searchParams, setSearchParams]);

  const deleteQueryParams = (value: string) => {
    if (searchParams.has(value) && searchParams.get(value) === '') {
      searchParams.delete(value);
      updateQueryParams(value, []);
      setSearchParams(searchParams);
    }
  };

  const updateQueryParams = (key: string, value: string | string[]) => {
    setQueryParams((prevState) => ({ ...prevState, [key]: value }));
  };

  const { data: religiousHolidays = initialAxiosResponse, isLoading } =
    useGetReligiousHolidaysQuery(
      pageSize,
      pageIndex + 1,
      sorting.map((s) => s.id.charAt(0).toUpperCase() + s.id.slice(1)).toString(),
      sorting.some((s) => s.desc),
      {
        onSuccess: () => void 0,
        onError: (error) => {
          errorToast(handleError(error));
        }
      },
      useDebounce(search, debounceDelay)
    );

  const { mutate, isLoading: isLoadingDeleteHoliday } = useDeleteReligiousHolidayMutation(
    queryClient,
    {
      onSuccess: () => {
        successToast({ title: t('successfulHolidayDelete') });
        handleCloseDeleteModal();
      },
      onError: (error) => {
        errorToast(handleError(error));
      }
    }
  );

  const handleEdit = (holiday: TableData | HolidayProps) => {
    setShowModal(true);
    setHolidayId(Number(holiday.id));
  };

  const handleDelete = (row: TableData | HolidayProps) => {
    setIsDeleteModalOpen(true);
    setHolidayId(row.id);
    setHolidayName(row.name);
  };

  const handleViewUsersWhoCelebrate = (id: number) => {
    setViewUserModalOpen(true);
    setHolidayId(Number(id));
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setHolidayId(undefined);
  };

  const handleCloseCreateEditModal = () => {
    setShowModal(false);
    setHolidayId(undefined);
    setStartDate(new Date());
  };

  const handleCloseViewUsersModal = () => {
    setViewUserModalOpen(false);
    setHolidayId(undefined);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
    setPagination({ pageIndex: 0, pageSize });
    localStorage.setItem('religiousHolidaysSearch', JSON.stringify(value));
    updateQueryParams('searchQuery', value);
    setDebounceDelay(500);
  };

  const handleViewChange = (newView: CustomView) => {
    setView(newView);
  };

  const handleCreateReligiousHoliday = (slot: SlotInfo) => {
    setShowModal(true);
    setStartDate(slot.start);
  };

  const formatEvents = (holiday: HolidayProps, date?: Date) => {
    return {
      id: holiday.id,
      start: holiday.recurring
        ? moment(date).startOf('day').toDate()
        : moment(holiday.startsAt).startOf('day').toDate(),
      allDay: true,
      title:
        view === 'year' ? (
          holiday.name
        ) : (
          <CalendarEventTitle
            holiday={holiday}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            isReligiousHolidays={true}
            handleViewUsersWhoCelebrate={handleViewUsersWhoCelebrate}
          />
        ),
      type: 'religiousHolidays',
      end: moment(holiday.recurring ? date : holiday.startsAt)
        .add(holiday.totalDaysCelebrated === 1 ? 0 : holiday.totalDaysCelebrated - 1, 'day')
        .endOf('day')
        .toDate()
    };
  };

  const formatedReligiousHolidays = religiousHolidays?.data?.items
    .map((holiday: HolidayProps) => {
      if (holiday.recurring) {
        const rruleObj = new RRule({
          freq: RRule.YEARLY,
          dtstart: moment(holiday.startsAt).add(-10, 'years').startOf('day').toDate(),
          until: moment(new Date()).add(10, 'years').toDate()
        });
        const recurringEvents = rruleObj.all();
        return recurringEvents.map((date) => formatEvents(holiday, date));
      } else {
        return formatEvents(holiday);
      }
    })
    .flat();

  useEffect(() => {
    isOpenTableView
      ? setPagination({ pageIndex: 0, pageSize: 10 })
      : setPagination({ pageIndex: 0, pageSize: 0 });
  }, [isOpenTableView]);

  return (
    <Box>
      <VStack align='stretch' gap='1' mx='3rem' my='1em'>
        <SadStates
          states={[
            {
              when: isLoading,
              render: () => <PageLoadingState />
            }
          ]}>
          <>
            <Box>
              <Switch
                id='switchReligiousHolidays'
                size='lg'
                onChange={() => setIsOpenTableView(!isOpenTableView)}
              />
              <ChakraText as='span' marginLeft='10px'>
                {t('switchToTableView')}
              </ChakraText>
            </Box>
            {religiousHolidays?.data &&
              (isOpenTableView ? (
                <>
                  <GenericTable
                    data={religiousHolidays?.data?.items?.map(
                      (item: {
                        startsAt: string | number | Date;
                        createdAt: string | number | Date;
                        updatedAt: string | number | Date;
                        overflowsToWorkdaysIfWeekend: boolean;
                        recurring: boolean;
                      }) => ({
                        ...item,
                        startsAt: formatDate(item.startsAt),
                        createdAt: formatDate(item.createdAt),
                        updatedAt: formatDate(item.updatedAt),
                        overflowsToWorkdaysIfWeekend: item.overflowsToWorkdaysIfWeekend
                          ? t('yes')
                          : t('no'),
                        recurring: item.recurring ? t('yes') : t('no')
                      })
                    )}
                    columns={getHolidayColumns()}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    pageCount={religiousHolidays?.data ? religiousHolidays.data.totalPages : 0}
                    setPagination={setPagination}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    handleView={handleViewUsersWhoCelebrate}
                    defaultHiddenColumns={{
                      recurring: false
                    }}
                    handleSearch={(value) => handleSearch(value)}
                    searchValue={search}
                    handleCreateData={() => setShowModal(true)}
                    localStorageKey='religiousHolidays'
                    dragAndDropLocalStorageKey='dndReligiousHolidays'
                    sorting={sorting}
                    setSorting={setSorting}
                    permissions={permissionsReligiousHoliday}
                  />
                </>
              ) : (
                <CustomCalendar
                  view={view}
                  handleView={handleViewChange}
                  handleCreate={handleCreateReligiousHoliday}
                  events={formatedReligiousHolidays}
                  eventsStyles={() => formatEventsStyles(view)}
                  year={year}
                  setYear={setYear}
                  handleSearch={(value) => handleSearch(value)}
                  searchValue={search}
                  handleCreateData={() => setShowModal(true)}
                />
              ))}
          </>
        </SadStates>
      </VStack>
      <Restricted
        to={[PERMISSION_TYPE.CREATERELIGIOUSHOLIDAY, PERMISSION_TYPE.EDITRELIGIOUSHOLIDAY]}>
        <CreateEditHoliday
          isOpen={showModal}
          onClose={handleCloseCreateEditModal}
          holidayId={holidayId}
          defaultStartDate={startDate}
        />
      </Restricted>
      <Restricted to={[PERMISSION_TYPE.VIEWUSERS]}>
        <ViewHolidayUsers
          isOpen={viewUserModalOpen}
          onClose={handleCloseViewUsersModal}
          holidayId={holidayId}
        />
      </Restricted>
      <Restricted to={[PERMISSION_TYPE.DELETERELIGIOUSHOLIDAY]}>
        <CustomConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={handleCloseDeleteModal}
          handleClick={() => mutate(Number(holidayId))}
          confirmButtonLabel={t('yesDelete')}
          message={`${t('confirmReligiousHolidayDeletion')} ${holidayName}?`}
          modalTitle={t('deleteReligiousHoliday')}
          isSubmitting={isLoadingDeleteHoliday}
        />
      </Restricted>
    </Box>
  );
};
