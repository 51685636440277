import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text as ChakraText
} from '@chakra-ui/react';

export type CustomModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  formId: string;
  isSubmitting: boolean;
  submitButtonLabel: string;
};

export const CustomModal = ({
  isOpen,
  onClose,
  title,
  children,
  formId,
  isSubmitting,
  submitButtonLabel
}: CustomModalProps) => {
  const [t] = useTranslation('common');

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl' isCentered scrollBehavior='outside'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display='flex' justifyContent='space-between' alignItems='center'>
          <ChakraText>{title}</ChakraText>
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter width='100%' display='flex' justifyContent='right' alignItems='right'>
          <Flex>
            <Button
              type='button'
              minW='150px'
              size='lg'
              onClick={onClose}
              m='0 20px'
              backgroundColor='white'
              border='1px solid #393053'
              color='#393053'
              _hover={{ backgroundColor: 'whitesmoke' }}>
              {t('cancel')}
            </Button>
            <Button
              form={formId}
              type='submit'
              minW='150px'
              disabled={isSubmitting}
              size='lg'
              mr='5%'>
              {submitButtonLabel}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
