import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionMeta, MultiValue, SingleValue } from 'react-select';
import { AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import { useQueryClient } from '@tanstack/react-query';
import * as Yup from 'yup';

import {
  CustomDateInput,
  CustomInput,
  CustomModal,
  CustomSelect,
  ModalErrorState,
  ModalLoadingState
} from '@/components';
import { UserContext } from '@/contexts';
import { PERMISSION_TYPE } from '@/enums';
import { handleError, SadStates, useErrorToast, useSuccessToast } from '@/helpers';
import {
  changeUserDetails,
  useCreateUserMutation,
  useGetAllRolesQuery,
  useGetReligiousHolidaysQuery,
  useGetUserByIdQuery
} from '@/services';
import {
  ChangeUserProfile,
  CreateUserForm,
  HolidaysToShow,
  RoleType,
  SelectOption,
  ShowUserDetails
} from '@/types';

type UserDetailsProps = {
  onClose: () => void;
  isEditMode: boolean;
  selectedUserId: string;
  isOpen: boolean;
};

export const CreateEditUser = ({
  onClose,
  isEditMode,
  selectedUserId,
  isOpen
}: UserDetailsProps) => {
  const { permissions } = useContext(UserContext);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [t] = useTranslation('common');
  const queryClient = useQueryClient();
  const succesToast = useSuccessToast();
  const errorToast = useErrorToast();
  const orderBy = 'name';
  const orderByDesc = false;
  const [isSelectOpenScroll, setIsSelectOpenScroll] = useState(false);

  const { data: roleData, isLoading: isRoleLoading, isError: isRoleError } = useGetAllRolesQuery();

  const {
    data,
    isLoading: isReligiousHolidaysLoading,
    isError: isReligiousHolidaysError
  } = useGetReligiousHolidaysQuery(
    0,
    1,
    orderBy.charAt(0).toUpperCase() + orderBy.slice(1),
    orderByDesc,
    {
      enabled: permissions.includes(PERMISSION_TYPE.VIEWRELIGIOUSHOLIDAYS),
      onSuccess: () => void 0,
      onError: (error) => {
        errorToast(handleError(error));
      }
    },
    ''
  );

  const {
    data: selectedUserData,
    isLoading: isUserDataLoading,
    isError: isUserDataError,
    isRefetching
  } = useGetUserByIdQuery(selectedUserId, {
    onSuccess: () => void 0,
    onError: (error) => {
      errorToast(handleError(error));
    }
  });

  const { mutate: createUser } = useCreateUserMutation(queryClient, {
    onSuccess: (response?: AxiosResponse) => {
      onClose();
      succesToast({ title: t('userCreationSuccessful', { response }) });
      setIsSubmitting(false);
    },
    onError: (error) => {
      errorToast(handleError(error));
      setIsSubmitting(false);
    }
  });

  const { mutate: changeUserProfile } = changeUserDetails(queryClient, {
    onSuccess: (response?: AxiosResponse) => {
      onClose();
      succesToast({ title: t('updateUserDetailsSuccessfully', { response }) });
      setIsSubmitting(false);
    },
    onError: (error) => {
      errorToast(handleError(error));
      setIsSubmitting(false);
    }
  });

  const religiousHolidaysSchema = Yup.object().shape({
    id: Yup.number(),
    name: Yup.string()
  });

  const createUserValidationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(t('firstNameRequired'))
      .min(2, t('nameMin'))
      .max(35, t('nameMax')),
    lastName: Yup.string()
      .required(t('lastNameRequired'))
      .min(2, t('nameMin'))
      .max(35, t('nameMax')),
    email: Yup.string().required(t('emailRequired')).email(t('emailInvalid')),
    referenceNumber: Yup.string()
      .required(t('referenceNumberRequired'))
      .max(20, t('refNumberLength')),
    employmentStartedAt: Yup.date()
      .required(t('employmentStartedAtRequired'))
      .nullable()
      .transform((v) => (v instanceof Date ? v : null)),
    religiousHolidays: Yup.array().of(religiousHolidaysSchema).max(2, t('maxReligiousHolidays'))
  });

  const initialValues = (): ShowUserDetails => {
    const selected = selectedUserData?.data;
    return {
      id: selected?.id || '',
      firstName: selected?.firstName || '',
      lastName: selected?.lastName || '',
      roleId: selected?.role?.roleId || 2,
      email: selected?.email || '',
      employmentStartedAt: new Date(
        selected?.employmentStartedAt?.toString().split('/').reverse().join('-') || new Date()
      ),
      referenceNumber: selected?.referenceNumber || '',
      religiousHolidays:
        selected?.religiousHolidays.map((holiday: HolidaysToShow) => ({
          value: holiday.id,
          label: holiday.name
        })) || []
    };
  };

  const handleChange = (
    newValue: MultiValue<SelectOption> | SingleValue<SelectOption>,
    action: ActionMeta<SelectOption>,
    setFieldValue: (
      field: string,
      value: MultiValue<SelectOption> | SingleValue<SelectOption>,
      shouldValidate?: boolean
    ) => void
  ) => {
    const { action: actionType } = action;
    if (actionType === 'clear') {
      setFieldValue('religiousHolidays', []);
      return;
    }
    setFieldValue('religiousHolidays', newValue, true);
  };

  const handleSubmit = (values: ShowUserDetails) => {
    setIsSubmitting(true);
    if (isEditMode) {
      const payload: ChangeUserProfile = {
        id: values?.id,
        firstName: values?.firstName,
        lastName: values?.lastName,
        roleId: values?.roleId,
        employmentStartedAt: values?.employmentStartedAt,
        referenceNumber: values?.referenceNumber,
        religiousHolidayIds: values?.religiousHolidays?.map((holidayIds) =>
          Number(holidayIds.value)
        )
      };
      changeUserProfile({ payload });
    } else {
      const payload: CreateUserForm = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        referenceNumber: values.referenceNumber,
        roleId: values.roleId,
        employmentStartedAt: new Date(values.employmentStartedAt),
      };
      createUser(payload);
    }
  };

  const handleMenuOpen = () => {
    setIsSelectOpenScroll(true);
  };

  const handleMenuClose = () => {
    setIsSelectOpenScroll(false);
  };

  return (
    <>
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        formId='userDetailsForm'
        submitButtonLabel={isEditMode ? t('editBtn') : t('add')}
        title={isEditMode ? t('editUser') : t('addUser')}
        isSubmitting={isSubmitting}>
        <SadStates
          states={[
            {
              when:
                (isEditMode && isUserDataLoading) || isRoleLoading || isReligiousHolidaysLoading || isRefetching,
              render: () => <ModalLoadingState />
            },
            {
              when: (isEditMode && isUserDataError) || isRoleError || isReligiousHolidaysError,
              render: () => <ModalErrorState />
            }
          ]}>
          <Formik
            enableReinitialize={!!isEditMode}
            validateOnBlur={false}
            initialValues={initialValues()}
            validationSchema={createUserValidationSchema}
            onSubmit={handleSubmit}>
            {({ errors, touched, setFieldValue, values }) => (
              <Form id='userDetailsForm' style={{ width: '100%' }}>
                <CustomInput
                  type='text'
                  id='firstName'
                  label={t('firstName')}
                  isInvalid={!!errors.firstName && touched.firstName}
                  error={errors.firstName}
                />
                <CustomInput
                  type='text'
                  id='lastName'
                  label={t('lastName')}
                  isInvalid={!!errors.lastName && touched.lastName}
                  error={errors.lastName}
                />
                <CustomInput
                  type='text'
                  id='referenceNumber'
                  label={t('referenceNumber')}
                  isInvalid={!!errors.referenceNumber && touched.referenceNumber}
                  error={errors.referenceNumber}
                />
                <CustomInput
                  type='email'
                  id='email'
                  label={t('email')}
                  isInvalid={!!errors.email && touched.email}
                  error={errors.email}
                />
                <CustomSelect
                  label={t('roleName')}
                  isDisabled={!permissions.includes(PERMISSION_TYPE.EDITUSERBYID)}
                  id='roleId'
                  defaultValue={{
                    value: selectedUserData?.data?.role?.roleId || roleData?.data[1].roleId,
                    label: selectedUserData ? t(`${selectedUserData?.data?.role?.name}`) : t(`${roleData?.data[1].name}`)
                  }}
                  handleChange={(value) =>
                    setFieldValue('roleId', (value as SingleValue<SelectOption>)?.value)
                  }
                  options={roleData?.data?.map((roles: RoleType) => ({
                    value: roles.roleId,
                    label: t(`${roles.name}`)
                  }))}
                  handleMenuOpen={handleMenuOpen}
                  handleMenuClose={handleMenuClose}
                  isSelectOpenScroll={isSelectOpenScroll}
                />
                <CustomDateInput
                  id='employmentStartedAt'
                  label={t('employmentStartedAt')}
                  onChange={(date: Date | null) => setFieldValue('employmentStartedAt', date)}
                  isInvalid={!!errors.employmentStartedAt && touched.employmentStartedAt}
                  error={errors.employmentStartedAt}
                />
                {isEditMode && (
                  <>
                    <CustomSelect
                      label={t('religiousHolidays')}
                      value={values?.religiousHolidays ?? []}
                      isMulti={true}
                      isDisabled={!permissions.includes(PERMISSION_TYPE.EDITUSERBYID)}
                      id='religiousHolidays'
                      handleChange={(newValue, action) =>
                        handleChange(newValue, action, setFieldValue)
                      }
                      options={data?.data?.items.map((religious: HolidaysToShow) => {
                        return { value: religious.id, label: religious.name };
                      })}
                      handleMenuOpen={handleMenuOpen}
                      handleMenuClose={handleMenuClose}
                      isInvalid={!!errors.religiousHolidays}
                      error={errors.religiousHolidays}
                      isSelectOpenScroll={isSelectOpenScroll}
                    />
                  </>
                )}
              </Form>
            )}
          </Formik>
        </SadStates>
      </CustomModal>
    </>
  );
};
