import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Calendar, SlotInfo, View, ViewsProps, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { Box, Flex } from '@chakra-ui/react';
import moment from 'moment';
import 'moment-timezone';

import {
  CustomCalendarHeader,
  CustomCalendarToolbar,
  CustomYearView,
  HeaderOptions
} from '@/components';
import { formatDaysStyles } from '@/helpers';
import { CalendarEventProps, CustomView, TableData } from '@/types';

moment.locale('sr', {
  week: {
    dow: 1,
    doy: 1
  }
});
const localizer = momentLocalizer(moment);

type CustomViewsProps = {
  [key: string]: boolean;
} & ViewsProps<
  {
    id?: number;
    type: number;
    start: Date;
    end: Date;
  },
  object
>;
type CustomCalendarProps = {
  events: CalendarEventProps[];
  handleCreate: (slot: SlotInfo) => void;
  handleEdit?: (id: CalendarEventProps | TableData) => void;
  handleView: (newView: View | CustomView) => void;
  view: View | CustomView;
  eventsStyles: (event: CalendarEventProps) => { style: React.CSSProperties };
  isMyAbsencesAdminView?: boolean;
  year: number;
  setYear: Dispatch<SetStateAction<number>>;
  handleSearch?: (value: string) => void;
  searchValue?: string;
  filter?: React.ReactNode;
  handleCreateData?: () => void;
  publicHolidaysYearValue?: string;
};

export const CustomCalendar = ({
  events,
  handleCreate,
  handleEdit,
  handleView,
  view,
  eventsStyles,
  isMyAbsencesAdminView,
  year,
  setYear,
  handleSearch,
  searchValue,
  filter,
  handleCreateData,
  publicHolidaysYearValue
}: CustomCalendarProps) => {
  const handleCustomYearView = () => {
    handleView('year');
  };
  const [currentDate, setCurrentDate] = useState(new Date());
  const onNavigate = useCallback((newDate: Date) => setCurrentDate(newDate), [setCurrentDate]);

  const [publicHolidaysDate, setPublicHolidaysDate] = useState(
    moment(new Date()).year(Number(publicHolidaysYearValue)).toDate()
  );
  const onNavigatePublicHolidays = useCallback(
    (newDate: Date) => setPublicHolidaysDate(newDate),
    [setPublicHolidaysDate]
  );

  useEffect(() => {
    setPublicHolidaysDate(moment(new Date()).year(Number(publicHolidaysYearValue)).toDate());
  }, [publicHolidaysYearValue]);

  return (
    <Box w='100%'>
      <Flex py='1%' w='full' gap='5'>
        <HeaderOptions
          handleSearch={handleSearch}
          searchValue={searchValue}
          filter={filter}
          handleCreateData={handleCreateData}
          isPublicHoliday={!!publicHolidaysYearValue}
        />
      </Flex>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height={isMyAbsencesAdminView ? '710px' : '744px'}>
        <Box h='100%' w='100%'>
          {view.toString() === 'year' ? (
            <>
              <CustomYearView
                calendarEvents={events}
                view={view}
                handleView={handleView}
                eventsStyles={eventsStyles}
                isMyAbsencesAdminView={isMyAbsencesAdminView}
                year={year}
                setYear={setYear}
                handleCreate={handleCreate}
                handleEdit={handleEdit}
              />
            </>
          ) : (
            <Calendar
              selectable
              localizer={localizer}
              events={events}
              startAccessor='start'
              endAccessor='end'
              views={['month', 'week', 'year'] as CustomViewsProps}
              eventPropGetter={eventsStyles}
              dayPropGetter={formatDaysStyles}
              onSelectSlot={handleCreate}
              onSelectEvent={(e) => handleEdit && handleEdit(e)}
              onView={(newView) => handleView(newView)}
              defaultView={view as View}
              date={
                publicHolidaysYearValue && publicHolidaysYearValue !== '-1'
                  ? moment(publicHolidaysDate).toDate()
                  : moment(currentDate).toDate()
              }
              onNavigate={
                publicHolidaysYearValue && publicHolidaysYearValue !== '-1'
                  ? onNavigatePublicHolidays
                  : onNavigate
              }
              components={{
                week: {
                  header: ({ date }) => <CustomCalendarHeader date={date} isWeekView={true} />
                },
                month: {
                  header: ({ date }) => <CustomCalendarHeader date={date} />
                },
                toolbar: (calendarToolbar) => (
                  <CustomCalendarToolbar
                    view={view}
                    handleView={calendarToolbar.onView}
                    handleOnNavigate={calendarToolbar.onNavigate}
                    handleYearView={handleCustomYearView}
                    toolbarDate={calendarToolbar.date}
                    setYear={setYear}
                  />
                )
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
