import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import {
  CustomDateInput,
  CustomInput,
  CustomModal,
  CustomNumberInput,
  ModalErrorState,
  ModalLoadingState
} from '@/components';
import { handleError, SadStates, useErrorToast, useSuccessToast } from '@/helpers';
import {
  useCreatePublicHolidayMutation,
  useGetPublicHoliday,
  useUpdatePublicHolidayMutation
} from '@/services';
import { HolidayProps } from '@/types';

type CreateEditEventProps = {
  holidayId?: number;
  isOpen: boolean;
  onClose: () => void;
  defaultStartDate: Date;
};

export const CreateEditPublicHoliday = ({
  holidayId,
  isOpen,
  onClose,
  defaultStartDate
}: CreateEditEventProps) => {
  const [t] = useTranslation('common');
  const queryClient = useQueryClient();
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();
  const isHolidayEdit = !!holidayId;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const {
    data: publicHoliday,
    isLoading: isLoadingPublicHoliday,
    isSuccess
  } = useGetPublicHoliday(Number(holidayId));

  const createHolidaySchema = Yup.object().shape({
    name: Yup.string()
      .min(1, t('nameMinLetters'))
      .max(35, t('nameMaxLetters'))
      .required(t('holidayNameRequired'))
      .matches(/^[a-zšđčćžA-ZŠĐČĆŽ/.\s_-]+$/, t('nameFormat')),
    startsAt: Yup.date()
      .required(t('dateRequired'))
      .nullable()
      .transform((v) => (v instanceof Date ? v : null)),
    totalDaysCelebrated: Yup.number()
      .required(t('totalDaysCelebratedRequired'))
      .min(1, t('holidayMinDays'))
      .max(14, t('holidayMaxDays'))
  });

  const { mutate: createHoliday } = useCreatePublicHolidayMutation(queryClient, {
    onSuccess: () => {
      successToast({ title: t('successfulHolidayCreation') });
      onClose();
      setIsSubmitting(false);
    },
    onError: (error) => {
      errorToast(handleError(error));
      setIsSubmitting(false);
    }
  });

  const { mutate: editHoliday } = useUpdatePublicHolidayMutation(queryClient, {
    onSuccess: () => {
      onClose();
      successToast({ title: t('successfulHolidayEdit') });
      setIsSubmitting(false);
    },
    onError: (error) => {
      errorToast(handleError(error));
      setIsSubmitting(false);
    }
  });

  const getInitialValues = (): HolidayProps => {
    return {
      id: publicHoliday?.data?.id || 0,
      name: publicHoliday?.data?.name || '',
      startsAt: new Date(
        publicHoliday?.data?.startsAt?.toString().split('/').reverse().join('-') ||
          moment(defaultStartDate).format('YYYY-MM-DDTHH:mm:ss[Z]')
      ),
      totalDaysCelebrated: publicHoliday?.data?.totalDaysCelebrated || 1
    };
  };

  const handleSubmit = (values: HolidayProps) => {
    setIsSubmitting(true);
    const payload: HolidayProps = {
      id: values.id,
      name: values.name,
      startsAt: new Date(values.startsAt),
      totalDaysCelebrated: values.totalDaysCelebrated
    };
    if (isHolidayEdit) {
      editHoliday(payload);
    } else {
      createHoliday(payload);
    }
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      title={isHolidayEdit ? t('editPublicHoliday') : t('addPublicHoliday')}
      formId='publicHolidaysForm'
      isSubmitting={isSubmitting}
      submitButtonLabel={isHolidayEdit ? t('editBtn') : t('add')}>
      <SadStates
        states={[
          {
            when: isHolidayEdit && isLoadingPublicHoliday,
            render: () => <ModalLoadingState />
          },
          {
            when: isHolidayEdit && !isSuccess,
            render: () => <ModalErrorState />
          }
        ]}>
        <Formik
          enableReinitialize
          validateOnBlur={false}
          validationSchema={createHolidaySchema}
          initialValues={getInitialValues()}
          onSubmit={handleSubmit}>
          {({ errors, touched, setFieldValue }) => (
            <Form id='publicHolidaysForm' style={{ width: '100%', height: '19rem' }}>
              <CustomInput
                type='text'
                isInvalid={!!errors.name && touched.name}
                id='name'
                label={t('name')}
                error={errors.name}
              />
              <CustomDateInput
                isInvalid={!!errors.startsAt && touched.startsAt}
                id='startsAt'
                label={t('startsAt')}
                error={errors.startsAt}
                onChange={(date: Date | null) => setFieldValue('startsAt', date)}
              />
              <CustomNumberInput
                focusBorderColor='brand.500'
                id='totalDaysCelebrated'
                label={t('totalDaysCelebrated')}
                isInvalid={!!errors.totalDaysCelebrated}
                min={1}
                max={14}
                error={errors.totalDaysCelebrated}
                onChange={(changeEvent: string) =>
                  setFieldValue('totalDaysCelebrated', changeEvent)
                }
              />
            </Form>
          )}
        </Formik>
      </SadStates>
    </CustomModal>
  );
};
