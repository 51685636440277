import { Box, Tooltip } from '@chakra-ui/react';

export const HolidaysTooltip = ({ label }: { label: string }) => (
  <Tooltip
    hasArrow
    p='7px 10px'
    label={label}
    bg='brand.600'
    borderRadius='10px'
    position='absolute'
    zIndex='125'
    color='white'
    w='100%'
    h='100%'>
    <Box w='100%' h='100%' title=''></Box>
  </Tooltip>
);
