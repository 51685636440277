import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import {
  CustomDateInput,
  CustomInput,
  CustomInputCheckbox,
  CustomModal,
  CustomNumberInput,
  ModalErrorState,
  ModalLoadingState
} from '@/components';
import { handleError, SadStates, useErrorToast, useSuccessToast } from '@/helpers';
import {
  useCreateReligiousHolidayMutation,
  useGetReligiousHoliday,
  useUpdateReligiousHolidayMutation
} from '@/services';
import { HolidayProps } from '@/types';

export type AddEditHolidayProps = {
  isOpen: boolean;
  onClose: () => void;
  holidayId?: number;
  defaultStartDate: Date;
};

export const CreateEditHoliday = ({
  isOpen,
  onClose,
  holidayId,
  defaultStartDate
}: AddEditHolidayProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [t] = useTranslation('common');
  const queryClient = useQueryClient();
  const succesToast = useSuccessToast();
  const errorToast = useErrorToast();
  const isHolidayEdit = !!holidayId;

  const {
    data: religiousHoliday,
    isLoading: isLoadingReligiousHoliday,
    isSuccess
  } = useGetReligiousHoliday(Number(holidayId));

  const createHolidaySchema = Yup.object().shape({
    name: Yup.string()
      .min(1, t('nameMinLetters'))
      .max(35, t('nameMaxLetters'))
      .required(t('holidayNameRequired'))
      .matches(/^[a-zšđčćžA-ZŠĐČĆŽ/.\s_-]+$/, t('nameFormat')),
    startsAt: Yup.date()
      .required(t('dateRequired'))
      .nullable()
      .transform((v) => (v instanceof Date ? v : null)),
    totalDaysCelebrated: Yup.number()
      .required(t('totalDaysCelebratedRequired'))
      .min(1, t('holidayMinDays'))
      .max(14, t('holidayMaxDays'))
  });

  const { mutate: createHoliday } = useCreateReligiousHolidayMutation(queryClient, {
    onSuccess: () => {
      onClose();
      succesToast({ title: t('successfulHolidayCreation') });
      setIsSubmitting(false);
    },
    onError: (error) => {
      errorToast(handleError(error));
      setIsSubmitting(false);
    }
  });

  const { mutate: updateHoliday } = useUpdateReligiousHolidayMutation(queryClient, {
    onSuccess: () => {
      onClose();
      succesToast({ title: t('successfulHolidayEdit') });
      setIsSubmitting(false);
    },
    onError: (error) => {
      errorToast(handleError(error));
      setIsSubmitting(false);
    }
  });

  const getInitialValues = (): HolidayProps => {
    return {
      name: religiousHoliday?.data?.name || '',
      startsAt: new Date(
        religiousHoliday?.data.startsAt?.toString().split('/').reverse().join('-') ||
          moment(defaultStartDate).format('YYYY-MM-DDTHH:mm:ss[Z]')
      ),
      overflowsToWorkdaysIfWeekend: religiousHoliday?.data.overflowsToWorkdaysIfWeekend || false,
      recurring: religiousHoliday?.data.recurring || false,
      totalDaysCelebrated: religiousHoliday?.data?.totalDaysCelebrated || 1,
      id: religiousHoliday?.data?.id
    };
  };

  const handleSubmit = (values: HolidayProps) => {
    setIsSubmitting(true);
    const payload: HolidayProps = {
      name: values.name,
      startsAt: values.startsAt,
      totalDaysCelebrated: values.totalDaysCelebrated,
      recurring: values.recurring,
      overflowsToWorkdaysIfWeekend: values.overflowsToWorkdaysIfWeekend,
      id: values.id
    };
    if (isHolidayEdit) {
      updateHoliday(payload);
    } else {
      createHoliday(payload);
    }
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      title={isHolidayEdit ? t('editReligiousHoliday') : t('addReligiousHoliday')}
      formId='religiousHolidayForm'
      isSubmitting={isSubmitting}
      submitButtonLabel={isHolidayEdit ? t('editBtn') : t('add')}>
      <SadStates
        states={[
          {
            when: isHolidayEdit && isLoadingReligiousHoliday,
            render: () => <ModalLoadingState />
          },
          {
            when: isHolidayEdit && !isSuccess,
            render: () => <ModalErrorState />
          }
        ]}>
        <Formik
          enableReinitialize
          validateOnBlur={false}
          initialValues={getInitialValues()}
          validationSchema={createHolidaySchema}
          onSubmit={handleSubmit}>
          {({ errors, touched, setFieldValue, values }) => (
            <Form id='religiousHolidayForm' style={{ width: '100%', height: '25rem' }}>
              <CustomInput
                type='text'
                isInvalid={!!errors.name && touched.name}
                id='name'
                label={t('name')}
                error={errors.name}
              />
              <CustomDateInput
                isInvalid={!!errors.startsAt && touched.startsAt}
                id='startsAt'
                label={t('startsAt')}
                error={errors.startsAt}
                onChange={(date: Date | null) => setFieldValue('startsAt', date)}
              />
              <CustomNumberInput
                focusBorderColor='brand.500'
                id='totalDaysCelebrated'
                label={t('totalDaysCelebrated')}
                isInvalid={!!errors.totalDaysCelebrated}
                min={1}
                max={14}
                error={errors.totalDaysCelebrated}
                onChange={(changeEvent: string) =>
                  setFieldValue('totalDaysCelebrated', changeEvent)
                }
              />
              <CustomInputCheckbox
                error={errors.recurring}
                isInvalid={!!errors.recurring}
                id='recurring'
                label={t('recurring')}
                isChecked={values.recurring}
                onChange={(changeEvent) => setFieldValue('recurring', changeEvent.target.checked)}
              />
              <CustomInputCheckbox
                isInvalid={!!errors.overflowsToWorkdaysIfWeekend}
                error={errors.overflowsToWorkdaysIfWeekend}
                id='overflowsToWorkdaysIfWeekend'
                label={t('overflowsToWorkDaysIfWeekend')}
                isChecked={values.overflowsToWorkdaysIfWeekend}
                onChange={(changeEvent) =>
                  setFieldValue('overflowsToWorkdaysIfWeekend', changeEvent.target.checked)
                }
              />
            </Form>
          )}
        </Formik>
      </SadStates>
    </CustomModal>
  );
};
