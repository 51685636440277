import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  BoxProps,
  Button,
  CloseButton,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spinner,
  Text as ChakraText,
  VStack
} from '@chakra-ui/react';
import i18next from 'i18next';

import { Restricted } from '@/components';
import { UserContext } from '@/contexts';
import { allowedPathsByPermission } from '@/helpers';
import { themeConfiguration } from '@/resources';
import { ReactComponent as FlagEN } from '@/resources/img/flagEN.svg';
import { ReactComponent as FlagRS } from '@/resources/img/flagRS.svg';
import { ReactComponent as LeawieLogo } from '@/resources/img/leawie-logo.svg';
import { ReactComponent as Leawie } from '@/resources/img/leawie-white.svg';

interface SidebarProps extends BoxProps {
  onClose: () => void;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
}

export const Sidebar = ({ onClose, setTitle, ...rest }: SidebarProps) => {
  const [t] = useTranslation('common');
  const { permissions } = useContext(UserContext);
  const navigate = useNavigate();

  const handleLanguageChange = (language: string) => {
    i18next.changeLanguage(language);
    localStorage.setItem('language', JSON.stringify(language));
  };

  return (
    <Box
      transition='3s ease'
      w={{ base: 'full', md: 60 }}
      pos='fixed'
      h='full'
      bgGradient={`${themeConfiguration.styles.body}`}
      borderRight='1px'
      borderColor='brand.500'
      {...rest}>
      <Flex h='16' bg='brand.600' width='full' alignItems='center'>
        <LeawieLogo
          style={{ height: '80%', width: '100%', marginLeft: '-0.3rem' }}
          fill={`${themeConfiguration.colors.brand[500]}`}
        />
        <Leawie
          style={{ height: '90%', width: '100%', marginLeft: '-2rem', marginRight: '2rem' }}
        />
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      <Flex alignItems='center' mt='2em' justifyContent='space-between' />
      <Restricted to={permissions} loadingComponent={<Spinner color='brand.500' size='xl' />}>
        <VStack mx='5%'>
          {allowedPathsByPermission(permissions)?.map(
            (permissionPath: { label: string; path: string }, index: number) => (
              <Button
                w='100%'
                textAlign='center'
                key={index}
                onClick={() => {
                  navigate(permissionPath.path);
                  setTitle(permissionPath.label);
                }}>
                {t(`${permissionPath.label}`)}
              </Button>
            )
          )}
        </VStack>
      </Restricted>
      <Flex
        style={{
          position: 'absolute',
          alignItems: 'center',
          bottom: '1rem',
          display: 'flex',
          justifyContent: 'center',
          marginInlineEnd: '4rem',
          marginInlineStart: '4rem'
        }}>
        <Menu arrowPadding={8} autoSelect={false}>
          <MenuButton as={Button} m='0' w='6.5rem'>
            <Box display='flex' alignItems='center' justifyContent='center'>
              {i18next.resolvedLanguage === 'en' ? <FlagEN /> : <FlagRS />}
              {i18next.resolvedLanguage.toLocaleUpperCase()}
            </Box>
          </MenuButton>
          <MenuList
            alignItems='center'
            bg='brand.500'
            p={0}
            minW='0'
            w='6.5rem'
            borderColor='brand.500'>
            <MenuOptionGroup
              defaultValue={i18next.resolvedLanguage === 'en' ? 'en' : 'rs'}
              type='radio'
              width='full'>
              <MenuItemOption
                iconSpacing={1}
                value={'en'}
                onClick={() => handleLanguageChange('en')}
                _hover={{ background: 'brand.50', color: 'black' }}>
                <Box display='flex' w='100%' h='100%' alignItems='center' justifyContent='center'>
                  <FlagEN />
                  <ChakraText>{t('en')}</ChakraText>
                </Box>
              </MenuItemOption>
              <MenuItemOption
                iconSpacing={1}
                value={'rs'}
                onClick={() => handleLanguageChange('rs')}
                _hover={{ background: 'brand.50', color: 'black' }}>
                <Box display='flex' w='100%' h='100%' alignItems='center' justifyContent='center'>
                  <FlagRS />
                  <ChakraText>{t('rs')}</ChakraText>
                </Box>
              </MenuItemOption>
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </Flex>
    </Box>
  );
};
