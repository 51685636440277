import axios from 'axios';
import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';

import { apiUrl } from '@/helpers';
import { ChangeUserProfile, Options } from '@/types';

const ADMIN_API_URL = '/v1/Users';

export const ADMIN_QUERY_KEY = 'admin-user';

export const adminApi = {
  list: (pageSize: number, currentPage: number, orderBy?: string, orderByDesc?: boolean, includeHolidays = false, search?: string) =>
    axios.get(
      `${apiUrl()}${ADMIN_API_URL}?pageSize=${pageSize}&currentPage=${currentPage}&orderBy=${orderBy}&orderByDesc=${orderByDesc}&includeHolidays=${includeHolidays}&search=${search}`
    ),
  listUsers: (pageSize: number, currentPage: number) =>
    axios.get(
      `${apiUrl()}${ADMIN_API_URL}?pageSize=${pageSize}&currentPage=${currentPage}`
    ),
  changeUserDetails: ({payload} : {payload: ChangeUserProfile}) =>
    axios.put(`${apiUrl()}${ADMIN_API_URL}/${payload.id}`, payload)
};

export const useGetAllUsersAdminQuery = (
  pageSize: number,
  currentPage: number,
  orderBy?: string,
  orderByDesc?: boolean,
  options?: Options,
  includeHolidays?: boolean,
  search?: string
) => {
  return useQuery(
    [ADMIN_QUERY_KEY, { pageSize, currentPage, orderBy, orderByDesc, includeHolidays, search }],
    () => adminApi.list(pageSize, currentPage, orderBy, orderByDesc, includeHolidays, search),
    {
      enabled: options?.enabled,
      keepPreviousData: true,
      onSuccess: (response) => {
        options?.onSuccess(response);
      },
      onError: (error) => {
        options?.onError(error);
      }
    }
  );
};

export const changeUserDetails = (queryClient: QueryClient, options: Options) => {
  return useMutation(adminApi.changeUserDetails, {
    onSuccess: (response) => {
      options.onSuccess(response);
      queryClient.invalidateQueries([ADMIN_QUERY_KEY]);
    },
    onError: (error) => {
      options.onError(error);
    }
  });
};

export const useGetAllUsersAdminSelectQuery = (
  pageSize: number,
  currentPage: number,
  options?: Options
) => {
  return useQuery(
    [ADMIN_QUERY_KEY, { pageSize, currentPage }],
    () => adminApi.listUsers(pageSize, currentPage),
    {
      enabled: options?.enabled,
      keepPreviousData: true,
      onError: (error) => {
        options?.onError(error);
      }
    }
  );
};
