import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text as ChakraText
} from '@chakra-ui/react';

type AbsenceTypeLegendType = {
  id: number;
  label: string;
  backgroundColor: string;
};

const absenceTypeLegend = [
  {
    id: 1,
    label: 'ANNUALLEAVE',
    backgroundColor: 'lightblue'
  },
  {
    id: 2,
    label: 'SICKLEAVE',
    backgroundColor: '#008B8B'
  },
  {
    id: 3,
    label: 'UNPAIDLEAVE',
    backgroundColor: '#EC87C0'
  },
  {
    id: 4,
    label: 'FAMILYSPECIALEVENTLEAVE',
    backgroundColor: '#ff9085'
  },
  {
    id: 5,
    label: 'BLOODDONATIONLEAVE',
    backgroundColor: '#DC143C'
  },
  {
    id: 6,
    label: 'OTHERLEAVE',
    backgroundColor: '#f0c33c'
  },
  {
    id: 7,
    label: 'religiousHolidays',
    backgroundColor: '#BA55D3'
  },
  {
    id: 8,
    label: 'publicHolidays',
    backgroundColor: '#4169E1'
  }
];

export const CalendarLegend = () => {
  const [t] = useTranslation('common');

  return (
    <Popover trigger='hover'>
      <PopoverTrigger>
        <Button>{t('legend')}</Button>
      </PopoverTrigger>
      <PopoverContent zIndex={3} position='relative' right='10%'>
        <PopoverArrow />
        <PopoverBody>
          {absenceTypeLegend.map((item: AbsenceTypeLegendType) => (
            <Box display='flex' alignItems='center' key={item.id} position='relative'>
              <ChakraText
                as='span'
                style={{
                  height: '15px',
                  width: '15px',
                  display: 'inline-block',
                  borderRadius: '50%',
                  backgroundColor: item.backgroundColor
                }}
              />
              <ChakraText marginLeft='10px'>{t(item.label)}</ChakraText>
            </Box>
          ))}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
