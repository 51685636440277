import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputProps } from '@chakra-ui/react';
import { Field } from 'formik';

export interface CustomInputProps extends InputProps {
  id: string;
  label?: string;
  error?: string;
  disabled?: boolean;
}

export const CustomInput = (props: CustomInputProps) => {
  return (
    <FormControl isInvalid={props.isInvalid}>
      <FormLabel mr='0' mt='2' htmlFor={props.id}>
        {props.label}
        <Field as={props.as ?? Input} {...props} name={props.id} autoComplete='on' borderColor='brand.400'/>
      </FormLabel>
      <FormErrorMessage mt='-1' mb='1'>
        {props.error}
      </FormErrorMessage>
    </FormControl>
  );
};
