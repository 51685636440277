import { useTranslation } from 'react-i18next';

import { Box, Text as ChakraText } from '@chakra-ui/react';
import moment from 'moment';

export const CustomCalendarToolbarYearView = ({ date }: { date: Date }) => {
  const currentDate = new Date(date);
  const [t] = useTranslation('common');
  const monthName = moment(currentDate).format('MMMM');

  return (
    <Box display='flex' justifyContent='center'>
      <ChakraText as='span' color='brand.600' fontSize='20px' fontWeight='bold'>
        {`${t(monthName)}, ${currentDate.getFullYear()}`}
      </ChakraText>
    </Box>
  );
};
