import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { CalendarIcon } from '@chakra-ui/icons';
import { Box, FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { Field, useField } from 'formik';

import { dateFormat } from '@/helpers';
import { themeConfiguration } from '@/resources';

export interface CustomDateInputProps {
  id: string;
  label: string;
  error?: string;
  isInvalid?: boolean;
  min?: Date | null;
  max?: Date | null;
  filterDate?: (date: Date) => boolean;
  onChange: (date: Date | null) => void;
}

export const CustomDateInput = (props: CustomDateInputProps) => {
  const { id, label, isInvalid, error } = props;

  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel mr='0' mt='1' htmlFor={id}>
        {label}
        <Box
          position='relative'
          borderRadius='md'
          border={`1px solid ${themeConfiguration.colors.brand[300]}`}>
          <label
            className={
              localStorage.getItem('chakra-ui-color-mode') === 'dark'
                ? 'dateColorDark'
                : 'dateColorLight'
            }
            onClick={(e) => e.preventDefault()}>
            <Field as={CustomDatePicker} {...props} name={id} />
            <CalendarIcon
              width='5%'
              position='absolute'
              right='16px'
              top='12px'
              cursor='pointer'
              onClick={(e) => e.stopPropagation()}
            />
          </label>
        </Box>
      </FormLabel>
      <FormErrorMessage mt='-1' mb='1'>
        {error}
      </FormErrorMessage>
    </FormControl>
  );
};

const CustomDatePicker = (props: CustomDateInputProps) => {
  const [field] = useField(props.id);

  return (
    <DatePicker
      {...field}
      id={props.id}
      selected={field.value}
      dateFormat={dateFormat}
      minDate={props.min}
      maxDate={props.max}
      filterDate={props.filterDate}
      onChange={props.onChange}
      autoFocus={false}
      autoComplete='off'
      className={`date ${props.isInvalid ? 'invalid' : 'valid'}`}
      popperPlacement='bottom-end'
      popperModifiers={[
        {
          name: 'arrow',
          options: {
            padding: 210
          }
        },
        {
          name: 'offset',
          options: {
            offset: [1, 1]
          }
        },
        {
          name: 'preventOverflow',
          options: {
            tether: false
          }
        }
      ]}
    />
  );
};
