import React, { useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import { ArrowDownIcon, ArrowUpIcon, DragHandleIcon, QuestionOutlineIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, HStack, Text as ChakraText, Th } from '@chakra-ui/react';
import { Column, flexRender, Header, Table as ReactTable } from '@tanstack/react-table';

import { TableData } from '@/types';

export const ColumnHeader = ({
  table,
  header,
  dragAndDropLocalStorageKey
}: {
  table: ReactTable<TableData>;
  header: Header<TableData, unknown>;
  dragAndDropLocalStorageKey: string;
}) => {
  const { getState, setColumnOrder } = table;
  const { columnOrder } = getState();
  const { column } = header;
  const meta = column.columnDef.meta;
  const [t] = useTranslation('common');
  let icon;

  switch (column.getIsSorted()) {
    case 'asc':
      icon = <ArrowUpIcon w={4} h={4} />;
      break;
    case 'desc':
      icon = <ArrowDownIcon w={4} h={4} />;
      break;
    default:
      icon = null;
      break;
  }

  const [, dropRef] = useDrop({
    accept: 'column',
    drop: (draggedColumn: Column<TableData>) => {
      const newColumnOrder = [...columnOrder];
      const draggedColumnId = newColumnOrder.indexOf(draggedColumn.id);
      const targetColumnId = newColumnOrder.indexOf(column.id);
      newColumnOrder.splice(targetColumnId, 0, newColumnOrder.splice(draggedColumnId, 1)[0]);
      setColumnOrder(newColumnOrder);
    }
  });

  useEffect(() => {
    localStorage.setItem(dragAndDropLocalStorageKey, JSON.stringify(columnOrder));
  }, [columnOrder]);

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    canDrag: table.getVisibleFlatColumns().length > 1,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    item: { id: column.id },
    type: 'column'
  });

  return (
    <Th
      ref={dropRef}
      colSpan={header.colSpan}
      bg='brand.600'
      fontSize='16px'
      color='white'
      style={{
        opacity: isDragging ? 0.5 : 1
      }}>
      <Flex w='full' justify='space-between'>
        <HStack ref={previewRef}>
          <Box style={{ cursor: 'grab' }} ref={dragRef}>
            <DragHandleIcon w={4} h={4} />
          </Box>
          <React.Fragment key={header.id}>
            <Box
              as={Button}
              variant='unstyled'
              onClick={column.getToggleSortingHandler()}
              style={{ cursor: column.getCanSort() ? 'pointer' : 'default' }}>
              {header.isPlaceholder ? null : meta?.tooltip ? (
                <HStack>
                  <ChakraText>
                    {flexRender(column.columnDef.header, header.getContext())}
                  </ChakraText>
                  <ChakraText title={t(meta?.tooltip)}>
                    <QuestionOutlineIcon />
                  </ChakraText>
                </HStack>
              ) : (
                flexRender(column.columnDef.header, header.getContext())
              )}
            </Box>
          </React.Fragment>
          {icon}
        </HStack>
      </Flex>
    </Th>
  );
};
