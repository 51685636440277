type SadStatesProps = {
    states: {
      when: boolean | (() => boolean);
      render: () => JSX.Element;
    }[];
    children: (() => JSX.Element) | JSX.Element;
  };

export const SadStates = ({ states, children }: SadStatesProps) => {
  const sadState = states.find((c) => (typeof c.when === 'function' ? c.when() : c.when));

  if (sadState) {
    return sadState.render();
  }

  return typeof children === 'function' ? children() : children;
};
