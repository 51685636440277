import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

type CalendarHeaderProps = {
  date: Date;
  isWeekView?: boolean;
};

export const CustomCalendarHeader = ({ date, isWeekView }: CalendarHeaderProps) => {
  const [t] = useTranslation('common');
  const dayName = moment(date).format('ddd');
  const formatedDate = moment(date).format('DD');
  const isWeekend = dayName === 'Sat' || dayName === 'Sun';

  return (
    <Box
      backgroundColor={isWeekend ? (localStorage.getItem('chakra-ui-color-mode') === 'dark' ? 'gray.500' :'brand.150') : 'brand.600'}
      color={isWeekend ? (localStorage.getItem('chakra-ui-color-mode') === 'dark' ? 'white' : 'black') : 'white'}
      padding='10px'>
      {isWeekView && formatedDate} {t(dayName).slice(0, 3)}
    </Box>
  );
};
