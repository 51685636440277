import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Center,
  Image as ChakraImage,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
  VStack
} from '@chakra-ui/react';

import { UserContext } from '@/contexts';
import { themeConfiguration } from '@/resources';
import { HolidaysToShow, User } from '@/types';

type CurrentUserProps = {
  currentUser: User;
  refetch: () => void;
};

export const UserDetails = () => {
  const { currentUser, refetch }: CurrentUserProps = useContext(UserContext);
  const [t] = useTranslation('common');

  const religiousHolidayNames = currentUser?.religiousHolidays.map((holiday: HolidaysToShow) => ({
    value: holiday.id,
    label: holiday.name
  }));

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '90vh',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <VStack
        p='3%'
        w='600px'
        borderRadius='lg'
        boxShadow='2xl'
        backgroundColor={`${themeConfiguration.styles.profile}`}>
        <Box>
          <Center>
            <ChakraImage
              borderRadius='lg'
              mb='1.5rem'
              src='gibbresh.png'
              fallbackSrc='https://via.placeholder.com/150'
            />
          </Center>
          <TableContainer overflowY='auto' h='full' borderRadius='lg'>
            <Table fontSize='18' variant='striped' colorScheme='gray' borderRadius='lg'>
              <Tbody bg='white' whiteSpace='break-spaces'>
                <Tr>
                  <Td fontWeight='bold'>{t('firstNameLastName')}</Td>
                  <Td>{`${currentUser?.firstName}${' '}${currentUser?.lastName}`}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight='bold'>{t('email')}</Td>
                  <Td>{currentUser?.email}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight='bold'>{t('referenceNumber')}</Td>
                  <Td>{currentUser?.referenceNumber}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight='bold'>{t('religiousHolidays')}</Td>
                  <Td>
                    {religiousHolidayNames?.length === 0
                      ? ''
                      : `${religiousHolidayNames?.map(
                          (holidayName) => `${' '}${holidayName.label}`
                        )}`}
                  </Td>
                </Tr>
                <Tr>
                  <Td fontWeight='bold'>{t('remainingAndTotalAnnualLeaveDays')}</Td>
                  <Td>{`${currentUser?.remainingAnnualLeaveDays}/${currentUser?.annualLeaveDays}`}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight='bold'>{t('remainingAndTotalSickLeaveDays')}</Td>
                  <Td>{`${currentUser?.remainingSickLeaveDays}/${currentUser?.sickLeaveDays}`}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </VStack>
    </div>
  );
};
