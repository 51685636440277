import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Text as Info, VStack } from '@chakra-ui/react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { CustomInput } from '@/components';
import { UserContext } from '@/contexts';
import { handleFirebaseErrors, useErrorToast } from '@/helpers';
import { themeConfiguration } from '@/resources';
import { ReactComponent as Logo } from '@/resources/img/leawie.svg';
import { LoginForm } from '@/types';

export const LoginPage = () => {
  const [t] = useTranslation('common');
  const navigate = useNavigate();
  const { login } = useContext(UserContext);
  const initialValues: LoginForm = { email: '', password: '' };
  const errorToast = useErrorToast();

  const loginSchema = Yup.object().shape({
    password: Yup.string().required(t('passRequired')),
    email: Yup.string().email(t('emailInvalid')).required(t('emailRequired'))
  });

  const handleSubmit = async (values: LoginForm, { setSubmitting }: FormikHelpers<LoginForm>) => {
    setSubmitting(true);
    login(values.email, values.password)?.catch((res) => {
      errorToast(handleFirebaseErrors(res.message));
    });
  };

  return (
    <VStack
      style={{
        display: 'flex',
        width: '100%',
        height: '90vh',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <Box width='500px' textAlign='center' height='230px'>
        <Logo />
      </Box>
      <Formik
        validateOnMount
        validateOnChange
        initialValues={initialValues}
        validationSchema={loginSchema}
        onSubmit={handleSubmit}>
        {({ isSubmitting, isValid, errors, touched }) => (
          <Form>
            <VStack p='10%' w='500px' bg={`${themeConfiguration.styles.popover}`} borderRadius='lg' boxShadow='lg'>
              <CustomInput
                type='email'
                maxLength={50}
                isInvalid={!!errors.email && touched.email}
                id='email'
                label={t('email')}
                error={errors.email}
              />
              <CustomInput
                type='password'
                maxLength={50}
                isInvalid={!!errors.password && touched.password}
                id='password'
                label={t('password')}
                error={errors.password}
              />
              <Info
                position='relative'
                width='100%'
                fontWeight={500}
                _hover={{ cursor: 'pointer' }}
                onClick={() => navigate('/forgotPassword')}>
                {t('forgotPassword')}
              </Info>
              <Button
                type='submit'
                minW='150px'
                size='lg'
                top='15px'
                disabled={isSubmitting || !isValid}>
                {t('login')}
              </Button>
            </VStack>
          </Form>
        )}
      </Formik>
    </VStack>
  );
};
