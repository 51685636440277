import { Dispatch, SetStateAction, useEffect } from 'react';
import { NavigateAction, View } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button, Text as ChakraText } from '@chakra-ui/react';
import moment from 'moment';

import { CustomView } from '@/types';

type CustomCalendarToolbarProps = {
  view: CustomView;
  yearViewLabel?: number | string;
  handleView: (view: View) => void;
  handleOnNavigate?: (navigate: NavigateAction, date?: Date | undefined) => void;
  increaseCurrentYear?: () => void;
  reduceCurrentYear?: () => void;
  handleYearView?: () => void;
  toolbarDate?: Date;
  setYear: Dispatch<SetStateAction<number>>;
};

export const CustomCalendarToolbar = ({
  view,
  yearViewLabel,
  handleView,
  handleOnNavigate,
  increaseCurrentYear,
  reduceCurrentYear,
  handleYearView,
  toolbarDate,
  setYear
}: CustomCalendarToolbarProps) => {
  const [t] = useTranslation('common');
  const handleNext = () => {
    view === 'year' ? increaseCurrentYear?.() : handleOnNavigate?.('NEXT');
  };

  const handlePrev = () => {
    view === 'year' ? reduceCurrentYear?.() : handleOnNavigate?.('PREV');
  };
  const weekDays = `${moment(toolbarDate).startOf('week').format('DD')}-${moment(toolbarDate)
    .endOf('week')
    .format('DD')}`;
  const monthName = moment(toolbarDate).format('MMMM');

  useEffect(() => {
    if (toolbarDate) {
      setYear(toolbarDate.getFullYear());
    }
  }, [toolbarDate]);

  return (
    <Box className='rbc-toolbar' display='flex' justifyContent='space-between'>
      <Box display='flex' alignItems='center' justifyContent='center'>
        <Button
          onClick={handlePrev}
          h='35px'
          w='50px'
          className={
            localStorage.getItem('chakra-ui-color-mode') === 'dark'
              ? 'fontColorDark'
              : 'fontColorLight'
          }>
          <ChevronLeftIcon display='flex' alignItems='center' />
        </Button>
        <Button
          onClick={handleNext}
          h='35px'
          w='50px'
          className={
            localStorage.getItem('chakra-ui-color-mode') === 'dark'
              ? 'fontColorDark'
              : 'fontColorLight'
          }>
          <ChevronRightIcon display='flex' alignItems='center' />
        </Button>
      </Box>
      <ChakraText as='span' className='rbc-toolbar-label'>
        {view === 'week'
          ? `${t(monthName)} ${weekDays}`
          : view === 'year'
          ? yearViewLabel
          : `${t(monthName)}, ${toolbarDate?.getFullYear()}`}
      </ChakraText>
      <ChakraText as='span'>
        <Button
          className={
            localStorage.getItem('chakra-ui-color-mode') === 'dark'
              ? 'fontColorDark'
              : 'fontColorLight'
          }
          onClick={() => handleView('month')}>
          {t('month')}
        </Button>
        <Button
          className={
            localStorage.getItem('chakra-ui-color-mode') === 'dark'
              ? 'fontColorDark'
              : 'fontColorLight'
          }
          onClick={() => handleView('week')}>
          {t('week')}
        </Button>
        <Button
          className={
            localStorage.getItem('chakra-ui-color-mode') === 'dark'
              ? 'fontColorDark'
              : 'fontColorLight'
          }
          onClick={handleYearView}>
          {t('year')}
        </Button>
      </ChakraText>
    </Box>
  );
};
