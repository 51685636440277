import axios from 'axios';

import { apiUrl } from '@/helpers';
import { QueryClient, useMutation } from '@tanstack/react-query';

import { Options, SendExportProps } from '@/types';

const EXPORT_API_URL = '/v1/Export';

const EXPORT_QUERY_KEY = 'export';

export const exportApi = {
  export_worklog: (payload: SendExportProps) => axios.post(`${apiUrl()}${EXPORT_API_URL}/export_worklog/`, payload)
};

export const useExportWorklogMutation = (queryClient: QueryClient, options: Options) => {
  return useMutation(exportApi.export_worklog, {
    onSuccess: (response) => {
      options.onSuccess(response);
      queryClient.invalidateQueries([EXPORT_QUERY_KEY]);
    },
    onError: (error) => {
      options.onError(error);
    }
  });
};
