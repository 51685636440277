import { useTranslation } from 'react-i18next';
import { Center, Spinner, Text as Info, VStack } from '@chakra-ui/react';

import { themeConfiguration } from '@/resources';

export const PageLoadingState = () => {
  const [t] = useTranslation('common');
  return (
    <Center h='100vh' color={`${themeConfiguration.styles.spinner}`}>
      <VStack>
        <Spinner size='xl' />
        <Info>{t('loading')}</Info>
      </VStack>
    </Center>
  );
};
