import { Dispatch, SetStateAction } from 'react';
import { Calendar, SlotInfo, View, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { Box } from '@chakra-ui/react';
import moment from 'moment';

import {
  CustomCalendarHeaderForYearView,
  CustomCalendarToolbar,
  CustomCalendarToolbarYearView,
  HolidaysTooltip
} from '@/components';
import { MonthHelper, formatDaysStyles } from '@/helpers';
import { CalendarEventProps, CustomView, TableData } from '@/types';

type CustomYearViewProps = {
  calendarEvents: CalendarEventProps[];
  view: CustomView;
  handleView: (newView: CustomView | View) => void;
  eventsStyles: (event: CalendarEventProps) => { style: React.CSSProperties };
  isMyAbsencesAdminView?: boolean;
  year: number;
  setYear: Dispatch<SetStateAction<number>>;
  handleCreate: (slot: SlotInfo) => void;
  handleEdit?: (id: CalendarEventProps | TableData) => void;
};

export const CustomYearView = ({
  calendarEvents,
  view,
  handleView,
  eventsStyles,
  isMyAbsencesAdminView,
  year,
  setYear,
  handleCreate,
  handleEdit
}: CustomYearViewProps) => {
  const localizer = momentLocalizer(moment);
  const months = MonthHelper(year);

  const formatedMonths = months.map((month) => {
    const filteredEvents = calendarEvents?.filter((calendarEvent) => {
      const eventStarts = moment(calendarEvent.start).format('yyyy-MM');
      const eventEnds = moment(calendarEvent.end).format('yyyy-MM');
      const monthStarts = moment(month.min).format('yyyy-MM');
      const monthEnds = moment(month.max).format('yyyy-MM');
      return eventStarts === monthStarts || eventEnds === monthEnds;
    });
    if (filteredEvents?.length > 0) {
      const events = filteredEvents.map((filteredEvent) => {
        return {
          id: filteredEvent.id || 0,
          start: new Date(moment(filteredEvent.start).format('yyyy-MM-DD')),
          end: new Date(moment(filteredEvent.end).format('yyyy-MM-DD')),
          approvalStatus: filteredEvent.approvalStatus,
          title: !filteredEvent.isAbsence && (
            <HolidaysTooltip label={String(filteredEvent.title)} />
          ),
          type: filteredEvent.type
        };
      });
      return { ...month, events };
    }
    return month;
  });

  const increaseCurrentYear = () => {
    setYear(year + 1);
  };

  const reduceCurrentYear = () => {
    setYear(year - 1);
  };

  return (
    <Box>
      <Box display='flex' flexDirection='column' justifyContent='center'>
        <CustomCalendarToolbar
          handleView={handleView}
          yearViewLabel={year}
          view={view}
          increaseCurrentYear={increaseCurrentYear}
          reduceCurrentYear={reduceCurrentYear}
          setYear={setYear}
        />
        <Box
          border='1px solid lightGray'
          padding='5px 15px'
          m='5px 0'
          h={isMyAbsencesAdminView ? '655' : '688'}
          overflowY='auto'>
          <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
            {months.map((month) => (
              <Box key={month.id} w='22%' h='300px'>
                <Calendar
                  key={month.id}
                  selectable
                  events={
                    formatedMonths.find((formatedMonth) => formatedMonth.id === month.id)?.events ||
                    []
                  }
                  localizer={localizer}
                  startAccessor='start'
                  endAccessor='end'
                  views={['month']}
                  drilldownView={'week'}
                  min={month.min}
                  max={month.max}
                  defaultDate={month.min}
                  onSelectSlot={handleCreate}
                  onSelectEvent={(e) => handleEdit && handleEdit(e)}
                  eventPropGetter={eventsStyles}
                  dayPropGetter={formatDaysStyles}
                  components={{
                    toolbar: ({ date }) => (
                      <CustomCalendarToolbarYearView date={new Date(date.setFullYear(year))} />
                    ),
                    header: CustomCalendarHeaderForYearView
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
