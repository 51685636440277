import i18n from 'i18next';

import { themeConfiguration } from '@/resources';

export const getHolidayColumns = () => [
  {
    id: 'name',
    accessorKey: 'name',
    header: i18n.t('name', { ns: 'common' })
  },
  {
    id: 'startsAt',
    accessorKey: 'startsAt',
    header: i18n.t('startsAt', { ns: 'common' })
  },
  {
    id: 'overflowsToWorkDaysIfWeekend',
    accessorKey: 'overflowsToWorkdaysIfWeekend',
    header: i18n.t('overflows', { ns: 'common' }),
    meta: {
      tooltip: i18n.t('overflowsToWorkDaysIfWeekend')
    },
    enableSorting: false
  },
  {
    id: 'totalDaysCelebrated',
    accessorKey: 'totalDaysCelebrated',
    header: i18n.t('totalDaysCelebrated', { ns: 'common' })
  },
  {
    id: 'recurring',
    accessorKey: 'recurring',
    header: i18n.t('recurring', { ns: 'common' }),
    enableSorting: false
  }
];

export const formatEventsStyles = (view: string) => ({
  style: {
    backgroundColor: themeConfiguration.colors.brand[600],
    borderRadius: '10px',
    border: '0px',
    height: view === 'year' ? '12px' : '35px',
    marginTop: view === 'week' ? '20px' : 0
  }
});
