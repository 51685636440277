import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionMeta, MultiValue, SingleValue } from 'react-select';

import { Button, Flex, FormControl, FormLabel, Spinner, VStack } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { format, lastDayOfMonth } from 'date-fns';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { CustomDateInput, CustomNumberInput, CustomSelect } from '@/components';
import { UserContext } from '@/contexts';
import { PERMISSION_TYPE } from '@/enums';
import { downloadFile, handleError, useErrorToast, useSuccessToast } from '@/helpers';
import { useExportWorklogMutation, useGetAllUsersAdminSelectQuery } from '@/services';
import { ExportProps, SelectOption, SendExportProps, UsersToShow } from '@/types';

export const Worklog = () => {
  const [t] = useTranslation('common');
  const { permissions } = useContext(UserContext);
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();
  const today = new Date();
  const firstDateOfMonth = format(today, 'yyyy-MM-01');
  const lastDateOfMonth = format(lastDayOfMonth(today), 'yyyy-MM-dd');
  const queryClient = useQueryClient();

  const { data: allUsersData, isLoading: isLoadingUsers } = useGetAllUsersAdminSelectQuery(100, 1, {
    enabled: permissions.includes(PERMISSION_TYPE.VIEWUSERS),
    onSuccess: () => void 0,
    onError: (error) => {
      errorToast(handleError(error));
    }
  });

  const { mutate: exportWorklog } = useExportWorklogMutation(queryClient, {
    onSuccess: (res) => {
      downloadFile(res);
      successToast({ title: t('successfulExport') });
    },
    onError: () => {
      errorToast({ title: t('unsuccessfulExport') });
    }
  });

  const handleUserChange = (
    newValue: MultiValue<SelectOption>,
    action: ActionMeta<SelectOption>,
    setFieldValue: (
      field: string,
      value: MultiValue<SelectOption> | SingleValue<SelectOption> | undefined,
      shouldValidate?: boolean
    ) => void
  ) => {
    const { action: actionType } = action;
    if (actionType === 'clear') {
      setFieldValue('users', undefined);
      return;
    }

    if (actionType === 'remove-value' && newValue.length === 0) {
      setFieldValue('users', undefined);
      return;
    }

    setFieldValue('users', newValue, true);
  };

  const userSchema = Yup.object().shape({
    id: Yup.number(),
    firstName: Yup.string(),
    lastName: Yup.string()
  });

  const exportSchema = Yup.object().shape({
    users: Yup.array().of(userSchema).required(t('userIdRequired')),
    startsAt: Yup.date()
      .required(t('startsAtRequired'))
      .nullable()
      .transform((v) => (v instanceof Date ? v : null)),
    endsAt: Yup.date()
      .required(t('endsAtRequired'))
      .nullable()
      .transform((v) => (v instanceof Date ? v : null))
      .min(Yup.ref('startsAt'), t('endsAtDateBeforeStartsAtError')),
    countHours: Yup.number()
      .required(t('countHoursRequired'))
      .min(1, t('countHoursMin'))
      .max(16, t('countHoursMax'))
  });

  const getInitialValues = (): ExportProps => {
    return {
      users: undefined,
      startsAt: new Date(firstDateOfMonth),
      endsAt: new Date(lastDateOfMonth),
      countHours: 8
    };
  };

  const handleSubmit = (values: ExportProps) => {
    const payload: SendExportProps = {
      usersIds: values?.users?.map((userId) => userId.value),
      startsAt: values.startsAt,
      endsAt: values.endsAt,
      countHours: values.countHours
    };
    exportWorklog(payload);
  };

  return (
    <Flex
      style={{
        display: 'flex',
        width: '100%',
        height: '90vh',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <VStack p='3%' w='600px' borderRadius='lg' boxShadow='2xl' backgroundColor='white'>
        <Formik
          initialValues={getInitialValues()}
          validationSchema={exportSchema}
          onSubmit={handleSubmit}>
          {({ errors, setFieldValue, isValid }) => (
            <Form noValidate style={{ width: '100%', height: '29rem' }}>
              <FormControl>
                <FormLabel fontSize='16pt' mb='5%'>
                  {t('worklogTitle')}
                </FormLabel>
              </FormControl>
              <CustomSelect
                id='users'
                label={t('users')}
                isMulti={true}
                placeholder={t('user')}
                handleChange={(newValue, action) =>
                  handleUserChange(newValue as MultiValue<SelectOption>, action, setFieldValue)
                }
                options={
                  !isLoadingUsers && allUsersData?.data ? (
                    allUsersData?.data?.items.map((users: UsersToShow) => {
                      return { value: users.id, label: `${users?.firstName} ${users?.lastName}` };
                    })
                  ) : (
                    <Spinner />
                  )
                }
                error={errors.users}
                isInvalid={!!errors.users}
              />
              <CustomDateInput
                id='startsAt'
                label={t('startDate')}
                onChange={(date: Date | null) => setFieldValue('startsAt', date)}
                error={errors.startsAt}
                isInvalid={!!errors.startsAt}
              />
              <CustomDateInput
                id='endsAt'
                label={t('endsAt')}
                onChange={(date: Date | null) => setFieldValue('endsAt', date)}
                error={errors.endsAt}
                isInvalid={!!errors.endsAt}
              />
              <CustomNumberInput
                focusBorderColor='brand.500'
                id='countHours'
                label={t('countHours')}
                isInvalid={!!errors.countHours}
                min={1}
                max={16}
                error={errors.countHours}
                onChange={(changeEvent: string) => setFieldValue('countHours', changeEvent)}
              />
              <Button type='submit' minW='100px' disabled={!isValid} size='md' mr='5%' mt='5%'>
                {t('export')}
              </Button>
            </Form>
          )}
        </Formik>
      </VStack>
    </Flex>
  );
};
