import { useContext } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { Layout } from '@/components';
import { PermissionProvider, UserContext } from '@/contexts';
import { PERMISSION_TYPE } from '@/enums';
import {
  Absences,
  ConfirmPassword,
  ForgotPassword,
  LoginPage,
  MyAbsences,
  ProtectedRoute,
  PublicHolidays,
  ReligiousHolidays,
  UserDetails,
  UsersListAdminView,
  Worklog
} from '@/pages';

export const App = () => {
  const { currentUser, permissions } = useContext(UserContext);

  const fetchPermissions = () => async (permission: number[]): Promise<boolean> => {
    await new Promise(resolve => setTimeout(resolve, 100));
    return permission.every(allowedPermission => permissions.includes(allowedPermission));
  };

  let routes = null;
  if (currentUser) {
    routes = (
      <Layout>
        <Routes>
          <Route element={<ProtectedRoute allowedPermissions={[PERMISSION_TYPE.VIEWUSERS, PERMISSION_TYPE.VIEWRELIGIOUSHOLIDAYS, PERMISSION_TYPE.VIEWWORKLOG]} />}>
            <Route path='/leave' element={<Absences />} />
            <Route path='/usersListAdmin' element={<UsersListAdminView />} />
            <Route path='/religiousHoliday' element={<ReligiousHolidays />} />
            <Route path='/worklog' element={<Worklog />} />
          </Route>
          <Route element={<ProtectedRoute allowedPermissions={[PERMISSION_TYPE.VIEWABSENCES, PERMISSION_TYPE.VIEWPUBLICHOLIDAYS, PERMISSION_TYPE.GETCURRENTUSER]} />}>
            <Route path='/myLeave' element={<MyAbsences />} />
            <Route path='/publicHolidays' element={<PublicHolidays />} />
            <Route path='/myProfile' element={<UserDetails />} />
          </Route>
          <Route path='*' element={permissions.includes(PERMISSION_TYPE.VIEWRELIGIOUSHOLIDAYS) ? <Navigate to='/leave' replace /> : <Navigate to='/myLeave' replace />} />
        </Routes>
      </Layout>
    );
  } else {
    routes = (
      <Routes>
        <Route path='/login' element={<LoginPage />} />
        <Route path='/continueRegistration' element={<ConfirmPassword />} />
        <Route path='/forgotPassword' element={<ForgotPassword />} />
        <Route path='/changePassword' element={<ConfirmPassword />} />
        <Route path='*' element={<Navigate to='/login' replace />} />
      </Routes>
    );
  }
  return <PermissionProvider fetchPermissions={fetchPermissions()}><BrowserRouter>{routes}</BrowserRouter></PermissionProvider>;
};
