import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormControl, FormErrorMessage, VStack } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useQueryClient } from '@tanstack/react-query';
import * as Yup from 'yup';

import { CustomInput } from '@/components';
import { handleError, useErrorToast, useSuccessToast } from '@/helpers';
import { themeConfiguration } from '@/resources';
import { ReactComponent as Logo } from '@/resources/img/leawie.svg';
import { useForgotPasswordMutation } from '@/services';
import { ForgotPasswordForm } from '@/types';

export const ForgotPassword = () => {
  const [t] = useTranslation('common');
  const [serverError, setServerError] = useState('');
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();
  const queryClient = useQueryClient();

  const { mutate: forgotPassword } = useForgotPasswordMutation(queryClient, {
    onSuccess: () => {
      successToast({ title: t('successfulPasswordSetRequest') });
    },
    onError: (error) => {
      errorToast(handleError(error));
    }
  });

  const initialValues: ForgotPasswordForm = { email: '' };

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email(t('emailInvalid')).required(t('emailRequired'))
  });

  const handleSubmit = async (values: ForgotPasswordForm) => {
    const payload = {
      email: values.email
    };
    forgotPassword(payload);
  };

  return (
    <VStack
      style={{
        display: 'flex',
        width: '100%',
        height: '90vh',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <Box width='500px' textAlign='center' height='230px'>
        <Logo />
      </Box>
      <Formik
        validateOnMount
        validateOnChange
        initialValues={initialValues}
        validationSchema={forgotPasswordSchema}
        onSubmit={handleSubmit}>
        {({ isSubmitting, isValid, errors, touched }) => (
          <Form onInput={() => setServerError('')}>
            <VStack p='10%' w='500px' bg={`${themeConfiguration.styles.popover}`} borderRadius='lg' boxShadow='lg'>
              <CustomInput
                type='email'
                maxLength={50}
                isInvalid={!!errors.email && touched.email}
                id='email'
                label={t('email')}
                error={errors.email}
              />
              <FormControl isInvalid={!!serverError}>
                <FormErrorMessage>{serverError}</FormErrorMessage>
              </FormControl>
              <Button
                type='submit'
                minW='150px'
                size='lg'
                top='15px'
                disabled={isSubmitting || !isValid || !!serverError}>
                {t('sendRequest')}
              </Button>
            </VStack>
          </Form>
        )}
      </Formik>
    </VStack>
  );
};
