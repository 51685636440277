import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { SlotInfo, View } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { MultiValue, SingleValue } from 'react-select';

import { Box, Text as ChakraText, Stack, Switch, VStack } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { PaginationState, SortingState } from '@tanstack/react-table';
import i18next from 'i18next';
import moment from 'moment';
import { RRule } from 'rrule';
import { v4 as uuidv4 } from 'uuid';

import {
  AbsenceFilter,
  CalendarLegend,
  CreateEditAbsence,
  CustomCalendar,
  CustomConfirmationModal,
  GenericTable,
  PageLoadingState,
  Restricted
} from '@/components';
import { UserContext } from '@/contexts';
import { ABSENCE_APPROVAL_STATUS, ABSENCE_TYPE, PERMISSION_TYPE } from '@/enums';
import {
  SadStates,
  changeAbsenceBackgroundColor,
  formatDate,
  getAbsenceColumns,
  getStripePattern,
  handleError,
  initialAxiosResponse,
  useErrorToast,
  useSuccessToast
} from '@/helpers';
import { useDebounce } from '@/hooks';
import { useDeleteAbsence, useGetAllAbsencesQuery, useGetAllPublicHolidaysQuery } from '@/services';
import {
  AbsenceFilters,
  AbsenceProps,
  CalendarEventProps,
  CustomView,
  HolidayProps,
  SelectOption,
  TableData
} from '@/types';

export const MyAbsences = () => {
  const { currentUser, permissions } = useContext(UserContext);
  const [sorting, setSorting] = useState<SortingState>([{ id: 'startsAt', desc: false }]);
  const errorToast = useErrorToast();
  const successToast = useSuccessToast();
  const queryClient = useQueryClient();
  const [showCreateEditModal, setShowCreateEditModal] = useState(false);
  const [t] = useTranslation('common');
  const [search, setSearch] = useState(
    JSON.parse(localStorage.getItem('myAbsencesSearch') || 'null')
  );
  const [selectedAbsenceId, setSelectedAbsenceId] = useState<number>(0);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [filtersType, setFiltersType] = useState<string>('');
  const [filtersApprovalStatus, setFiltersApprovalStatus] = useState<string>('');
  const [view, setView] = useState<CustomView>('month');
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [filterTypeValue, setFilterTypeValue] = useState<
    SingleValue<SelectOption> | MultiValue<SelectOption>
  >(JSON.parse(localStorage.getItem('myAbsencesTypeValue') || 'null'));
  const [filterApprovalStatusValue, setFilterApprovalStatusValue] = useState<
    SingleValue<SelectOption> | MultiValue<SelectOption>
  >(JSON.parse(localStorage.getItem('myAbsencesApprovalStatusValue') || 'null'));
  const [isOpenTableView, setIsOpenTableView] = useState<boolean>(false);
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });

  const permissionsMyAbsences = [
    PERMISSION_TYPE.CREATEABSENCE,
    PERMISSION_TYPE.VIEWABSENCEBYID,
    PERMISSION_TYPE.EDITABSENCEBYUSER,
    PERMISSION_TYPE.DELETEABSENCE
  ];
  const [searchParams, setSearchParams] = useSearchParams();
  const [queryParams, setQueryParams] = useState(() => ({
    searchQuery: searchParams.get('searchQuery') ?? [],
    type: searchParams.get('type') ? searchParams.get('type')?.split(',') : [],
    status: searchParams.get('status') ? searchParams.get('status')?.split(',') : []
  }));
  const [isLoadingFilters, setIsLoadingFilters] = useState<boolean>(true);
  const [debounceDelay, setDebounceDelay] = useState<number>(0);

  const absenceTypeObjects = Object.keys(ABSENCE_TYPE)
    .filter((v) => isNaN(Number(v)))
    .map((label) => {
      return {
        value: ABSENCE_TYPE[label as keyof typeof ABSENCE_TYPE],
        label: t(`${label}`) || label
      };
    });

  const approvalStatusObjects = Object.keys(ABSENCE_APPROVAL_STATUS)
    .filter((v) => isNaN(Number(v)))
    .map((label) => {
      return {
        value: ABSENCE_APPROVAL_STATUS[label as keyof typeof ABSENCE_APPROVAL_STATUS],
        label: t(`${label}`) || label
      };
    });

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('myAbsencesSearch') || 'null') === null) {
      localStorage.setItem('myAbsencesSearch', JSON.stringify(''));
    }
    if (JSON.parse(localStorage.getItem('myAbsencesTypeValue') || 'null') === null) {
      localStorage.setItem('myAbsencesTypeValue', JSON.stringify([]));
    }
    if (JSON.parse(localStorage.getItem('myAbsencesApprovalStatusValue') || 'null') === null) {
      localStorage.setItem('myAbsencesApprovalStatusValue', JSON.stringify([]));
    }

    if (
      searchParams.get('searchQuery') === null &&
      searchParams.get('type') === null &&
      searchParams.get('status') === null
    ) {
      updateQueryParams(
        'searchQuery',
        JSON.parse(localStorage.getItem('myAbsencesSearch') || 'null')
      );
      setSearch(JSON.parse(localStorage.getItem('myAbsencesSearch') || 'null'));

      updateQueryParams('type', getItemValueFromLocalStorage('myAbsencesTypeValue'));
      setFilterTypeValue(JSON.parse(localStorage.getItem('myAbsencesTypeValue') || 'null'));

      updateQueryParams('status', getItemValueFromLocalStorage('myAbsencesApprovalStatusValue'));
      setFilterApprovalStatusValue(
        JSON.parse(localStorage.getItem('myAbsencesApprovalStatusValue') || 'null')
      );
    } else {
      setSearch(queryParams.searchQuery.toString());
      localStorage.setItem('myAbsencesSearch', JSON.stringify(queryParams.searchQuery.toString()));

      searchParams.get('type') === null
        ? localStorage.setItem('myAbsencesTypeValue', JSON.stringify([]))
        : updateFiltersByUrl('type', 'myAbsencesTypeValue', absenceTypeObjects, setFilterTypeValue);

      searchParams.get('status') === null
        ? localStorage.setItem('myAbsencesApprovalStatusValue', JSON.stringify([]))
        : updateFiltersByUrl(
            'status',
            'myAbsencesApprovalStatusValue',
            approvalStatusObjects,
            setFilterApprovalStatusValue
          );
    }
  }, []);

  useEffect(() => {
    setSearchParams({
      searchQuery: queryParams.searchQuery === '' ? [] : queryParams.searchQuery,
      type:
        searchParams.get('type') === null
          ? (queryParams.type as Array<string>)
          : (queryParams.type as Array<string>)?.join(','),
      status:
        searchParams.get('status') === null
          ? (queryParams.status as Array<string>)
          : (queryParams.status as Array<string>)?.join(',')
    });

    deleteQueryParams('searchQuery');
    deleteQueryParams('type');
    deleteQueryParams('status');
  }, [queryParams, searchParams, setSearchParams]);

  const deleteQueryParams = (value: string) => {
    if (searchParams.has(value) && searchParams.get(value) === '') {
      searchParams.delete(value);
      updateQueryParams(value, []);
      setSearchParams(searchParams);
    }
  };

  const updateQueryParams = (key: string, value: string | string[]) => {
    setQueryParams((prevState) => ({ ...prevState, [key]: value }));
  };

  const getItemValueFromLocalStorage = (item: string) => {
    return (JSON.parse(localStorage.getItem(item) || 'null') as MultiValue<SelectOption>).map(
      ({ value }) => value.toString()
    );
  };

  const updateFiltersByUrl = (
    queryParam: string,
    item: string,
    objects: AbsenceFilters[],
    setValue: Dispatch<SetStateAction<SingleValue<SelectOption> | MultiValue<SelectOption>>>
  ) => {
    const result = objects.filter((object) => {
      const array = searchParams
        .get(queryParam)
        ?.split(',')
        .filter((x) => x === object.value.toString());
      return !(array?.length === 0);
    });
    setValue(result);
    localStorage.setItem(item, JSON.stringify(result));
  };

  useEffect(() => {
    handleTranslation('myAbsencesTypeValue', ABSENCE_TYPE, setFilterTypeValue);
    handleTranslation(
      'myAbsencesApprovalStatusValue',
      ABSENCE_APPROVAL_STATUS,
      setFilterApprovalStatusValue
    );
  }, [t]);

  const handleTranslation = (
    item: string,
    absenceEnum: typeof ABSENCE_TYPE | typeof ABSENCE_APPROVAL_STATUS,
    setValue: Dispatch<SetStateAction<SingleValue<SelectOption> | MultiValue<SelectOption>>>
  ) => {
    const result = (
      JSON.parse(localStorage.getItem(item) || 'null') as MultiValue<SelectOption>
    ).map(({ value }) => {
      return {
        value: value,
        label: t(`${absenceEnum[value as keyof typeof absenceEnum]}`)
      };
    });
    setValue(result);
    localStorage.setItem(item, JSON.stringify(result));
  };

  const { data = initialAxiosResponse, isLoading: isLoadingData } = useGetAllAbsencesQuery(
    pageSize,
    pageIndex + 1,
    false,
    sorting
      .map((s) => {
        if (s.id === 'type' && i18next.resolvedLanguage === 'rs') {
          return 'TypeSerbian';
        } else if (s.id === 'approvalStatus' && i18next.resolvedLanguage === 'rs') {
          return 'ApprovalStatusSerbian';
        } else {
          return s.id.charAt(0).toUpperCase() + s.id.slice(1);
        }
      })
      .toString(),
    sorting.some((s) => s.desc),
    {
      onSuccess: () => setIsLoadingFilters(false),
      onError: (error) => {
        errorToast(handleError(error));
      }
    },
    currentUser?.id.toString(),
    useDebounce(search, debounceDelay),
    filtersType,
    filtersApprovalStatus,
    year.toString()
  );

  const handleDelete = (row: TableData) => {
    setSelectedAbsenceId(row.id);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setSelectedAbsenceId(0);
    setIsDeleteModalOpen(false);
  };

  const { mutate: deleteAbsence, isLoading: isLoadingDeleteAbsence } = useDeleteAbsence(
    queryClient,
    {
      onSuccess: () => {
        successToast({ title: t('successfullyDeletedAbsence') });
        handleCloseDeleteModal();
      },
      onError: (error) => {
        errorToast(handleError(error));
      }
    }
  );

  const { data: publicHolidays = initialAxiosResponse } = useGetAllPublicHolidaysQuery(
    pageSize,
    pageIndex + 1,
    '',
    false,
    {
      onSuccess: () => void 0,
      onError: (error) => {
        errorToast(handleError(error));
      }
    },
    '',
    String(year)
  );

  const handleCloseCreateEdit = () => {
    setShowCreateEditModal(false);
    setSelectedAbsenceId(0);
    setStartDate(new Date());
  };

  const handleEditByUser = (row: TableData | CalendarEventProps) => {
    if (
      row.approvalStatus === ABSENCE_APPROVAL_STATUS.PENDING ||
      row.approvalStatus === t('PENDING')
    ) {
      setSelectedAbsenceId(Number(row.id));
      setShowCreateEditModal(true);
      setStartDate((row as CalendarEventProps).start);
    }
  };

  const handleSearch = (value: string) => {
    setSearch(value);
    setPagination({ pageIndex: 0, pageSize });
    localStorage.setItem('myAbsencesSearch', JSON.stringify(value));
    updateQueryParams('searchQuery', value);
    setDebounceDelay(500);
  };

  const defaultHiddenColumns = {
    createdAt: false,
    id: false,
    requestMessage: false,
    responseMessage: false,
    reviewerId: false,
    updatedAt: false
  };

  const handleChangeFilterType = (
    newFilterType: SingleValue<SelectOption> | MultiValue<SelectOption>
  ) => {
    setFilterTypeValue(newFilterType);
    localStorage.setItem('myAbsencesTypeValue', JSON.stringify(newFilterType));
    updateQueryParams(
      'type',
      (newFilterType as MultiValue<SelectOption>).map(({ value }) => value.toString())
    );
  };

  const handleChangeFilterApprovalStatus = (
    newFilterApprovalStatus: SingleValue<SelectOption> | MultiValue<SelectOption>
  ) => {
    setFilterApprovalStatusValue(newFilterApprovalStatus);
    localStorage.setItem('myAbsencesApprovalStatusValue', JSON.stringify(newFilterApprovalStatus));
    updateQueryParams(
      'status',
      (newFilterApprovalStatus as MultiValue<SelectOption>).map(({ value }) => value.toString())
    );
  };

  useEffect(() => {
    if (filterTypeValue) {
      const filters = Array.isArray(filterTypeValue)
        ? filterTypeValue.map(({ value }) => value).join(',')
        : undefined;
      setFiltersType(String(filters));
    }
  }, [filterTypeValue]);

  useEffect(() => {
    if (filterApprovalStatusValue) {
      const filters = Array.isArray(filterApprovalStatusValue)
        ? filterApprovalStatusValue.map(({ value }) => value).join(',')
        : undefined;
      setFiltersApprovalStatus(String(filters));
    }
  }, [filterApprovalStatusValue]);

  const formatAbsences = (absences: AbsenceProps[]) => {
    return absences?.map((item) => ({
      id: item.id,
      type: item.type,
      allDay: true,
      start: moment(item.startsAt).endOf('day').toDate(),
      end: moment(item.endsAt).endOf('day').toDate(),
      approvalStatus: item.approvalStatus,
      isAbsence: true
    }));
  };

  const handleCreateAbsence = (slot: SlotInfo) => {
    if (
      moment(slot.start).format('dddd') !== 'Sunday' &&
      moment(slot.start).format('dddd') !== 'Saturday'
    ) {
      setShowCreateEditModal(true);
      setStartDate(slot.start);
    }
  };

  const formatedPublicHolidays = publicHolidays?.data?.items.map((holiday: HolidayProps) => {
    return {
      id: uuidv4(),
      start: moment(holiday.startsAt).startOf('day').toDate(),
      title: holiday.name,
      allDay: true,
      type: 'publicHolidays',
      end: moment(holiday.startsAt)
        .add(holiday.totalDaysCelebrated === 1 ? 0 : holiday.totalDaysCelebrated - 1, 'days')
        .endOf('day')
        .toDate()
    };
  });

  const formatedReligiousHolidays =
    (currentUser &&
      currentUser.religiousHolidays.flatMap((holiday: HolidayProps) => {
        if (holiday.recurring) {
          const rruleObj = new RRule({
            freq: RRule.YEARLY,
            dtstart: moment(holiday.startsAt).add(-10, 'years').startOf('day').toDate(),
            until: moment(new Date()).add(10, 'years').toDate()
          });
          const recurringEvents = rruleObj.all();
          return recurringEvents.map((date) => ({
            start: moment(date).startOf('day'),
            end: moment(date)
              .add(holiday.totalDaysCelebrated === 1 ? 0 : holiday.totalDaysCelebrated - 1, 'days')
              .endOf('day'),
            id: uuidv4(),
            allDay: true,
            title: holiday.name,
            type: 'religiousHolidays'
          }));
        } else {
          return {
            start: moment(new Date(holiday.startsAt)).startOf('day'),
            end: moment(new Date(holiday.startsAt))
              .add(holiday.totalDaysCelebrated === 1 ? 0 : holiday.totalDaysCelebrated - 1, 'days')
              .endOf('day'),
            id: uuidv4(),
            allDay: true,
            title: holiday.name,
            type: 'religiousHolidays'
          };
        }
      })) ||
    [];

  const formatedAbsences = formatAbsences(data?.data?.items) || [];
  const events = publicHolidays?.data && [
    ...formatedPublicHolidays,
    ...formatedReligiousHolidays,
    ...formatedAbsences
  ];

  const handleViewChange = (newView: CustomView | View) => {
    setView(newView);
  };

  const formatEventsStyles = (calendarEvent: CalendarEventProps) => ({
    style: {
      background:
        calendarEvent &&
        (calendarEvent?.approvalStatus === ABSENCE_APPROVAL_STATUS.PENDING
          ? getStripePattern(changeAbsenceBackgroundColor(calendarEvent.type))
          : changeAbsenceBackgroundColor(calendarEvent.type)),
      borderRadius: '10px',
      color: 'black',
      border: '0px',
      height: view === 'year' ? '12px' : '35px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: view === 'week' ? '20px' : 0,
      fontWeight: '600'
    }
  });

  useEffect(() => {
    isOpenTableView
      ? setPagination({ pageIndex: 0, pageSize: 10 })
      : setPagination({ pageIndex: 0, pageSize: 0 });
  }, [isOpenTableView]);

  return (
    <Box>
      <VStack align='stretch' gap='1' mx='3rem' my='1em'>
        <SadStates
          states={[
            {
              when: isLoadingData || isLoadingFilters,
              render: () => <PageLoadingState />
            }
          ]}>
          <>
            <Stack align='center' direction='row' justifyContent='space-between' height='28px'>
              <Box>
                <Switch
                  id='switchMyAbsences'
                  size='lg'
                  onChange={() => setIsOpenTableView(!isOpenTableView)}
                />
                <ChakraText as='span' marginLeft='10px'>
                  {t('switchToTableView')}
                </ChakraText>
              </Box>
              {!isOpenTableView && <CalendarLegend />}
            </Stack>
            {isOpenTableView ? (
              data?.data && (
                <GenericTable
                  data={data?.data?.items?.map(
                    (item: {
                      approvalStatus: ABSENCE_APPROVAL_STATUS;
                      type: ABSENCE_TYPE;
                      createdAt: string | number | Date;
                      updatedAt: string | number | Date;
                      startsAt: string | number | Date;
                      endsAt: string | number | Date;
                    }) => ({
                      ...item,
                      approvalStatus: t(`${ABSENCE_APPROVAL_STATUS[item.approvalStatus]}`),
                      type: t(`${ABSENCE_TYPE[item.type]}`),
                      createdAt: formatDate(item.createdAt),
                      updatedAt: formatDate(item.updatedAt),
                      startsAt: formatDate(item.startsAt),
                      endsAt: formatDate(item.endsAt)
                    })
                  )}
                  columns={getAbsenceColumns()}
                  pageIndex={pageIndex}
                  pageSize={pageSize}
                  pageCount={data?.data ? data.data.totalPages : 0}
                  setPagination={setPagination}
                  defaultHiddenColumns={defaultHiddenColumns}
                  handleEditByUser={handleEditByUser}
                  handleSearch={(value) => handleSearch(value)}
                  searchValue={search}
                  handleCreateData={() => setShowCreateEditModal(true)}
                  handleDeleteAbsence={handleDelete}
                  filter={
                    <AbsenceFilter
                      filterTypeValue={filterTypeValue}
                      handleChangeFilterType={handleChangeFilterType}
                      filterApprovalStatusValue={filterApprovalStatusValue}
                      handleChangeFilterApprovalStatus={handleChangeFilterApprovalStatus}
                    />
                  }
                  localStorageKey='myLeaves'
                  dragAndDropLocalStorageKey='dndMyLeaves'
                  sorting={sorting}
                  setSorting={setSorting}
                  permissions={permissionsMyAbsences}
                />
              )
            ) : (
              <CustomCalendar
                view={view}
                handleCreate={handleCreateAbsence}
                handleEdit={handleEditByUser}
                handleView={handleViewChange}
                events={events}
                eventsStyles={formatEventsStyles}
                isMyAbsencesAdminView={
                  permissions.includes(PERMISSION_TYPE.EDITABSENCEBYADMIN) && true
                }
                year={year}
                setYear={setYear}
                handleSearch={(value) => handleSearch(value)}
                searchValue={search}
                filter={
                  <AbsenceFilter
                    filterTypeValue={filterTypeValue}
                    handleChangeFilterType={handleChangeFilterType}
                    filterApprovalStatusValue={filterApprovalStatusValue}
                    handleChangeFilterApprovalStatus={handleChangeFilterApprovalStatus}
                  />
                }
                handleCreateData={() =>
                  permissions.includes(PERMISSION_TYPE.CREATEABSENCE) &&
                  permissions.includes(PERMISSION_TYPE.EDITABSENCEBYUSER) &&
                  setShowCreateEditModal(true)
                }
              />
            )}
          </>
        </SadStates>
      </VStack>
      <Restricted to={[PERMISSION_TYPE.CREATEABSENCE, PERMISSION_TYPE.EDITABSENCEBYUSER]}>
        <CreateEditAbsence
          isMyAbsence={true}
          isOpen={showCreateEditModal}
          onClose={handleCloseCreateEdit}
          selectedAbsenceId={selectedAbsenceId}
          defaultStartDate={startDate}
        />
      </Restricted>
      <Restricted to={[PERMISSION_TYPE.DELETEABSENCE]}>
        <CustomConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={handleCloseDeleteModal}
          confirmButtonLabel={t('yesDelete')}
          message={t('confirmAbsenceDeletion')}
          modalTitle={t('deleteAbsenceRequest')}
          handleClick={() => deleteAbsence(Number(selectedAbsenceId))}
          isSubmitting={isLoadingDeleteAbsence}
        />
      </Restricted>
    </Box>
  );
};
