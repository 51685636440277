import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Text as ChakraText, VStack } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { PaginationState, SortingState } from '@tanstack/react-table';

import {
  CreateEditUser,
  CustomConfirmationModal,
  GenericTable,
  PageLoadingState,
  Restricted
} from '@/components';
import { UserContext } from '@/contexts';
import { PERMISSION_TYPE } from '@/enums';
import {
  formatDate,
  handleError,
  initialAxiosResponse,
  SadStates,
  useErrorToast,
  useSuccessToast
} from '@/helpers';
import { useDebounce } from '@/hooks';
import { useDeleteUserMutation, useGetAllUsersAdminQuery } from '@/services';
import { HolidayProps, TableData, User } from '@/types';

export const UsersListAdminView = () => {
  const { permissions } = useContext(UserContext);
  const [showCreateEditModal, setShowCreateEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [search, setSearch] = useState(JSON.parse(localStorage.getItem('usersSearch') || 'null'));
  const [t] = useTranslation('common');
  const errorToast = useErrorToast();
  const [sorting, setSorting] = useState<SortingState>([{ id: 'firstName', desc: false }]);
  const [userId, setUserId] = useState<string>();
  const queryClient = useQueryClient();
  const successToast = useSuccessToast();
  const [userName, setUserName] = useState<string>();
  const permissionsUsersList = [
    PERMISSION_TYPE.CREATEUSER,
    PERMISSION_TYPE.EDITUSERBYID,
    PERMISSION_TYPE.DELETEUSER
  ];
  const [searchParams, setSearchParams] = useSearchParams();
  const [queryParams, setQueryParams] = useState(() => ({
    searchQuery: searchParams.get('searchQuery') ?? []
  }));
  const [debounceDelay, setDebounceDelay] = useState<number>(0);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('usersSearch') || 'null') === null) {
      localStorage.setItem('usersSearch', JSON.stringify(''));
    }

    if (searchParams.get('searchQuery') === null) {
      updateQueryParams('searchQuery', JSON.parse(localStorage.getItem('usersSearch') || 'null'));
      setSearch(JSON.parse(localStorage.getItem('usersSearch') || 'null'));
    } else {
      setSearch(queryParams.searchQuery.toString());
      localStorage.setItem('usersSearch', JSON.stringify(queryParams.searchQuery.toString()));
    }
  }, []);

  useEffect(() => {
    setSearchParams({
      searchQuery: queryParams.searchQuery === '' ? [] : queryParams.searchQuery
    });

    deleteQueryParams('searchQuery');
  }, [queryParams, searchParams, setSearchParams]);

  const deleteQueryParams = (value: string) => {
    if (searchParams.has(value) && searchParams.get(value) === '') {
      searchParams.delete(value);
      updateQueryParams(value, []);
      setSearchParams(searchParams);
    }
  };

  const updateQueryParams = (key: string, value: string | string[]) => {
    setQueryParams((prevState) => ({ ...prevState, [key]: value }));
  };

  const getColumns = () => [
    {
      id: 'firstName',
      accessorKey: 'firstName',
      header: t('firstName')
    },
    {
      id: 'lastName',
      accessorKey: 'lastName',
      header: t('lastName')
    },
    {
      id: 'email',
      accessorKey: 'email',
      header: t('email')
    },
    {
      id: 'referenceNumber',
      accessorKey: 'referenceNumber',
      header: t('referenceNumber')
    },
    {
      id: 'remainingAnnualLeaveDays',
      accessorKey: 'remainingAnnualLeaveDays',
      cell: (row: { row: { original: User } }) =>
        `${row?.row?.original?.remainingAnnualLeaveDays} / ${row?.row?.original?.annualLeaveDays}`,
      header: t('remainingAndTotalAnnualLeaveDays')
    },
    {
      id: 'remainingSickLeaveDays',
      accessorKey: 'remainingSickLeaveDays',
      cell: (row: { row: { original: User } }) =>
        `${row?.row?.original?.remainingSickLeaveDays} / ${row?.row?.original?.sickLeaveDays}`,
      header: t('remainingAndTotalSickLeaveDays')
    },
    {
      id: 'religiousHolidays',
      accessorKey: 'religiousHolidays',
      header: t('religiousHolidays'),
      cell: (row: { row: { original: User } }) =>
        row?.row?.original?.religiousHolidays.map((holiday: HolidayProps, index: number) => (
          <ChakraText as='span' key={index}>
            {`${index ? ', ' : ''} ${holiday.name} ${'('}${formatDate(holiday.startsAt)}${')'}`}
          </ChakraText>
        ))
    },
    {
      id: 'employmentStartedAt',
      accessorKey: 'employmentStartedAt',
      header: t('employmentStartedAt')
    }
  ];

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });

  const { data = initialAxiosResponse, isLoading } = useGetAllUsersAdminQuery(
    pageSize,
    pageIndex + 1,
    sorting.map((s) => s.id.charAt(0).toUpperCase() + s.id.slice(1)).toString(),
    sorting.some((s) => s.desc),
    {
      enabled: permissions.includes(PERMISSION_TYPE.EDITABSENCEBYADMIN),
      onSuccess: () => void 0,
      onError: (error) => {
        errorToast(handleError(error));
      }
    },
    true,
    useDebounce(search, debounceDelay)
  );

  const handleEdit = (row: TableData) => {
    setUserId(row.id);
    setShowCreateEditModal(true);
  };

  const handleCloseEdit = () => {
    setShowCreateEditModal(false);
    setUserId(undefined);
  };

  const handleDelete = (row: TableData) => {
    setUserId(row.id);
    setShowDeleteModal(true);
    setUserName(`${row.firstName}  ${row.lastName}`);
  };

  const handleCloseDelete = () => {
    setShowDeleteModal(false);
    setUserId(undefined);
  };

  const { mutate: deleteUser, isLoading: isLoadingDeleteUser } = useDeleteUserMutation(
    queryClient,
    {
      onSuccess: () => {
        handleCloseDelete();
        successToast({ title: t('successfulUserDelete') });
      },
      onError: (error) => {
        errorToast(handleError(error));
      }
    }
  );

  const handleSearch = (value: string) => {
    setSearch(value);
    setPagination({ pageIndex: 0, pageSize });
    localStorage.setItem('usersSearch', JSON.stringify(value));
    updateQueryParams('searchQuery', value);
    setDebounceDelay(500);
  };

  const defaultHiddenColumns = {
    id: false,
    email: false,
    employmentStartedAt: false
  };

  return (
    <>
      <VStack mx='3rem' my='1em' gap={1} align='stretch'>
        <SadStates
          states={[
            {
              when: isLoading,
              render: () => <PageLoadingState />
            }
          ]}>
          {data?.data && (
            <>
              <GenericTable
                data={data?.data?.items?.map(
                  (item: { employmentStartedAt: string | number | Date }) => ({
                    ...item,
                    employmentStartedAt: formatDate(item.employmentStartedAt)
                  })
                )}
                columns={getColumns()}
                pageIndex={pageIndex}
                pageSize={pageSize}
                pageCount={data?.data ? data.data.totalPages : 0}
                setPagination={setPagination}
                handleEdit={(row) => handleEdit(row)}
                handleDelete={(row) => handleDelete(row)}
                defaultHiddenColumns={defaultHiddenColumns}
                handleSearch={(value) => handleSearch(value)}
                searchValue={search}
                handleCreateData={() => setShowCreateEditModal(true)}
                localStorageKey='employees'
                dragAndDropLocalStorageKey='dndAllEmployees'
                sorting={sorting}
                setSorting={setSorting}
                permissions={permissionsUsersList}
              />
            </>
          )}
        </SadStates>
      </VStack>
      <Restricted to={[PERMISSION_TYPE.CREATEUSER, PERMISSION_TYPE.EDITUSERBYID]}>
        <CreateEditUser
          isOpen={showCreateEditModal}
          onClose={handleCloseEdit}
          isEditMode={!!userId}
          selectedUserId={userId ?? ''}
        />
      </Restricted>
      <Restricted to={[PERMISSION_TYPE.DELETEUSER]}>
        <CustomConfirmationModal
          isOpen={showDeleteModal}
          onClose={handleCloseDelete}
          handleClick={() => deleteUser(String(userId))}
          confirmButtonLabel={t('yesDelete')}
          message={`${t('confirmUserDeletion')} ${userName}?`}
          modalTitle={t('deleteUser')}
          isSubmitting={isLoadingDeleteUser}
        />
      </Restricted>
    </>
  );
};
